import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import PurchaseQuotationForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transfer from '../transfer/Transfer';
import DropDownButton from 'devextreme-react/drop-down-button';
import TransferStatusCell from '../../../components/transfer-status-cell/TransferStatusCell';

export default function PurchaseQuotationListing() {

    const apiURL = `/api/PurchaseQuotation`;
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transformTitle, setTransformTitle] = useState(null);
    const [transferData, setTransferData] = useState(null);
    const transformRef = useRef(null);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const onItemClick = (e) => {
        const { action } = e.itemData;
        setTransformTitle(action);
        utils.transformOpen(transformRef);
    };
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/PurchaseQuotation/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"Purchase Quotation"}
            storageName={"PurchaseQuotationListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
            customizedAddButton={
                <DropDownButton
                    className="customized-listing-add-btn listing-btn"
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text="Add"
                    items={[
                        { id: 1, action: 'Transfer from Request' },
                        // { id: 2, action: 'Transfer from Sales Order' }
                    ]}
                    displayExpr="action"
                    keyExpr="id"
                    width={"100px"}
                    dropDownOptions={
                        {width: "180px"}
                    }
                    onItemClick={onItemClick}
                    onButtonClick={() => {
                        setCurrentEditID("new");
                    }}
              />
            }
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="DocumentNo" />
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexCode" caption="Forex"/>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column
                    dataField="TransferStatus"
                    caption="Status"
                    cellRender={(cellData) => (
                        <TransferStatusCell
                            hasPartiallyTransferred={cellData.data.HasPartiallyTransferred}
                            hasFullyTransferred={cellData.data.HasFullyTransferred}
                        />
                    )}
                />
        </Listing>
        </div>

        <PurchaseQuotationForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            lockedForm={lockForm}
            transferData={transferData}
            clearTransfer={setTransferData}
            title={"Purchase Quotation"} 
            apiURL={apiURL}
        />

        <Transfer 
            ref={transformRef}
            title={transformTitle}
            apiPath={{
                "Transfer from Request": {
                    FullDocumentListing: "api/PurchaseTransfer/RequestFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/RequestPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/RequestFullTransfer",
                    PartialDocumentTransfer:  "api/PurchaseTransfer/RequestPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/RequestFilter"
                }
            }}
            transferData={transferFormData}
        />
        
    </Container>;
}