import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';

export default function Salesmen(){
    return <Container>
        <div id='normal-listing'>
            <SettingListing
                listingTitle="Salesman"
                apiURL="/api/Salesman"
                listingURL="/api/Salesman/list"
                sortColumn={"Code"}
                defaultSettingTemplate={false}
                storageName={"SettingSalesmenListing"}
                customizedFormRender={[
                    {type: "asyncTextbox", dataField: "Code", asyncProperty: "code", required: true},
                    {type: "textbox", dataField: "Name", required: true},
                    {type: "textbox", dataField: "Email"},
                    {type: "textbox", dataField: "Phone"},
                    {type: "checkbox", dataField: "IsDefault", caption: "Set As Default"},
                ]}
                className={"listing-datagrid"}
            >
                <Column 
                    dataField="Code" 
                    dataType="string" 
                    cellTemplate={(container, options) => {
                        // Render the code text
                        container.textContent = options.data.Code;
                        // If the Default field is true, append the word "default"
                        if (options.data.IsDefault) {
                            const defaultSpan = document.createElement("span");
                            defaultSpan.classList.add("default-option-label");
                            defaultSpan.textContent = "Default";
                            container.appendChild(defaultSpan);
                        }
                    }}
                />
                <Column dataField="Name" dataType="string"/>
                <Column dataField="Email" dataType="string" />
                <Column dataField="Phone" dataType="string" />
                <Column visible={true} dataField="Active" caption="Active" width={100}/>
            </SettingListing>
        </div>
    </Container>
}