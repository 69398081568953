import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import SalesOrderAdjustmentForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transform from '../transform/Transform';

export default function SalesOrderAdjustmentListing(){
    const apiURL = `/api/SalesOrderAdjustment`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transferData, setTransferData] = useState(null);
    const transformRef = useRef(null);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };
    
    const addButtonOnClick = () => {
        utils.transformOpen(transformRef);
    };
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/SalesOrderAdjustment/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"Sales Order Adjustment"}
            storageName={"SalesOrderAdjustmentistingColumnWidth"}
            addButtonOnClick={addButtonOnClick}
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="DocumentNo" />
            <Column dataField="CustomerCode" />
            <Column dataField="CustomerName" />
            <Column dataField="ForexCode" caption="Forex"/>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField='OutstandingAmount' format={{ type: "fixedPoint", precision: 2 }} />
        </Listing>
        </div>

        <SalesOrderAdjustmentForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
            title={"Sales Order Adjustment"}
            transferData={transferData}
            clearTransfer={setTransferData}
        />
        
        <Transform 
            ref={transformRef}
            title={"Sales Order Adjustment"}
            apiPath={{
                "Sales Order Adjustment": {
                    FullDocumentListing: "api/SalesTransfer/OrderFullDocument",
                    PartialDocumentListing: "api/SalesTransfer/OrderPartial",
                    FullDocumentTransfer: "api/SalesTransfer/OrderFullTransfer",
                    PartialDocumentTransfer:  "api/SalesTransfer/OrderPartialTransfer",
                    FilterPath: "api/SalesTransfer/OrderFilter"
                }
            }}
            transferData={transferFormData}
        />
    </Container>;
}