import React, { useState } from "react";
import { Column, Button as ButtonColumn, Lookup } from "devextreme-react/data-grid";
import ARRefundForm from "./form";
import Container from "../../../components/container";
import utils from "../../../utils/common";
import Listing from "../../../components/listing/newListing";

export default function ARRefundListing() {
	const apiURL = `/api/ARRefund`;

	const [currentEditID, setCurrentEditID] = useState(null);

	function handleEdit(e) {
		const id = e.row.key;
		const data = e.row.data;
		const TotalAmount = data.MainTotalAmount;
		const OutstandingAmount = data.OutstandingAmount;
		setCurrentEditID(id);
	}

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='listing-datagrid'
					apiURL={apiURL}
					listingURL='/api/ARRefund/list'
					sortColumn={"DocumentDate"}
					sortOrder={"desc"}		
					listingTitle={"AR Refund"}
					storageName={"ARRefundListingColumnWidth"}
					onAddClick={setCurrentEditID}
					handleEdit={handleEdit}>
					<Column dataField='DocumentDate' format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField='DocumentNo' />
					<Column dataField='CustomerCode' />
					<Column dataField='CustomerName' />
					<Column dataField='ForexCode' caption={"Forex"} />
					<Column dataField='MainForexAmount' caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='MainLocalAmount' caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField='OutstandingAmount' caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>

			<ARRefundForm 
				formID={currentEditID} 
				closePopup={setCurrentEditID} 
				title={"A/R Refund"}
				apiURL={apiURL}
			/>
		</Container>
	);
}
