import Container from "../../../components/container";
import Listing from "../../../components/listing/newListing";
import BankReconciliationForm from "./form";
import BankReconciliationFormBFY from './beforeFiscalYear.js';
import { Column, Button as ButtonColumn } from "devextreme-react/data-grid";
import { useState } from "react";
import { Button } from "devextreme-react/button";

export default function BankReconciliationListing(){
    const apiURL = `/api/BankReconciliation`;
    const listingURL = `/api/BankReconciliation/list`;
    const [formDetails, setFormDetails] = useState(null);
    const [openingID, setOpeningID] = useState(null);

    const handleEdit = (e) => {
        const data = e.row.data;
        setFormDetails(data);
    }

    return(
        <Container>
            <div id="normal-listing">
            <Listing
                className={"listing-datagrid"}
                apiURL={apiURL}
                listingURL={listingURL}
                sortColumn={"BankStatementDate"}
                sortOrder={"desc"}
                listingTitle={"Bank Reconciliation"}
                storageName={"GLBankReconciliationListing"}
                onAddClick={(value) => setFormDetails({id: value})}
                handleEdit={handleEdit}
                customizedAddButton={[
                    <Button
                        icon='plus'
                        onClick={(e) => {
                            setFormDetails({id: "new"});
                        }}
                        text='Add'
                        className='listing-page-add-btn listing-btn'
                    />,
                    <Button
                        icon='plus'
                        text='Opening Bank Recon'
                        className='listing-page-add-btn'
                        id='gl-listing-btn'
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                            setOpeningID("new");
                        }}
                    />
                ]}
            >
                
                <Column dataField={"BankStatementDate"} format="dd/MM/yyyy" dataType={"date"} caption={"Bank Statement Date"} />
                <Column dataField="ChartAccountCode" caption={"Code"}/>
                <Column dataField="ChartAccountName" caption={"Description"}/>
                <Column dataField="BankStatementBalance" caption={"Bank Statement Amount"} format={{ type: "fixedPoint", precision: 2}}/>
            </Listing>
            </div>

            <BankReconciliationForm 
                formDetails={formDetails}
                closePopup={setFormDetails}
                title={"Bank Reconciliation"}
            />

            <BankReconciliationFormBFY 
                closePopup={setOpeningID}
				formID={openingID}
			/>
        </Container>
    )
}