import { ScrollView, DropDownButton, Popup, Button, SelectBox, TextBox } from "devextreme-react";
import utils, { yesno, loading, closeLoading, CheckCreditLimit } from "../../utils/common";
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import CustomizedValidationGroup from "../customized-validation-group/CustomizedValidationGroup";
import baseapi from "../../api/baseapi";
import ErrorPopUpForm from "./ErrorPopupForm";
import { getFromLS, getSharedState, setSharedState } from "../../utils/localstorage";
import { useNavigate } from "react-router-dom";
import { ParentValueCalculation } from "../../utils/module-calculation";
import CreditTermForm from "./CreditTermForm";
import { useAuth } from "../../contexts/auth";
import { DocumentSettings } from "../../utils/documentSettings";
export default forwardRef(function PopupForm(props, ref) {
  const currentToken = localStorage.getItem("Authorization").substring(7);
  const [formPopup, setFormPopup] = useState(false);
  const [lookupValidateRef, setLookupValidateRef] = useState(null);
  // const [startValidation, setStartValidation] = useState(0);
  const [scrollViewHeight, setScrollViewHeight] = useState(utils.GetScrollViewHeightV2());
  const [reportSelected, setReportSelected] = useState(props.defaultSelectedReport);
  const [popupReportVisible, setPopupReportVisible] = useState(false);
  const [popupEmailVisible, setPopupEmailVisible] = useState(false);
  const [emailToSend, setEmailToSend] = useState();
  const documentViewerLink = useRef(null);
  const [reportList, setReportList] = useState([]);
  const [actionButtonEnabled, setActionButtonEnabled] = useState(
    props.actionButtonEnabled === undefined ? true : props.actionButtonEnabled
  );
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const popupMessageRef = useRef(null);
  const validatorRef = useRef(null);
  const scrollViewRef = useRef(null);
  const formStay = useRef(false);
  const preview = useRef(null);
  const email = useRef(null);
  const submitForm = useRef(null);
  const isDirectToReport = useRef(null);
  const isPrintReportClicked = useRef(false);
  const [saveButtonItems, setSaveButtonItems] = useState([
    { id: 1, text: "Save & Close" },
    { id: 2, text: "Save & Print" },
  ]);
  const [actionButtomItems, setActionButtomItems] = useState([
    { action: "Copy" },
    { action: "Paste" },
    { action: "Delete" },
    { action: "Print Report" },
  ]);
  const [hasAccess, setHasAccess] = useState(true);
  const [hasAddRight, setHasAddRight] = useState(true);
  const [hasEditRight, setHasEditRight] = useState(true);
  const [reportRight, setReportRight] = useState(true);
  const [deleteRight, setDeleteRight] = useState(true);
  const [lockForm, setLockForm] = useState(false);
  const defaultValues = useRef({});
  const popupCreditLimitRef = useRef(null);
  const [validatedData, setValidatedData] = useState({});
  const [checkResults, setCheckResults] = useState(false);
  const { user } = useAuth();

  // Listen for storage events and update state
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "sharedState") {
        const sharedState = event.newValue ? event.newValue : {};

        if (!sharedState.previewLoading) {
          closeLoading();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!utils.isNullOrEmpty(props.onOpening)) {
      props.onOpening(formPopup);
      setCheckResults(false);
    }
  }, [formPopup]);

  useEffect(() => {
    const fetchNextDocNo = async () => {
      if (utils.isNullOrEmpty(props.formData)) {
        return; // Exit early if formData is null or empty
      }

      const formData = props.formData;

      // Check if both keys exist in formData
      if (!("DocumentDate" in formData) || !("DocumentPrefix" in formData)) {
        return;
      }

      // Check if both values are defined
      if (!formData["DocumentDate"] || !formData["DocumentPrefix"]) {
        return;
      }

      const apiURL = DocumentSettings.NextDocNoApiEndpoints.SelectDocNo(
        formData["DocumentPrefix"],
        formData["DocumentDate"]
      );

      try {
        const response = await baseapi.httpget(apiURL); // API call
        const { NextDocNo } = response.data;

        // Check if setFormValue exists and then call it
        if (props.setFormValue) {
          props.setFormValue({ NextDocNo }); // Update parent's formValue
        }
      } catch (error) {
        console.error("Error fetching next document number:", error);
      }
    };

    fetchNextDocNo();
  }, [props.formData?.["DocumentDate"], props.formData?.["DocumentPrefix"]]);

  useEffect(() => {
    if (props.ReportDocumentNo !== undefined) {
      setActionButtomItems([
        { action: "Copy" },
        { action: "Paste" },
        { action: "Delete" },
        { action: "Print Report" },
      ]);
      setSaveButtonItems([
        { id: 1, text: "Save & Close" },
        { id: 2, text: "Save & Print" },
      ]);
    } else {
      setActionButtomItems([{ action: "Copy" }, { action: "Paste" }, { action: "Delete" }]);
      setSaveButtonItems([{ id: 1, text: "Save & Close" }]);
    }
  }, [props.ReportDocumentNo]);

  useEffect(() => {
    if (!utils.isNullOrEmpty(props.actionButtonEnabled)) {
      setActionButtonEnabled(props.actionButtonEnabled);
    }
  }, [props.actionButtonEnabled]);

  useEffect(() => {
    if (!utils.isNullOrEmpty(props.reportURL)) {
      baseapi
        .httpget(props.reportURL)
        .then((response) => {
          const { data } = response;
          setReportList(data);
        })
        .catch(() => {
          throw "Network error";
        });
    }
  }, [props.reportURL]);

  useEffect(() => {
    if (!utils.isNullOrEmpty(props.defaultSelectedReport)) {
      setReportSelected(props.defaultSelectedReport);
    }
  }, [props.defaultSelectedReport]);

  useEffect(() => {
    if (!utils.isNullOrEmpty(props.lookupValidateRef)) {
      setLookupValidateRef(props.lookupValidateRef);
    }
  }, [props.lookupValidateRef]);

  useEffect(() => {
    if (!utils.isNullOrEmpty(props.isEditing)) {
      setIsEditing(props.isEditing);
    }
  }, [props.isEditing]);

  // Validates form and perform additional actions depending
  // on currently enabled refs.
  useEffect(() => {
    if (!utils.isNullOrEmpty(props.startValidation)) {
      const startValidation = props.startValidation;
      if (startValidation !== 0) {
        validateForm(lookupValidateRef).then(async (result) => {
          if (!utils.isNullOrEmpty(props.validationResult)) {
            //Return the validation result
            props.validationResult(result);
          }

          if (result === true && preview.current === true) {
            if (validateChildren().valid) {
              setPopupReportVisible(true);
            }
            preview.current = false;
          }

          if (result === true && email.current === true) {
            setPopupEmailVisible(true);
            email.current = false;
          }

          if (result === true && submitForm.current === true) {
            submitForm.current = false;
            await onSaving({ stay: formStay.current });
          }
        });
      }
    }
  }, [props.startValidation]);

  // Runs validation for the parent fields only.
  // Children datagrid rows are validated via validateChildren.
  const validateForm = (lookupRef) => {
    return new Promise((resolve, reject) => {
      validatorRef.current.validateForm(lookupRef).then((result) => {
        resolve(result);
      });
    });
  };

  // Setting a validation value will trigger the startValidation
  // useEffect hook, which is responsible for running the form
  // validation. Further actions will be carried out depending
  // on the enabled refs ie opening the report popup.
  const onValidating = (value) => {
    if (!utils.isNullOrEmpty(props.onValidating)) {
      props.onValidating(value);
    }
  };

  const setErrorForm = (e) => {
    const details = {};

    if (!utils.isNullOrEmpty(e.visible)) {
      details["visible"] = e.visible;
    }

    if (!utils.isNullOrEmpty(e.message)) {
      details["message"] = e.message;
    }

    if (!utils.isNullOrEmpty(e.type)) {
      details["type"] = e.type;
    }

    utils.displayPopupMessage(popupMessageRef, details);

    if (e.action == 1 && !utils.isNullOrEmpty(props.onShown)) {
      props.onShown();
    }
  };

  useImperativeHandle(ref, () => ({
    // validateForm(lookupRef = null) {
    // 	return new Promise((resolve, reject) => {
    // 		validatorRef.current.validateForm(lookupRef).then((result) => {
    // 			resolve(result);
    // 		});
    // 	});
    // },
    setErrorForm(e) {
      setErrorForm(e);
    },
    setPreviewReportList(list) {
      setReportList(list);
    },
    getReportPreviewLink() {
      return documentViewerLink.current;
    },
    setReportPreviewLink(value) {
      documentViewerLink.current = value;
    },
    setPreviewVisible(value) {
      setPopupReportVisible(value);
    },
    setEmailVisible(value) {
      setPopupEmailVisible(value);
    },
    openForm() {
      setFormPopup(true);
    },
    closeForm() {
      setFormPopup(false);
    },
    closeButtonAction() {
      closeButtonAction();
    },
    successfulSubmit(submissionProps) {
      const successful = submissionProps.status;

      if (successful) {
        //Open report if preview is true.
        openReport(submissionProps.docNo);

        if (!formStay.current) {
          closeButtonAction();
        }
        onValidating(0);

        const refArray = childrenGridToArray(props.childrenGridRef);
        for (var i = 0; i < refArray.length; i++) {
          utils.childrenGridSetFocusCellIndex(refArray[i], null);
          utils.childrenGridSetSource(refArray[i], []);
        }

        if (!utils.isNullOrEmpty(props.offsetGridClear)) {
          props.offsetGridClear({ Parent: {}, Children: [] });
        }

        if (!utils.isNullOrEmpty(props.onSuccessfulSubmit)) {
          props.onSuccessfulSubmit({ stay: formStay.current });
        }
      }
      closeLoading();
    },
    submitWithoutChecking(submissionProps = {}) {
      //Open report if preview
      openReport(submissionProps?.docNo);

      if (!formStay.current) {
        closeButtonAction();
      }
      onValidating(0);

      const refArray = childrenGridToArray(props.childrenGridRef);
      for (var i = 0; i < refArray.length; i++) {
        utils.childrenGridSetFocusCellIndex(refArray[i], null);
        utils.childrenGridSetSource(refArray[i], []);
      }

      if (!utils.isNullOrEmpty(props.offsetGridClear)) {
        props.offsetGridClear({ Parent: {}, Children: [] });
      }

      if (!utils.isNullOrEmpty(props.onSuccessfulSubmit)) {
        props.onSuccessfulSubmit({ stay: formStay.current });
      }
      closeLoading();
    },
    afterInitialization(formProps, data) {
      var results = [];
      var parent = null;

      //Offset by checking
      if (!utils.isNullOrEmpty(data) && !utils.isNullOrEmpty(data.offsetBy)) {
        if (!utils.isNullOrEmpty(data.model) && !utils.isNullOrEmpty(data.model.Parent)) {
          parent = data.model.Parent;
        }

        if (utils.isObject(data.offsetBy)) {
          const offsetBy = data.offsetBy;
          for (var document in offsetBy) {
            if (Array.isArray(offsetBy[document]) && offsetBy[document].length > 0) {
              const filterCopied = offsetBy[document].filter(
                (c) => c.DocumentNo !== parent.DocumentNo
              );

              if (filterCopied.length > 0) {
                results.push(document);
              }
            }
          }
        }

        if (results.length > 0) {
          setErrorForm(utils.offsetByMessage(results.join(", ")));
        }

        if (!utils.isNullOrEmpty(props.lockFormOnChanged)) {
          if (results.length > 0) {
            lockFormOnChanged(true);
          } else {
            lockFormOnChanged(false);
          }
        }
      }

      if (!utils.isNullOrEmpty(data)) {
        const formData = data.model.Parent;
        if (formData.Cancelled) {
          setErrorForm(utils.DocumentCancelledMessage);
        } // Transferred document checking
        else if (!utils.isNullOrEmpty(data.transferredBy)) {
          const transferredBy = data.transferredBy;

          if (Array.isArray(transferredBy) && transferredBy.length > 0) {
            setErrorForm(
              utils.transferredByMessage(
                transferredBy.map((document) => document.DocumentNo).join(", ")
              )
            );

            lockFormOnChanged(true);
          }
        }

        //Default values to be used for initialising children data grid rows.
        if (!utils.isNullOrEmpty(data.defaultValues) && utils.isObject(data.defaultValues)) {
          defaultValues.current = data.defaultValues;
        }

        //Before Fiscal Year
        if (!utils.isNullOrEmpty(data.beforeFiscalYear)) {
          isBeforeFiscalYear(data.beforeFiscalYear);
        }
      }
    },
    getDefaultValues() {
      return defaultValues.current;
    },
  }));

  const lockFormOnChanged = (value) => {
    if (!utils.isNullOrEmpty(props.lockFormOnChanged)) {
      props.lockFormOnChanged(value);
      setLockForm(value);
    }
  };

  const isBeforeFiscalYear = (value) => {
    if (!utils.isNullOrEmpty(props.isBeforeFiscalYear)) {
      props.isBeforeFiscalYear(value);

      if (value) {
        setErrorForm({
          visible: true,
          message: `You can only edit document that is created before current fiscal year at Opening Balance!`,
          type: "Warning",
        });
      }
    }
  };

  const notNowButton = (e) => {
    setCheckResults(true);
    setEmailToSend("");
    documentViewerLink.current = null;
    setPopupEmailVisible(false);
    setPopupReportVisible(false);

    if (props.previewClosed !== undefined) {
      props.previewClosed(false);
    }

    if (props.emailClosed !== undefined) {
      props.emailClosed(false);
    }
  };

  // Wrapper around previewReportWithoutSave.
  const directToReport = async (e) => {
    //// console.log(e)
    isDirectToReport.current = true;
    if (isEditing && lockForm && !utils.isNullOrEmpty(props.ReportDocumentNo)) {
      previewReportWithoutSave(props.ReportDocumentNo);
    } else if (
      isEditing &&
      isPrintReportClicked.current === true &&
      !utils.isNullOrEmpty(props.ReportDocumentNo)
    ) {
      previewReportWithoutSave(props.ReportDocumentNo);
      isPrintReportClicked.current = false;
    } else {
      await saveAndClose();
    }
  };

  // Preview the document report.
  const previewReportWithoutSave = (documentNo = null) => {
    if (!utils.isNullOrEmpty(documentNo)) {
      openReport(documentNo);
    }
  };

  const saveFormActionButton = async (e) => {
    const { text } = e.itemData;

    if (text === "Save & Close") {
      await saveAndClose();
    } else if (text === "Save & Print") {
      var checkCredit = await displayCheckCredit(false);

      if (!checkCredit) {
        setCheckResults(true);
      } else {
        setCheckResults(false);
        saveAndPrint();
      }
    } else if (text === "Save & Send") {
    }
  };

  // Function to call for closing popup.
  const closeButtonAction = () => {
    setCheckResults(false);
    setFormPopup(false);
    onValidating(0);
    setPopupReportVisible(false);
    setPopupEmailVisible(false);
    setLockForm(false);
    documentViewerLink.current = null;
    // isDirectToReport.current = null;

    if (!utils.isNullOrEmpty(props.onClosing)) {
      props.onClosing();
      //reset document prefix
      DocumentSettings.setDocumentPrefix("");
    }

    const refArray = childrenGridToArray(props.childrenGridRef);
    for (var i = 0; i < refArray.length; i++) {
      utils.childrenGridSetFocusCellIndex(refArray[i], null);
      utils.childrenGridSetSource(refArray[i], []);
      utils.childrenGridSetParent(refArray[i], {});
    }
  };

  const continueOnButtonClick = async () => {
    if (!utils.isNullOrEmpty(props.startValidation)) {
      formStay.current = true;
      submitForm.current = true;
      onValidating(crypto.randomUUID());
    } else {
      await onSaving();
    }
  };

  // Save the document and opens up the Preview Report popup.
  const saveAndPrint = () => {
    preview.current = true;
    onValidating(crypto.randomUUID());
  };

  // Save the document and close the document popup.
  const saveAndClose = async () => {
    if (!utils.isNullOrEmpty(props.startValidation)) {
      formStay.current = false;
      submitForm.current = true;
      onValidating(crypto.randomUUID());
    } else {
      await onSaving();
    }
  };

  const childrenGridToArray = (Gridref) => {
    var refArray = [];

    if (Array.isArray(Gridref)) {
      refArray = refArray.concat(Gridref);
    } else if (!utils.isNullOrEmpty(Gridref)) {
      refArray.push(Gridref);
    }

    return refArray;
  };

  const formActionButton = async (e) => {
    const { action } = e.itemData;

    if (action === "Copy") {
      const copiedObject = {};
      const refArray = childrenGridToArray(props.childrenGridRef);

      if (!utils.isNullOrEmpty(props.copyFields) && utils.isObject(props.copyFields)) {
        const copyData = props.copyFields;
        for (let field in copyData) {
          copiedObject[field] = copyData[field];
        }

        utils.formCopy(props.copyStorage, copiedObject, props.disabledCopyColumns);

        setErrorForm({
          visible: true,
          message: `Data Successfully Copied!`,
          type: "Success",
        });
      } else {
        setErrorForm({
          visible: true,
          message: `This form cannot be copied!`,
          type: "Warning",
        });
      }
    } else if (action === "Paste") {
      const copiedData = getFromLS(props.copyStorage);

      if (copiedData === undefined) {
        setErrorForm({
          visible: true,
          message: `No Data Copied Yet!`,
          type: "Danger",
        });
      } else {
        if (!utils.isNullOrEmpty(props.formData)) {
          const formData = props.formData;

          // Assign the existing values
          copiedData["Parent"]["DocumentNo"] = formData["DocumentNo"];
          copiedData["Parent"]["DocumentDate"] = new Date();
          copiedData["Parent"]["NextDocNo"] = formData["NextDocNo"];
          copiedData["Parent"]["IssueBy"] = formData["IssueBy"];
        }

        const refArray = childrenGridToArray(props.childrenGridRef);
        for (var i = 0; i < refArray.length; i++) {
          var name = utils.childrenGridGetName(refArray[i]);

          if (utils.isNullOrEmpty(name)) {
            //If the name prop is empty, then use storage name to replace as the object prop
            name = utils.childGridGetStorageName(refArray[i]);
          }

          if (!utils.isNullOrEmpty(name)) {
            if (!utils.isNullOrEmpty(copiedData[name])) {
              //Recalculation and format during paste
              if (!utils.isNullOrEmpty(copiedData["Parent"])) {
                copiedData["Parent"] = ParentValueCalculation(
                  copiedData[name],
                  copiedData["Parent"]
                ).parent;
              }
              utils.childrenGridSetSource(refArray[i], copiedData[name]);
            }
          }
        }

        if (!utils.isNullOrEmpty(props.onPasteForm)) {
          props.onPasteForm(copiedData);
        }

        setErrorForm({
          visible: true,
          message: `Data Successfully Pasted!`,
          type: "Success",
        });
      }
    } else if (action === "Delete") {
      const result = await yesno(
        "Are you sure you want to delete this record?",
        "Delete Confirmation",
        ["Yes", "No"]
      );
      if (result.isConfirmed) {
        if (!utils.isNullOrEmpty(props.apiURL) && !utils.isNullOrEmpty(props.formData)) {
          loading(`Deleting ${props.title}...`);
          baseapi.httpdel(props.apiURL, { id: props.formData["ID"] }).then((response) => {
            const { data } = response;
            setErrorForm(utils.deleteMessage(props, data));
            closeLoading();

            if (data.status) {
              closeButtonAction();
            }
          });
        }
      }
    } else if (action === "Print Report") {
      isPrintReportClicked.current = true;
      saveAndPrint();
    }
  };

  const onShown = (e) => {
    const element = e.component.content().parentElement.parentElement.classList;
    element.add("customized-popup-form"); //Add default class name
    if (!utils.isNullOrEmpty(props.popupClassName)) {
      element.add(props.popupClassName);
    }

    if (!utils.isNullOrEmpty(props.onShown)) {
      props.onShown();
    }
  };

  // Validate the children data and display any error messages.
  const validateChildren = () => {
    const validData = {
      valid: true,
    };
    const childrenFilterRules = props.filterRules;

    const refArray = childrenGridToArray(props.childrenGridRef);

    // Peform validation on each children grid, usually there will only be one.
    for (var i = 0; i < refArray.length; i++) {
      const source = utils.childrenGridGetSource(refArray[i]);
      const name = utils.childrenGridGetName(refArray[i]);

      // console.log("source", source, "name", name)
      // Performing children grid validation along with filtering data rows according to filter rules.
      if (
        Array.isArray(source) &&
        !utils.isNullOrEmpty(name) &&
        !utils.isNullOrEmpty(childrenFilterRules) &&
        utils.isObject(childrenFilterRules)
      ) {
        const rules = childrenFilterRules[name];
        // console.log("rules", rules)

        if (!utils.isNullOrEmpty(rules)) {
          const filteredChildren = utils.filterGridValue(source, rules);

          if (
            utils.isObject(props.requiredColumns) &&
            !utils.isNullOrEmpty(props.requiredColumns[name])
          ) {
            const requiredColumn = props.requiredColumns[name];
            const childrenValid = utils.checkGridValid(
              filteredChildren.length > 0 ? filteredChildren : source,
              requiredColumn,
              false
            );
            // console.log("children valid", childrenValid, filteredChildren);
            if (!childrenValid.valid) {
              validData.valid = false;

              if (childrenValid.brokenRules.length > 0) {
                //Display error message
                const brokenRules = childrenValid.brokenRules[0];
                //Check if there is a customized column name
                const caption = rules.find((c) => c.name === brokenRules["column"])?.caption;
                const columnName = utils.reformatStringWithSpaces(
                  caption ??
                    utils.childGridCaptionSetting[brokenRules["column"]] ??
                    brokenRules["column"]
                );
                setErrorForm({
                  visible: true,
                  message: `${columnName} is required!`,
                  type: "Warning",
                });
              }
              break;
            }
            validData[name] = filteredChildren;
          }
        }
      }
      // Perform children grid validation only.
      else if (Array.isArray(source) && !utils.isNullOrEmpty(name)) {
        if (
          utils.isObject(props.requiredColumns) &&
          !utils.isNullOrEmpty(props.requiredColumns[name])
        ) {
          const requiredColumn = props.requiredColumns[name];
          const childrenValid = utils.checkGridValid(source, requiredColumn, ref, false);
          // console.log("children valid", childrenValid);
          if (!childrenValid.valid) {
            validData.valid = false;

            if (childrenValid.brokenRules.length > 0) {
              //Display error message
              const brokenRules = childrenValid.brokenRules[0];
              //Check if there is a customized column name
              const columnName = utils.reformatStringWithSpaces(
                utils.childGridCaptionSetting[brokenRules["column"]] ?? brokenRules["column"]
              );
              setErrorForm({
                visible: true,
                message: `${columnName} is required!`,
                type: "Warning",
              });
            }
            break;
          }
          validData[name] = source;
        }
      }
    }

    if (!utils.isNullOrEmpty(props.customizedValidation)) {
      if (props.customizedValidation() === false) {
        validData.valid = false;
      }
    }

    return validData;
  };

  // Function to run when saving the document.
  const onSaving = async (value = {}) => {
    // Validate children data.
    const validData = validateChildren();
    let asyncValid = true;

    if (!utils.isNullOrEmpty(props.customizedAsyncValidation) && validData.valid) {
      asyncValid = await props.customizedAsyncValidation();
    }

    // console.log(checkResults);

    // Only proceed with the rest of the save process if the children validation has passed.
    if (
      !utils.isNullOrEmpty(props.onSaving) &&
      utils.isObject(value) &&
      validData.valid &&
      asyncValid === true
    ) {
      // console.log("Inside Here", checkResults);
      var checkCredit = await displayCheckCredit(checkResults);

      if (!checkCredit) {
        value["validData"] = validData;
        setValidatedData(value);
      } else {
        value["validData"] = validData;
        // Usually a submit handler to save the document
        // and perform additional actions such as
        // successfulSubmit function.
        props.onSaving(value);
      }
    }
  };

  async function displayCheckCredit(allowCheck = false) {
    if (!allowCheck) {
      if (props.creditChecking !== false) {
        var moduleID = props.formData.ModuleID ?? "";
        const checking = await CheckCreditLimit(
          props.formData,
          moduleID === "FAME_AR" ? true : false,
          props.isBoth,
          props.isTerm
        );
        // console.log(checking);
        if (!utils.isNullOrEmpty(checking)) {
          // console.log(checking);
          utils.displayPopupMessage(popupCreditLimitRef, {
            visible: checking.visible,
            isBoth: checking.isBoth,
            isTerm: checking.isTerm,
            isCustomer: checking.isAR,
            userType:
              checking.userType ??
              (checking.isAR === true
                ? props.formData["CustomerCode"]
                : props.formData["SupplierCode"]),
            totalOutAmt: checking.totalOutAmt,
            creditTerm: checking.creditTerm ?? props.formData["CreditTermsDays"],
            overdueDays: checking.overdueDays,
            creditLimit: checking.creditLimit,
            outstandingAmt: checking.outstandingAmt,
          });

          return false; // Validated first
        } else {
          return true; // Nothing to validate
        }
      } else {
        return true; // No Need to validate
      }
    } else {
      return true;
    }
  }

  // Open the current document's report in a new tab.
  const openReport = (DocumentNo = null) => {
    // console.log("Report Document No Received", DocumentNo)
    if (!utils.isNullOrEmpty(DocumentNo)) {
      if (isDirectToReport.current) {
        if (reportSelected !== "") {
          baseapi
            .httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`)
            .then((response) => {
              const { data } = response;
              if (Array.isArray(data)) {
                const found = data.find((report) => report.ID === reportSelected);

                if (!utils.isNullOrEmpty(found)) {
                  documentViewerLink.current = found.ViewerAccessURL + "&DocumentNo=" + DocumentNo;
                  setSharedState({ previewLoading: true });
                  loading("Redirecting to Report...");
                  window.open(documentViewerLink.current.toString(), "_blank");
                  closeLoading();
                  isDirectToReport.current = null;
                  setPopupReportVisible(false);
                } else {
                  setErrorForm({
                    visible: true,
                    message: `Report Not Found`,
                    type: "Danger",
                  });
                }
              }
            });
        }
      }
    }
  };

  // Determine the Save button's visibility.
  const saveButtonVisible = () => {
    if (isEditing ? !hasEditRight : !hasAddRight) {
      return false;
    }

    if (props.disableButtons === true || props.saveButtonOnly === true) {
      return false;
    }

    return true;
  };

  // Determine the Action button's visibility.
  const actionButtonVisible = () => {
    const filterResults = actionButtonFilter(actionButtomItems);
    if (!actionButtonEnabled) {
      return false;
    }

    if (filterResults.length === 0) {
      return false;
    }

    return true;
  };

  const actionButtonFilter = (array) => {
    if (Array.isArray(array)) {
      return (
        array
          .filter((item) => {
            // Filter out "Print Report" if access to report is false
            return item.action !== "Print Report" || reportRight;
          })
          // Filter out "Delete" and "Print Report" if not editing.
          .filter(
            (item) => isEditing || (item.action !== "Delete" && item.action !== "Print Report")
          )
          // Filter out "Delete" if there is no delete right.
          .filter((item) => deleteRight || item.action !== "Delete")
          // Filter out "Delete" if apiURL does not exist.
          .filter((item) => !utils.isNullOrEmpty(props.apiURL) || item.action !== "Delete")
          // Filter out "Copy" and "Paste" if copying is disabled.
          .filter(
            (item) =>
              (utils.isNullOrEmpty(props.copyDisabled) && props.copyDisabled !== true) ||
              (item.action !== "Copy" && item.action !== "Paste")
          )
      );
    }

    return array;
  };

  // Filters the "Save & Print" option if we don't have report rights.
  const saveButtonFilter = (array) => {
    if (Array.isArray(array)) {
      return array.filter((item) => {
        return item.text !== "Save & Print" || reportRight;
      });
    }
    return array;
  };

  //perform access right

  useEffect(() => {
    const userAccessRights = user.AccessRights;

    let hasAdd = true; // Default to true if no specific add rights found
    let hasEdit = true; // Default to true if no specific edit rights found
    let hasDirectAccess = true; // Default to true if no specific direct access rights found
    let reportAccess = true; // Default to true if no specific report rights found
    let deleteAccess = true; // Default to true if no specific delete rights found

    const alwaysGrantDirectAccessPaths = [
      "/api/SalesCashSales",
      "/api/SalesDebitNote",
      "/api/SalesCreditNote",
      "/api/SalesInvoiceMain",
      "/api/PurchaseCashPurchase",
      "/api/PurchaseInvoice",
      "/api/PurchaseDebitNote",
      "/api/PurchaseReturn",
    ];

    if (userAccessRights) {
      const accessRight = userAccessRights.find((ur) => ur.ApiURL === props.apiURL);
      if (accessRight) {
        hasAdd = accessRight.Add !== undefined ? accessRight.Add : hasAdd;
        hasEdit = accessRight.Edit !== undefined ? accessRight.Edit : hasEdit;
        reportAccess = accessRight.Report !== undefined ? accessRight.Report : reportAccess;
        deleteAccess =
          accessRight.DeleteAccess !== undefined ? accessRight.DeleteAccess : deleteAccess;

        // Check if the current apiURL should always have direct access
        if (alwaysGrantDirectAccessPaths.includes(props.apiURL)) {
          hasDirectAccess = true; // Override with true
        } else if (!accessRight.DirectAccess) {
          const details = {
            visible: true,
            message: "You Are Not Authorized To Access This Form",
            type: "Danger",
          };
          utils.displayPopupMessage(popupMessageRef, details);
          alert("You Are Not Authorized To Access This Form");
          hasDirectAccess = false; // User does not have direct access
        }
      }
    }

    // Set state with the final rights
    setHasAddRight(hasAdd);
    setHasEditRight(hasEdit);
    setHasAccess(hasDirectAccess);
    setReportRight(reportAccess);
    setDeleteRight(deleteAccess);
  }, [props.apiURL]);

  if (!hasAccess) {
    return null;
  }
  return (
    <div>
      <Popup
        visible={formPopup}
        onHiding={props.onHiding}
        animation={{
          show: { type: "fade", duration: 10 },
        }}
        dragEnabled={props.dragEnabled === undefined ? false : props.dragEnabled}
        hideOnOutsideClick={
          props.hideOnOutsideClick === undefined ? false : props.hideOnOutsideClick
        }
        showCloseButton={props.showCloseButton === undefined ? false : props.showCloseButton}
        showTitle={props.showTitle === undefined ? false : props.showTitle}
        container=".dx-viewport"
        fullScreen={props.fullScreen === undefined ? true : props.fullScreen}
        onShown={onShown}
        width={props.width === undefined ? "auto" : props.width}
        height={props.height === undefined ? "auto" : props.height}
      >
        {!utils.isNullOrEmpty(props.headerCustomization) ? (
          props.headerCustomization()
        ) : (
          <div
            className={"popup-form-title ".concat(
              props.headerClassName !== undefined ? props.headerClassName : ""
            )}
          >
            <div className="popup-form-title-grid">
              <div>{props.title === undefined ? "" : props.title}</div>

              <div className="popup-form-close-container">
                {props.cancelDocumentCustomization === undefined
                  ? null
                  : props.cancelDocumentCustomization}
                <Button
                  icon="close"
                  stylingMode="text"
                  className="popup-form-close-btn"
                  onClick={closeButtonAction}
                />
              </div>
            </div>
          </div>
        )}

        <ScrollView
          ref={scrollViewRef}
          width="100%"
          height={scrollViewHeight}
          onUpdated={(e) => {
            if (props.visible) {
              setScrollViewHeight(utils.GetScrollViewHeightV2());
              // console.log("on updated trigger", utils.GetScrollViewHeightV2())
            }
          }}
        >
          <Popup
            visible={popupReportVisible}
            hideOnOutsideClick={false}
            width={"auto"}
            height={"auto"}
            showCloseButton={false}
            dragEnabled={false}
            showTitle={true}
            title="Preview Report"
            position="center"
          >
            <SelectBox
              value={reportSelected}
              elementAttr={{ dataField: "ReportNameSelect" }}
              onValueChanged={(e) => {
                setReportSelected(e.value);
              }}
              dataSource={reportList}
              displayExpr={"name"}
              valueExpr={"id"}
              searchEnabled={true}
              showClearButton={true}
              style={{ marginBottom: "20px" }}
            />
            <Button
              text="Preview Now"
              type="default"
              onClick={async (e) => await directToReport(e)}
            />
            <Button
              text="Not Now"
              onClick={(e) => notNowButton(e)}
              type="danger"
              style={{ marginLeft: "50px" }}
            />
          </Popup>

          <CustomizedValidationGroup ref={validatorRef}>{props.children}</CustomizedValidationGroup>
        </ScrollView>

        {props.customizedFormFooter !== undefined ? (
          props.customizedFormFooter
        ) : (
          <div className="customized-popup-form-footer">
            {props.customizedFormButton !== undefined && props.customizedFormButton}
            {props.saveButtonOnly === true && (
              <Button
                text={
                  utils.isNullOrEmpty(props.saveButtonOnlyText) ? "Save" : props.saveButtonOnlyText
                }
                width={"auto"}
                id="submit-link"
                style={{ backgroundColor: "#46CF64", borderRadius: "4px", marginRight: "5px" }}
                stylingMode="text"
                elementAttr={{ class: "popup-form-submit-btn" }}
                onClick={async () => {
                  await saveAndClose();
                }}
              />
            )}
            <DropDownButton
              visible={saveButtonVisible()}
              width={"auto"}
              id="submit-link"
              splitButton={true}
              useSelectMode={true}
              text={utils.isNullOrEmpty(props.saveButtonText) ? "Save" : props.saveButtonText}
              items={saveButtonFilter(saveButtonItems)}
              displayExpr="text"
              keyExpr="id"
              dropDownOptions={{ width: 120 }}
              elementAttr={{ class: "popup-form-submit-btn" }}
              style={{ backgroundColor: "#46CF64", borderRadius: "4px", marginRight: "5px" }}
              stylingMode="text"
              onItemClick={(e) => saveFormActionButton(e, "Save")}
              onButtonClick={async () => {
                if (props.directSaveAndClose || isEditing) {
                  await saveAndClose();
                } else {
                  continueOnButtonClick();
                }
              }}
            />

            <DropDownButton
              visible={actionButtonVisible()}
              dropDownOptions={{ width: "100px" }}
              splitButton={false}
              disabled={props.actionDisabled}
              useSelectMode={false}
              text="Action"
              type="default"
              stylingMode="contained"
              items={actionButtonFilter(actionButtomItems)}
              displayExpr="action"
              keyExpr="action"
              elementAttr={{ class: "form-action-dropdown" }}
              onItemClick={formActionButton}
              style={{ marginRight: "5px" }}
            />

            {props.Einvoice && (
              <>
                <Button
                  text={props.LongID ? "Resubmit" : "Submit"}
                  width={"auto"}
                  id="submit-link"
                  style={{ backgroundColor: "#46CF64", borderRadius: "4px", marginRight: "5px" }}
                  stylingMode="text"
                  elementAttr={{ class: "popup-form-submit-btn" }}
                  onClick={props.submitEInvoice}
                  disabled={!props.EinvoiceSubmitShow || !user?.EInvoiceAccessRight?.Submit} // Button disabled if user does not have submit access
                />

                <Button
                  text={"Cancel"}
                  width={"auto"}
                  id="submit-link"
                  style={{ backgroundColor: "#daa410", borderRadius: "4px", marginRight: "5px" }}
                  stylingMode="text"
                  elementAttr={{ class: "popup-form-submit-btn" }}
                  onClick={() => {
                    props.setCancelEinovoicePopupVisible(true);
                  }}
                  disabled={props.EinvoiceSubmitShow || !user?.EInvoiceAccessRight?.Cancelled} // Button disabled if user does not have cancel access
                />
              </>
            )}

            <Button
              width={"auto"}
              text="Close"
              type="danger"
              stylingMode="contained"
              className="popup-form-submit-btn"
              useSubmitBehavior={false}
              onClick={closeButtonAction}
            />
          </div>
        )}
      </Popup>

      <CreditTermForm
        ref={popupCreditLimitRef}
        ReturnSaveResult={(e) => {
          if (e.canSave === true) {
            if (checkResults === true) {
              setCheckResults(true);
              saveAndPrint();
            } else {
              props.onSaving(validatedData);
            }
          } else {
            setCheckResults(false);
          }
        }}
      />

      <ErrorPopUpForm ref={popupMessageRef} />
    </div>
  );
});
