import React, { useState, useEffect } from "react";
import { Button } from "devextreme-react/button";
import { Toast } from "devextreme-react/toast";
import { useAuth } from "../../contexts/auth";
import baseapi from "../../api/baseapi";

const EInvoiceSubmissionComponent = () => {
  const [messages, setMessages] = useState([]);
  const { user } = useAuth();

  // Fetch E-Invoice submissions
  useEffect(() => {
    const fetchEInvoices = () => {
      baseapi
        .httpget(`/api/einvoice/GetEInvoiceSubmission?userID=${user.userID}`)
        .then((result) => {
          if (result?.data?.length > 0) {
            const newMessages = result.data.map((inv) => ({
              id: inv.ID,
              text:
                inv.SubmissionStatus === "Completed"
                  ? `E-Invoice for ${inv.DocumentNo} Has Been Processed Successfully.`
                  : `E-Invoice for ${inv.DocumentNo} Is Still Pending.`,
              status: inv.SubmissionStatus, // Get status from API
              link: `/einvoice/preview/${inv.DocumentNo}`, // Link to preview
            }));
            setMessages(newMessages);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch E-Invoice submissions:", error);
        });
    };

    if (user?.userID) {
      fetchEInvoices();
    }
  }, [user]);

  // Close Toast
  const closeToast = (id) => {
    setMessages((prev) => prev.filter((message) => message.id !== id));
  };

  return (
    <div id="container">
      {messages.map((message, index) => (
        <Toast
          key={message.id}
          visible={true}
          width={350}
          height={90}
          displayTime={36000}
          hideOnOutsideClick={false}
          closeOnSwipe={false}
          type="custom"
          position={{
            my: "bottom right",
            at: "bottom right",
            of: window,
            offset: `0 ${-index * 105}`,
          }}
          onHiding={() => closeToast(message.id)}
          contentRender={() => (
            <div
              className={`einvoice-toast-flex-box ${
                message.status === "Completed" ? "completed" : "pending"
              }`}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* Emoji Icon */}
              <span style={{ fontSize: "20px", marginRight: "8px" }}>
                {message.status === "Completed" ? "✅" : "⏳"}
              </span>

              {/* Message Content */}
              <div style={{ flex: 1, paddingRight: "8px" }}>
                <span>{message.text} &nbsp;</span>
                <a
                  href={message.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="toast-link"
                  onClick={() => closeToast(message.id)}
                >
                  View
                </a>
              </div>

              {/* Close Button */}
              <Button icon="close" stylingMode="text" onClick={() => closeToast(message.id)} />
            </div>
          )}
        />
      ))}
    </div>
  );
};

export default EInvoiceSubmissionComponent;
