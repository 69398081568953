import React, { useRef, useEffect, useState } from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';
import UserGroupsForm from './UserGroupsForm';
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";
import Listing from '../../components/listing/newListing';
import baseapi from '../../api/baseapi';


export default function UserGroups() {
    const apiURL = "/api/UserGroup";
    const [currentEditID, setCurrentEditID] = useState(null);

    useEffect(() => {
        baseapi.httpget('/api/UserGroup/list')
            .then(response => {
                const { data } = response;
                console.log(data)
            });

    },)

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    const renderIsSystemDefinedCell = (data) => {
        const commonButtonStyle = {
          padding: '5px 6px',
          borderRadius: '5%',
          border: 'none', // Remove the default button border
          cursor: 'default', // Change cursor to default
          display: 'inline-block' // Ensure the button displays inline
        };
      
        const customButtonStyle = {
          ...commonButtonStyle,
          backgroundColor: '#74B973', // Green color for "Custom"
          color: 'white',
        };
      
        const systemButtonStyle = {
          ...commonButtonStyle,
          backgroundColor: '#FF6B6B', // Red color for "System"
          color: 'white',
        };
      
        return (
          <button style={data.value ? systemButtonStyle : customButtonStyle}>
            {data.value ? 'System' : 'Custom'}
          </button>
        );
    }
    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/UserGroup/list"
                sortColumn={"Code"}
                className={"listing-datagrid"}
                listingTitle={"User Groups"}
                storageName={"UserGroupsListingColumnWidth"}
                onAddClick={setCurrentEditID}
                dateFilter={false}
                handleEdit={handleEdit}
            >
              
			  <Column visible={true} dataField="Code" caption="User Group ID"  width={400}/>
                <Column visible={true} dataField="Name" caption="Description"  width={500} />
                <Column
        visible={true}
        dataField="isSystemDefined"
        caption="Type"
        width={150}
        cellRender={renderIsSystemDefinedCell}
      />
                <Column visible={true} dataField="Active" caption="Active" width={100}/>

            </Listing>
        </div>

        <UserGroupsForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>;
}