import { HomePage } from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

import UserCompany from "./pages/usercompany/usercompany";
import User from "./pages/user/listing";
import * as Settings from "./pages/settings";
import * as Stocks from "./pages/stock";
import * as GLModules from "./pages/GL-Module";
import * as ARModules from "./pages/AR-Module";
import * as APModules from "./pages/AP-Module";
import * as SalesModule from "./pages/Sales-Module";
import * as PurchaseModule from "./pages/Purchase-Module";
import * as StockModule from "./pages/Stock-Module";
import CustomerListing from "./pages/customer/listing";
import * as GSTTax from "./pages/gsttax";
import ReportDesigner from "./pages/reportdesigner/ReportDesigner";
import DocumentViewer from "./pages/documentviewer/DocumentViewer";
import RestoreReport from "./pages/restorereport/RestoreReport";
import * as ReportListings from "./pages/reportlisting";
import SupplierListing from "./pages/supplier/listing";
import * as WorkshopModules from "./pages/Workshop-Module";
import * as TestingModules from "./pages/Testing-Module";
import Profile from "./pages/profile/profile";
import * as Tools from "./pages/Tools";
import * as Contra from "./pages/contra";
import AddCompany from "./components/register-form/RegisterForm";
import Subscription from "./components/subscription/SubscriptionForm";
import PDFViewer from "./pages/documentviewer/PDFViewer";
import ConsolidatedEInvoicesListing from "./pages/Sales-Module/consolidated-e-invoice/listing";
import ConsolidatedEInvoicesListingSupplier from './pages/AP-Module/consolidated-e-invoice/listing';

export const navigation = [
	// {
	//     title: "Testing AR",
	//     path: "/testing/ar",
	//     element: ARInvoiceMainListing,
	//     hideInMenu: true
	// },
	{
		text: "Dashboard",
		forWorkshop: false,
		path: "/home",
		icon: "chart",
		styling: ["home.scss"],
		element: HomePage
	},
	
	{
		text: "Users",
		forWorkshop: false,
		path: "/users",
		icon: "card",
		styling: ["Maintenance-Module/maintenance-module.scss"],
		element: User
	},
	{
		title: "Stock",
		forWorkshop: false,
		path: "/stocks/edit/:id",
		element: Stocks.StockEdit,
		hideInMenu: true
	},
	{
		title: "GST Tax Code",
		forWorkshop: false,
		path: "/gsttaxcode/edit/:id",
		element: GSTTax.GSTTaxEdit,
		hideInMenu: true
	},
	{
		title: "User Profile",
		forWorkshop: false,
		path: "/profile",
		element: Profile,
		styling: ["User-Setting-Module/profile.scss"],
		hideInMenu: true
	},
	{
		title: "Add Company",
		forWorkshop: false,
		path: "/addcompany",
		element: AddCompany,
		hideInMenu: true
	},
	
	// {
	//     title : "AR Bad Debt",
	//     path: '/arbaddebt/edit/:id',
	//     element: ARBadDebtsEdit.ARBadDebtsEdit,
	//     hideInMenu: true
	// },
	{
		title: "Company Profile",
		path: "/companyprofile",
		forWorkshop: false,
		element: UserCompany,
		styling: ["User-Setting-Module/profile.scss"],
		hideInMenu: true
	},
	{
		title: "Report Designer",
		path: "/reportdesigner",
		forWorkshop: false,
		styling: ["designer.scss"],
		element: ReportDesigner,
		hideInMenu: true
	},
	{
		title: "Document Viewer",
		path: "/documentviewer",
		forWorkshop: false,
		element: DocumentViewer,
		hideInMenu: true
	},

	{
		title: "PDF Viewer",
		path: "/pdfviewer",
		forWorkshop: false,
		element: PDFViewer,
		hideInMenu: true
	},
	{
		title: "Restore Report",
		path: "/restorereport",
		forWorkshop: false,
		styling: ["restore-report.scss"],
		element: RestoreReport,
		hideInMenu: true
	},
	{
		text: "Opening Balance",
		path: "/openingbalance-test",
		styling: ["GL-Module/opening-balance.scss"],
		element: GLModules.OpeningBalanceTemp,
		hideInMenu: true
	},
	{
		text: "General Ledger",
		icon: "doc",
		forWorkshop: false,
		styling: ["GL-Module/gl-module.scss"],
		items: [
			{
				text: "Chart Of Account",
				path: "/chartofaccount",
				styling: ["GL-Module/chart-account.scss"],
				element: GLModules.ChartAccount
			},
			{
				text: "Cash Book Entry",
				path: "/cashbookentry",
				element: GLModules.CashBookEntry
			},
			{
				text: "Journal Entry",
				path: "/journalentry",
				element: GLModules.JournalEntry
			},
			{
				text: "Opening Balance",
				path: "/openingbalance",
				styling: ["GL-Module/opening-balance.scss"],
				element: GLModules.OpeningBalance,
			},
			{
				text: "Stock Value Maintenance",
				path: "/stockvalue",
				element: GLModules.StockValue
			},
			{
				text: "Bank Reconciliation",
				path: "/bankreconciliation",
				element: GLModules.BankReconciliation
			},
			// {
			// 	text: "GL Trial Balance",
			// 	path: "/gltrialbalance",
			// 	element: GLTrialBalance
			// },
			{
				text: "GL Report",
				styling: ["GL-Module/chart-account.scss", "report-listing.scss"],
				items: [
					{
						text: "Balance Sheet Report",
						path: "/BalanceSheet",
						element: ReportListings.BalanceSheet
					},
					{
						text: "Profit Loss Report",
						path: "/ProfitLoss",
						element: ReportListings.ProfitLoss
					},
					{
						text: "Trial Balance Report",
						path: "/TrialBalance",
						element: ReportListings.TrialBalance
					},
					{
						text: "Ledger Report",
						path: "/GLLedger",
						element: ReportListings.GLLedgerListing
					},
					{
						text: "GL Document Listing",
						path: "/GLDocumentListing",
						element: ReportListings.DocumentListing
					},
					{
						text: "GL Journal Transaction Listing",
						path: "/JournalTransaction",
						element: ReportListings.JournalTransaction
					}
					// ,{
					// 	title: "Report Listing",
					// 	text: "Listing",
					// 	path: `/reportlisting/GLReporting`,
					// 	element: ReportListings.ReportListing
					// }
				]
			}
		]
	},
	{
		text: "Account Receivable",
		icon: "group",
		forWorkshop: false,
		styling: ["AR-Module/ar-module.scss"],
		items: [
			{
				text: "Customer",
				path: "/customers",
				element: CustomerListing
			},
			{
				title: "AR Invoice",
				text: "Customer Invoice",
				path: "/arsales",
				element: ARModules.ARSalesListing,
				// styling: ["Sales-Module/sales-module.scss", "Workshop-Module/workshop-module.scss"],
			},
			{
				title: "AR Receipt",
				text: "Customer Receipt",
				path: "/arreceipt",
				element: ARModules.ARReceiptListing
			},
			{
				title: "AR Debit Note",
				text: "Customer Debit Note",
				path: "/ardebitnote",
				element: ARModules.ARDebitNoteListing
			},
			{
				title: "AR Credit Note",
				text: "Customer Credit Note",
				path: "/arcreditnote",
				element: ARModules.ARCreditNoteListing
			},
			{
				title: "AR Refund",
				text: "Customer Refund",
				path: "/arrefund",
				element: ARModules.ARRefundListing
			},
			{
				title: "AR Contra",
				text: "Customer Contra",
				path: "/arcontra",
				styling: ["AP-Module/ap-module.scss"],
				element: Contra.ContraListing
			},
			// {
			//     title : "AR Post Dated Cheque",
			//     text: 'Post Dated Cheque',
			//     path: '/arpostdatecheque',
			//     element: ARPostDateCheque
			// },
			// {
			//     title : "AR Bad Debts",
			//     text: 'Bad Debts',
			//     path: '/arbaddebt',
			//     element: ARBadDebtsEdit.ARBadDebtsListing,
			// },
			{
				text: "Customer Report",
				styling: ["report-listing.scss"],
				items: [
					{
						text: "Customer Statement Report",
						path: "/StatementofAccountListing",
						element: ReportListings.StatementofAccountListing
					},
					{
						text: "Customer Document Listing",
						path: "/ARDocumentListing",
						element: ReportListings.DocumentListing
					},
					{
						text: "Customer Balance Report",
						path: "/ARBalanceListing",
						element: ReportListings.BalanceListing
					},
					{
						text: "Customer Ageing Report",
						path: "/ARAgeingListing",
						element: ReportListings.AgeingListing
					}
					// ,{
					// 	title: "Report Listing",
					// 	text: "Listing",
					// 	path: `/reportlisting/ARReporting`,
					// 	element: ReportListings.ReportListing
					// }
				]
			}
		]
	},
	{
		text: "Account Payable",
		icon: "user",
		forWorkshop: false,
		styling: ["AP-Module/ap-module.scss"],
		items: [
			{
				title: "AP Supplier",
				text: "Supplier",
				path: "/supplier",
				element: SupplierListing
			},
			{
				title: "AP Invoice",
				path: "/appurchase/",
				text: "Supplier Invoice",
				element: APModules.PurchaseListing
			},
			{
				title: "AP Payment",
				path: "/appayment/",
				text: "Supplier Payment",
				element: APModules.PaymentListing
			},
			{
				title: "AP Debit Note",
				path: "/apdebitnote/",
				text: "Supplier Debit Note",
				element: APModules.DebitNoteListing
			},
			{
				title: "AP Credit Note",
				path: "/apcreditnote/",
				text: "Supplier Credit Note",
				element: APModules.CreditNoteListing
			},
			{
				title: "AP Refund",
				text: "Supplier Refund",
				path: "/aprefund",
				element: APModules.APRefundListing
			},
			{
				text: "Consolidated E-Invoices",
				styling: ["report-listing.scss", "Sales-Module/sales-module.scss"],
				items: [
					{
						text: "Send Consolidated E-Invoices",
						path: "/ConsolidatedEInvoicesSupplier",
						element:  ConsolidatedEInvoicesListingSupplier
					}
					// ,{
					// 	title: "Report Listing",
					// 	text: "Listing",
					// 	path: `/reportlisting/ARReporting`,
					// 	element: ReportListings.ReportListing
					// }
				]
			},
			{
				title: "AP Contra",
				text: "Supplier Contra",
				path: "/apcontra",
				element: Contra.ContraListing
			},
			{
				text: "Supplier Report",
				styling: ["report-listing.scss"],
				items: [
					{
						text: "Supplier Statement Report",
						path: "/APStatementofAccountListing",
						element: ReportListings.APStatementofAccountListing
					},
					{
						text: "Supplier Document Listing",
						path: "/APDocumentListing",
						element: ReportListings.DocumentListing
					},
					{
						text: "Supplier Balance Report",
						path: "/APBalanceListing",
						element: ReportListings.BalanceListing
					},
					{
						text: "Supplier Ageing Report",
						path: "/APAgeingListing",
						element: ReportListings.AgeingListing
					}
					// ,{
					// 	title: "Report Listing",
					// 	text: "Listing",
					// 	path: `/reportlisting/APReporting`,
					// 	element: ReportListings.ReportListing
					// }
				]
			}
		]
	},
	{
		text: "Sales",
		icon: "doc",
		styling: ["Sales-Module/sales-module.scss"],
		items: [
			{
				text: "Sales Quotation",
				path: "/sales-quotation",
				element: SalesModule.SalesQuotationListing
			},
			{
				text: "Sales Order",
				path: "/sales-order",
				element: SalesModule.SalesOrderListing
			},
			{
				text: "Delivery Order",
				path: "/sales-deliveryorder",
				element: SalesModule.SalesDeliveryOrderListing
			},
			{
				text: "Sales Invoice",
				path: "/sales-invoice",
				element: SalesModule.SalesInvoiceMainListing
			},
			{
				text: "Cash Sales",
				path: "/sales-cashsales",
				element: SalesModule.SalesCashSalesListing
			},
			{
				text: "Sales Debit Note",
				path: "/sales-debitnote",
				element: SalesModule.SalesDebitNoteListing
			},
			{
				text: "Sales Credit Note",
				path: "/sales-creditnote",
				element: SalesModule.SalesCreditNoteListing
			},
			{
				text: "Sales Order Adjustment",
				path: "/sales-order-adjustment",
				element: SalesModule.SalesOrderAdjustmentListing
			},
			{
				text: "Sales Report",
				styling: ["report-listing.scss", "Sales-Module/sales-module.scss"],
				items: [
					{
						text: "Sales Document Listing",
						path: "/SalesDocumentListing",
						element: ReportListings.DocumentListing
					},
					{
						text: "SO Back Order Listing",
						path: "/SOBackOrderDocumentListing",
						element: ReportListings.DocumentListing
					}
				]
			}
			,
			{
				text: "Consolidated E-Invoices",
				styling: ["report-listing.scss", "Sales-Module/sales-module.scss"],
				items: [
					{
						text: "Send Consolidated E-Invoices",
						path: "/ConsolidatedEInvoices",
						element:  ConsolidatedEInvoicesListing
					}
					// ,{
					// 	title: "Report Listing",
					// 	text: "Listing",
					// 	path: `/reportlisting/ARReporting`,
					// 	element: ReportListings.ReportListing
					// }
				]
			},
		]
	},
	{
		text: "Purchase",
		icon: "doc",
		styling: ["Purchase-Module/purchase-module.scss"],
		items: [
			{
				text: "Purchase Request",
				path: "/purchase-request",
				element: PurchaseModule.PurchaseRequestListing
			},
			{
				text: "Purchase Quotation",
				path: "/purchase-quotation",
				element: PurchaseModule.PurchaseQuotationListing
			},
			{
				text: "Purchase Order",
				path: "/purchase-order",
				element: PurchaseModule.PurchaseOrderListing
			},
			{
				text: "Purchase Received",
				path: "/purchased-received",
				element: PurchaseModule.PurchaseReceivedListing
			},
			{
				text: "Purchase Invoice",
				path: "/purchase-invoice",
				element: PurchaseModule.PurchaseInvoiceListing
			},
			{
				text: "Cash Purchase",
				path: "/cash-purchase",
				element: PurchaseModule.CashPurchaseListing
			},
			{
				text: "Purchase Debit Note",
				path: "/purchase-debitnote",
				element: PurchaseModule.PurchaseDebitNoteListing
			},
			{
				text: "Purchase Return",
				path: "/purchase-return",
				element: PurchaseModule.PurchaseReturnListing
			},
			{
				text: "Purchase Order Adjustment",
				path: "/purchase-order-adjustment",
				element: PurchaseModule.PurchaseOrderAdjustmentListing
			},
			{
				text: "Purchase Report",
				styling: ["report-listing.scss"],
				items: [
					{
						text: "Purchase Document Listing",
						path: "/PurchaseDocumentListing",
						element: ReportListings.DocumentListing
					},
					{
						text: "PO Back Order Listing",
						path: "/POBackOrderDocumentListing",
						element: ReportListings.DocumentListing
					}
				]
			}
		]
	},
	{
		forWorkshop: false,
		text: "Stocks",
		icon: "box",
		styling: ["Inventory-Module/inventory-module.scss"],
		items: [
			{
				text: "Stock Item",
				path: "/stock-item",
				element: Stocks.StockListing
			},
			{
				text: "Stock Details Analysis",
				path: "/stock-details-analysis",
				element: StockModule.StockAnalysis
			},
			{
				text: "Stock Group",
				path: "/stock-group",
				element: StockModule.StockGroupListing
			},
			{
				text: "Stock Received",
				path: "/stock-received",
				element: StockModule.StockReceivedListing
			},
			{
				text: "Stock Issue",
				path: "/stock-issue",
				element: StockModule.StockIssueListing
			},
			{
				text: "Stock Adjustment",
				path: "/stock-adjustment",
				element: StockModule.StockAdjustmentListing
			},
			{
				text: "Stock Transfer",
				path: "/stock-transfer",
				element: StockModule.StockTransferListing
			},

			{
				text: "Stock Write Off",
				path: "/stock-writeoff",
				element: StockModule.StockWriteOffListing
			},
			{
				text: "Stock Take",
				path: "/stock-take",
				element: StockModule.StockTakeListing
			},
			{
				text: "Stock Report",
				styling: ["report-listing.scss"],
				items: [
					{
						text: "Stock Card Report",
						path: "/StockCardReportListing",
						element: StockModule.StockCardReport
					},
					{
						text: "Stock Balance Report",
						path: "/StockBalanceReportListing",
						element: StockModule.StockBalanceReport
					}
				]
			}
		]
	},
	{
		text: "Workshop",
		icon: "car",
		forWorkshop: true,
		styling: ["Workshop-Module/workshop-module.scss"],
		items: [
			{
				text: "Registration",
				path: "/Registration",
				element: WorkshopModules.RegistrationListing
			},
			{
				text: "Job order",
				path: "/WorkshopJobOrder",
				element: WorkshopModules.JobOrderListing
			},
			{
				text: "Quotation",
				path: "/WorkshopQuotation",
				element: WorkshopModules.QuotationListing
			},
			{
				text: "Job Sheet",
				path: "/Jobsheet",
				element: WorkshopModules.JobSheetListing
			},
			{
				title: "Workshop Invoice",
				text: "Workshop Invoice",
				path: "/workshopinvoice",
				element: WorkshopModules.WorkshopInvoiceListing,
			},
			{
				text: "Service Advisor",
				path: "/serviceadvisor",
				element: WorkshopModules.ServiceAdvisorListing,
			},
			{
				text: "Mechanic",
				path: "/mechanic",
				element: WorkshopModules.MechanicListing,
			},
			{
				text: "Vehicle Service Record",
				path: "/vehicleservicerecord",
				element: WorkshopModules.VehicleServiceRecordListing
			},
			{
				text: "Type Of Service",
				path: "/typeofservice",
				element: WorkshopModules.TypeOfServiceListing,
			},
			{
				text: "Workshop Report",
				items: [
					{
						text: "Workshop Document Listing",
						path: "/WorkshopDocumentListing",
						element: ReportListings.DocumentListing					}
					// {
					// 	title: "Report Listing",
					// 	text: "Listing",
					// 	path: `/reportlisting/WKReporting`,
					// 	element: ReportListings.ReportListing
					// }
				]
			}
		]
	},
	{
		text: "Maintenance",
		icon: "preferences",
		forWorkshop: false,
		styling: ["Maintenance-Module/maintenance-module.scss"],
		items: [
			{
				text: "Document Setting",
				path: "/DocumentSetting",
				element: Settings.DocumentNumberSetting
			},
			{
				text: "Departments",
				path: "/Departments",
				element: Settings.Department
			},
			{
				text: "Project",
				path: "/Projects",
				element: Settings.Project
			},
			{
				text: "Applications",
				path: "/Applications",
				element: Settings.Application
			},
			{
				text: "Brands",
				path: "/Brands",
				element: Settings.Brands
			},
			{
				text: "Models",
				path: "/Models",
				element: Settings.Models
			},
			// {
			// 	text: "Materials",
			// 	path: "/Materials",
			// 	element: Settings.Materials
			// },
			{
				text: "UOM",
				path: "/UOM",
				element: Settings.UOM
			},
			{
				text: "Account Types",
				path: "/AccountTypes",
				element: Settings.AccountType
			},
			{
				text: "Item Types",
				path: "/ItemTypes",
				element: Settings.ItemType
			},
			{
				text: "States",
				path: "/State",
				element: Settings.State
			},
			{
				text: "Countries",
				path: "/Country",
				element: Settings.Country
			},
			{
				text: "Locations",
				path: "/Locations",
				element: Settings.Locations
			},
			{
				text: "Salesmen",
				path: "/Salesman",
				element: Settings.Salesmen
			},
			{
				text: "Forex",
				path: "/Forex",
				element: Settings.Forex
			},
			{
				text: "Areas",
				path: "/Areas",
				element: Settings.Areas
			},
			{
				text: "Categories",
				path: "/Categories",
				element: Settings.Categories
			},
			{
				text: "Statement Types",
				path: "/StatementTypes",
				element: Settings.StatementTypes
			},
			{
				text: "Transaction Types",
				path: "/TransactionTypes",
				element: Settings.TransactionType
			},
			{
				text: "Chart Types",
				path: "/ChartTypes",
				element: Settings.ChartType
			},
			{
				text: "Journal Types",
				path: "/JournalTypes",
				element: Settings.JournalType
			},
			{
				text: "Tax Type",
				path: "/TaxType",
				element: Settings.TaxType
			},
			{
				text: "Credit Terms",
				path: "/CreditTerms",
				element: Settings.CreditTerms
			},
			{
				text: "User Groups",
				path: "/UserGroups",
				element: Settings.UserGroups
			},
			{
				text: "Plan Access Control",
				path: "/PlanAcessControl",
				element: Settings.PlanAcessControl
			}
		]
	},
	{
		title: "GST Tax Code",
		icon: "textdocument",
		text: "GST Tax",
		path: "/gsttaxcode",
		element: GSTTax.GSTTaxListing
	},
	{
		title: "Subscription Plan",
		icon: "textdocument",
		text: "Subscription Plan",
		path: "/subscribe",
		element: Subscription,
		hideInMenu: true
		
	},
	{
		icon: "toolbox",
		text: "Tools",
		forWorkshop: false,
		styling: ["reports.scss"],
		items: [
			{
				text: "Fiscal Year",
				path: "/fiscalyear",
				element: Tools.FiscalYear
			},
			{
				text: "Default Setting",
				path: "/defaultsetting",
				element: Tools.DefaultSettingListing
			},
			{
				text: "Design Report",
				path: "/reports",
				element: Tools.ReportDesignerListing
			},
			{
				text: "Audit Trail",
				path: "/audittrail",
				element: Tools.AuditTrailListing,
				styling: ["AR-Module/ar-module.scss", "AP-Module/ap-module.scss", "Sales-Module/sales-module.scss", "Purchase-Module/purchase-module.scss"]
			},
			{
				text: "User Log",
				path: "/userlog",
				element: Tools.UserLogListing
			},
			{
				text: "Decimal Settings",
				path: "/decimalSetting",
				element: Tools.DecimalSetting
			},
			{
				text: "E-Invoices",
				path: "/e-invoices",
				element: Tools.EInvoiceListing
			},

		]
	},
	{
		icon: "warning",
		text: "Testing Module",
		forWorkshop: false,
		styling: ["AR-Module/ar-module.scss"],
		items: [
			{
				text: "AR Debit Note",
				path: "/testing/ardebitnote",
				element: TestingModules.ARDebitNoteListing
			}
		]
	}
];

let routes = [];
function setupRoute(route, parent = null) {
	if (route.items) {
		route.items.forEach((child) => {
			setupRoute(child, {
				text: route.text,
				parent: parent,
				styling: route.styling
			});
		});
	} else {
		routes.push({
			path: route.path,
			element: withNavigationWatcher(route.element, route.path),
			title: route.title,
			text: route.text,
			parent: parent,
			styling: route.styling
		});
	}
}

navigation.forEach((item) => {
	setupRoute(item);
});

export default routes;
