import PopupForm from "../../../components/popup-form/PopupFormV2";
import { useRef, useState, useCallback, useEffect } from "react";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import utils, { closeLoading, loading, CheckChequeNoValid } from "../../../utils/common";
import baseapi from "../../../api/baseapi";

export default function BankReconciliationFormBFY(props) {
    const formRef = useRef(null);
    const formID = useRef(null);
    const chartCodeLookupRef = useRef(null);
    const childrenGridRef = useRef(null);
    const [startValidation, setStartValidation] = useState(0);
    const [formValue, setFormValue] = useState({});
	const [beforeFiscalYearDate, setBeforeFiscalYearDate] = useState(null);

    useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			utils.popupFormOpen(formRef);
		}
	}, [props.formID]);

    const clearForm = () => {
		props.closePopup(null);
		setFormValue({});
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

    function onValueChanged(e, receivedDataField = null) {
		// console.log("I am in value changed", e)
		try {
			// console.log("e", e)
            const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
            const obj = {};

            obj[dataField] = e.value;

            if(dataField === "ChartAccountID" && !utils.isNullOrEmpty(e.selectedItem)){
                const selectedItem = e.selectedItem;
                obj["ChartAccountCode"] = selectedItem?.code;
                obj["ChartAccountName"] = selectedItem?.name;

                loading();
                baseapi.httpget("/api/BankReconciliation/OpeningBankRecon", { chartAccountID: e.value }).then((response) => {
                    const { data } = response;
					
					if(!utils.isNullOrEmpty(data.BeforeFiscalYearDate)){
						setBeforeFiscalYearDate(data.BeforeFiscalYearDate);
					}

                    if(!utils.isNullOrEmpty(data.Children)){
                        utils.childrenGridSetSource(childrenGridRef, data.Children);
                        closeLoading();
                    }
                });
            }

            setFormValue((prevValues) => ({
                ...prevValues,
                ...obj
            }));

		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit(e) {
		if(!utils.isNullOrEmpty(e.validData)){
			const validData = e.validData;
			// console.log("valid data", validData)
			const submittedForm = {
				ChartAccountID: formValue["ChartAccountID"],
				Children : validData["Children"]
			};

			loading(`Saving ${props.title}...`);
			baseapi
			.httppost(
				utils.extendUrlVar("/api/BankReconciliation/SaveOpeningBankRecon"),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
	
			});
		}
	}

	const onInitNew = () => {
		return {
			DocumentDate: beforeFiscalYearDate,
			DebitAmount: 0,
			CreditAmount: 0
		};
	};

	const customizedAsyncValidation = async () => {
		const children = utils.childrenGridGetSource(childrenGridRef);
		const chequeNo = [];

		if(Array.isArray(children)){
			for(var i = 0; i < children.length; i++){
				const record = children[i];
				if(!utils.isNullOrEmpty(record["ChequeNo"])){
					chequeNo.push(utils.trim(record["ChequeNo"]));
				}
			}
		}

		return await CheckChequeNoValid(formRef, chequeNo, formValue["DocumentNo"]);
	};

	const gridOnRowUpdating = (e) => {
		const oldValue = e.oldData;
		const currentRow = Object.assign(oldValue, e.newData);
		var currentField = Object.keys(e.newData)[0];
		
		if (currentField === "DebitAmount") {
			currentRow["CreditAmount"] = 0;
		} else if (currentField === "CreditAmount") {
			currentRow["DebitAmount"] = 0;
		}
		else if(currentField === "DocumentNo"){
			currentRow["DocumentNo"] = utils.toUpperCase(currentRow["DocumentNo"]);
		}
		else if(currentField === "DocumentDate"){
			//Document Date validation
			if(utils.compareDates(beforeFiscalYearDate, currentRow["DocumentDate"]) === -1){
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: "Document Date must set before the current fiscal year!",
					type: "Warning",
				});

				currentRow["DocumentDate"] = beforeFiscalYearDate;
			}			
		}

		e.newData = currentRow;
	};

	const customizedValidation = () => {
		const children = utils.childrenGridGetSource(childrenGridRef);

		if(Array.isArray(children)){
			for(var i = 0; i < children.length; i++){
				const record = children[i];
				if(utils.compareDates(beforeFiscalYearDate, record["DocumentDate"]) === -1){
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: "Document Date must set before the current fiscal year!",
						type: "Warning",
					});
					return false;
				}
			}

			return true;
		}

		utils.popupFormSetErrorForm(formRef, {
			visible: true,
			message: "Invalid submission!",
			type: "Warning",
		});

		return false;
	};

    return(
        <div>
            <PopupForm
				ref={formRef}
				width={"60%"}
				height={"60%"}
				shading={true}
				showTitle={false}
				fullScreen={false}
				title={"Opening Bank Reconciliation"}
				childrenGridRef={childrenGridRef}
				creditChecking={false}
				onClosing={clearForm}
				actionButtonEnabled={false}
				disableButtons={true}
                saveButtonOnly={true}
                onValidating={setStartValidation}
				startValidation={startValidation}
				onSaving={handleSubmit}
				requiredColumns={{
					Children: ["DocumentDate", "DocumentNo", "ChequeNo", "DebitAmount", "CreditAmount"]
				}}
				lookupValidateRef={[chartCodeLookupRef]}
				customizedAsyncValidation={customizedAsyncValidation}
				headerClassName={"green-theme-border-bottom"}
				customizedValidation={customizedValidation}
			>
                <div className='popup-form-main-content' id='beforeFiscalYear-Popup-container'>
                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item'>
                            <div className='popup-group-form-label'>Chart Code </div>

                            <div className='popup-group-form-input'>
                                <CustomizedLookup
                                    ref={chartCodeLookupRef}
                                    className={"listing-datagrid"}
                                    displayExpr={"name"}
                                    valueExpr={"id"}
                                    value={formValue["ChartAccountID"]}
                                    onSelectionChanged={(e) => onValueChanged(e, "ChartAccountID")}
                                    dataSourceURL={"api/Utilities/GetGLBKAccount"}
                                    displayText={formValue["ChartAccountName"]}
                                    required={true}
                                    requiredMessage={"Chart Code is required!"}
                                    startValidation={startValidation !== 0}
                                >
                                    <Column dataField='code'></Column>
                                    <Column dataField='name'></Column>
                                </CustomizedLookup>
                            </div>
                        </div>
                    </div>            
                </div>

                <div className='children-datagrid-container' id="beforeFiscalYear-Popup" style={{ padding: "0 0" }}>
					<ChildrenDatagrid
						ref={childrenGridRef}
						name={"Children"}
						enabledDescription={false}
						keyExpr='ID'
						showBorders={true}
						loadPanel={false}
						allowColumnReordering={true}
						allowColumnResizing={true}
						storageName={"GLOpeningBalanceChildrenGrid"}
						// onFocusedCellChanged={onFocusedCellChanged}
						onRowUpdating={gridOnRowUpdating}
						// onRowRemoving={gridOnRowRemoving}
						defaultColumnValues={onInitNew}
						disabledCommonColumns={true}
						autoAddRow={false}
						height={"245px"}
					>
						<Column dataField={"DocumentNo"} caption={"Doc No"} />

						<Column 
							dataField={"DocumentDate"} 
							caption={"Date"} 
							dataType={"date"} 
							editorOptions={{ 
								min : "2000-01-01", 
								displayFormat : 'dd/MM/yyyy',
								dateSerializationFormat : 'yyyy-MM-dd',
								useMaskBehavior : true,
							}}
							format={'dd/MM/yyyy'}
						/>
                        <Column dataField={"ChequeNo"}/>
						<Column dataField={"Description"}/>
						<Column 
							dataField={"DebitAmount"} 
							caption={"Debit"} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true,
							}}
						/>

						<Column 
							dataField={"CreditAmount"} 
							caption={"Credit"} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true,
							}}
						/>

						<Summary recalculateWhileEditing={true}>
							<TotalItem column='DebitAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='CreditAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
						</Summary>
					</ChildrenDatagrid>
				</div>
            </PopupForm>
        </div>
    )
}