import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import { useNavigate } from "react-router-dom";
import baseapi from "../../api/baseapi";
import utils from "../../utils/common";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import { NumberBox } from "devextreme-react/number-box";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import { loading, closeLoading } from "../../utils/common";
import CustomizedLookup from "../../components/lookup/Lookup";
import CustomizedGridLookup from "../../components/lookup/GridLookup";
import { Validator, RequiredRule as ValidatorRequired, EmailRule, PatternRule } from "devextreme-react/validator";
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";

export default function UserForm(props) {
	const tempForm = useRef(null);
	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});
	const userGroupLookupRef = useRef(null);
	const [userGroupList, setUserGroupList] = useState([]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {

			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);




	function valueOnChange(e, receivedDataField = null) {
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);

			}
		} catch (err) {
			console.log(err);
		}
	}
	function handleSubmit({ stay }) {
		// Function to handle the save logic
		const saveUserDetails = () => {
			const loadingMessage =
				formID.current === "new"
					? "Saving User Detail & Sending Invitation Email..."
					: "Saving User Detail";
			loading(loadingMessage);

			const submittedForm = formValue;

			baseapi
				.httppost(
					utils.extendUrlVar("/api/User/save", {
						id: formID.current || "",
						del: false,
					}),
					submittedForm
				)
				.then((saveResponse) => {
					const saveData = saveResponse.data;
					utils.popupFormSuccessfulSubmit(formRef, saveData);
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: saveData.message,
						type: saveData.status ? "Success" : "Warning",
						action: saveData.action,
					});
					// Call fetchSeatData after submission
					if (props.fetchSeatData) {
						props.fetchSeatData();
					}
				})
				.catch((error) => {
					console.error("Error during save:", error);
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: "An error occurred while saving the data.",
						type: "Error",
						action: null,
					});
				});
		};


		if (formID.current === "new") {
			baseapi
				.httpget("/api/User/CheckLicensedUser")
				.then((checkResponse) => {
					const data = checkResponse.data; // Extract the data property
					if (!data.status) {
						utils.popupFormSetErrorForm(formRef, {
							visible: true,
							message: data.message,
							type: "Warning",
							action: data.action,
						});
						return; // Stop further execution
					}

					// If the check passes, proceed to save the user details
					saveUserDetails();
				})
				.catch((error) => {
					console.error("Error during licensed user check:", error);
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: "An error occurred while checking licensed user status.",
						type: "Error",
						action: null,
					});
				});
		} else {

			saveUserDetails();
		}
	}

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New User...");
		else loading("Loading Existing User Details...");

		baseapi.httpget("/api/User/GetUser?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;


			if (formID.current === "new") {
				tempForm.current = data;
				setFormValue(tempForm.current);
			} else {
				setFormValue(data);
				tempForm.current = data;
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"50%"}
				headerClassName={"maintenance-form-title-bg maintenance-popup-form-title"}
				creditChecking={false}
				title={formID.current === "new" ? "Invite User" : "Edit User Details"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
				onPasteForm={(e) => setFormValue(e.Parent)}
				formData={formValue}
				copyStorage={"UserCopiedData"}
				onValidating={setStartValidation}
				startValidation={startValidation}
				lookupValidateRef={[userGroupLookupRef]}
				isEditing={isEditing}
				onSaving={handleSubmit}
				actionButtonEnabled={false}
				apiURL={"/api/User"}

			>
				<hr className='maintenance-popup-form-hr' />
				<div className='popup-form-main-content'>
					<div className='popup-group-form-item' style={{ marginTop: '20px' }}>
						<div className='popup-group-form-label'>Email : </div>

						<div className='popup-group-form-input'>
							<AsyncTextBox
								value={formValue["Email"]}
								elementAttr={{ dataField: "Email" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								disabled={formID.current !== "new" || props.lockedForm === true}
								startValidation={startValidation !== 0}
								asyncURL={"api/User/CheckDuplicateEmail"}
								requiredMessage={"Email is required!"}
								asyncMessage={"This Email is already registered!"}
								toUpperCase={false}

							>
								<EmailRule message="Email is invalid" />
							</AsyncTextBox>
						</div>
					</div>



					<div className='popup-group-form-item' style={{ marginTop: '20px' }}>
						<div className='popup-group-form-label'>Username : </div>

						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["Username"]}
								elementAttr={{ dataField: "Username" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
							>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Username is required!"} />}</Validator>
							</TextBox>
						</div>
					</div>


					<div className='popup-group-form-item' style={{ marginTop: '20px' }}>
						<div className='popup-group-form-label'>User Role </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={userGroupLookupRef}
								className={"listing-datagrid"}
								dataSource={userGroupList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["UserRole"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "UserRole");
								}}
								startValidation={startValidation !== 0}
								required={true}
								requiredMessage={"User Group is required!"}
								dataSourceURL={"api/Utilities/GetCompanyUserRoles"}
								onDataSourceChanged={setUserGroupList}
								displayText={formValue["UserRoleCode"]}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Descripion"}></Column>
							</CustomizedLookup>

						</div>
					</div>
					<br />
				</div>
			</PopupForm>
		</div>
	);
}
