import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class Country extends React.Component {
    // render() {
    //     return <CommonTable title="Country" url="/api/Country" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Country"
                    apiURL="/api/Country"
                    listingURL="/api/Country/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingCountryListing"}
                    className={"listing-datagrid"}
                />
            </div>
        </Container>
    }
}

export default Country;