import React, { useState, useEffect } from "react";
import { Column, TotalItem, Summary } from "devextreme-react/data-grid";
import Listing from "../../components/listing/report-component/reportsListing";
import utils from "../../utils/common";
import baseapi from "../../api/baseapi";
import ARCreditNoteForm from "../AR-Module/credit-notes/form";
import ARDebitNoteForm from "../AR-Module/debit-notes/form";
import ARReceiptForm from "../AR-Module/receipt/form";
import ARSalesForm from "../AR-Module/sales/form";
import SalesInvoiceMainForm from "../Sales-Module/invoice-main/form";
import APCreditNoteForm from "../AP-Module/credit-notes/form";
import APDebitNoteForm from "../AP-Module/debit-notes/form";
import APPaymentForm from "../AP-Module/payment/form";
import APPurchaseForm from "../AP-Module/purchase/form";
import CashBookEntryForm from "../GL-Module/cash-book-entry/form";
import ARRefundForm from "../AR-Module/refund/form";
import APRefundForm from "../AP-Module/refund/form";
import SalesCashSalesForm from "../Sales-Module/cash-sales/form";
import SalesCreditNoteForm from "../Sales-Module/credit-note/form";
import SalesDebitNoteForm from "../Sales-Module/debit-note/form";
import SalesDeliveryOrderForm from "../Sales-Module/delivery-order/form";
import SalesQuotationForm from "../Sales-Module/quotation/form";
import SalesOrderForm from "../Sales-Module/order/form";
import PurchaseCashPurchaseForm from "../Purchase-Module/cash-purchase/form";
import PurchaseDebitNoteForm from "../Purchase-Module/debit-note/form";
import PurchaseQuotationForm from "../Purchase-Module/quotation/form";
import PurchaseInvoiceForm from "../Purchase-Module/invoice/form";
import PurchaseOrderForm from "../Purchase-Module/order/form";
import PurchaseReturnForm from "../Purchase-Module/purchase-return/form";
import PurchaseReceivedForm from "../Purchase-Module/received/form";
import PurchaseRequestForm from "../Purchase-Module/request/form";
import ContraForm from "../contra/form";
import JournalEntryForm from "../GL-Module/journal-entry/form";
import WorkshopInvoiceForm from "../Workshop-Module/Invoice/form";
import JobSheetForm from "../Workshop-Module/job-sheet/form";

function creditNote(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", width: "11%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "11%" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name", width: "18%" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex", width: "8%" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "OutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "OffsetAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal", width: "8%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailCNAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCNLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCNOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCNOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function debitNote(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", width: "8%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "8%" },
				{ dataField: "Code", width: "6%" },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name", width: "17%" },
				{ dataField: "Description", width: "8%" },
				{ dataField: "MainProjectCode", caption: "Project", width: "5%" },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", width: "5%" },
				{ dataField: "Forex", width: "5%" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "TaxAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "MainForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "Journal", width: "6%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailDNAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDNTaxAmount",
					column: "TaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDNMainForexAmount",
					column: "MainForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDNLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function payment(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", width: "10%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "10%" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name", width: "16%" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex", width: "7%" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "OutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "OffsetAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "BankCharges", caption: "Bank Charge", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal", width: "7%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailBankCharges",
					column: "BankCharges",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function invoice(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", width: "8%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "8%" },
				{ dataField: "Code", width: "6%" },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name", width: "17%" },
				{ dataField: "Description", width: "8%" },
				{ dataField: "MainProjectCode", caption: "Project", width: "5%" },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", width: "5%" },
				{ dataField: "Forex", width: "5%" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "TaxAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "MainForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" },
				{ dataField: "Journal", width: "6%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTaxAmount",
					column: "TaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailMainForexAmount",
					column: "MainForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function contra(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", width: "8%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "8%" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name", width: "17%" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex", width: "5%" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "TotalOutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "OutstandingAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "OtherType", caption: isAR ? "Supplier Name" : "Customer Name", width: "17%" },
				{ dataField: "OtherForex", caption: "Forex", width: "5%" },
				{ dataField: "OtherAmount", caption: isAR ? "Amount (Supplier)" : "Amount (Customer)", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal", width: "5%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOtherAmount",
					column: "OtherAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function refund(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", width: "11%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "11%" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name", width: "18%" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex", width: "8%" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "OutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "OffsetAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "11%" },
				{ dataField: "BankCharges", caption: "Bank Charge", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "8%" }
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				// { dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailBankCharges",
					column: "BankCharges",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function journal() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No", width: "10%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "7.5%" },
				{ dataField: "Description", width: "25%" },
				{ dataField: "JournalType", width: "10%" },
				{ dataField: "ForexCode", caption: "Forex", width: "5%" },
				{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7.5%" },
				{ dataField: "TotalForexDebit", caption: "Debit Incl. Tax", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "12.5%" },
				{ dataField: "TotalForexCredit", caption: "Credit Incl. Tax", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "12.5%" },
				{ dataField: "TransactionTypeCode", caption: "Type", width: "10%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailTotalForexDebit",
					column: "TotalForexDebit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTotalForexCredit",
					column: "TotalForexCredit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function journalDetail() {
	return [
		{
			detailColumns: [
				{ dataField: "ChartCode", caption: "Chart Code" },
				{ dataField: "GLDescription", caption: "GL Desc" },
				{ dataField: "DetailDesc", caption: "Description" },
				{ dataField: "DetailProjectCode", caption: "Project" },
				{ dataField: "ForexDebit", caption: "Debit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexCredit", caption: "Credit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "LocalDebit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "LocalCredit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "TaxCode" },
				{ dataField: "TaxRate", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexTaxAmount", caption: "Tax Include", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "DebitIncludeTax", caption: "Debit Incl. Tax", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "CreditIncludeTax", caption: "Credit Incl. Tax", format: { type: "fixedPoint", precision: 2 } }
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailTotal",
					summaryType: "sum",
					showInColumn: "ChartCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailForexDebit",
					column: "ForexDebit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexCredit",
					column: "ForexCredit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalDebit",
					column: "LocalDebit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalCredit",
					column: "LocalCredit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDebitIncludeTax",
					column: "DebitIncludeTax",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCreditIncludeTax",
					column: "CreditIncludeTax",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function cashBook() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No", width: "12%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "7.5%" },
				{ dataField: "Description", width: "17.5%" },
				{ dataField: "ChequeNo", caption: "Cheque", width: "10%" },
				{ dataField: "PaymentMethod", caption: "Payment Method", width: "12.5%" },
				{ dataField: "MainProjectCode", caption: "Project", width: "5%" },
				{ dataField: "ForexCode", caption: "Forex", width: "5%" },
				{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "5%" },
				// { dataField: "TotalForexAmount", caption: "Amount", format: { type: "fixedPoint", precision: 2 } },
				// { dataField: "TotalLocalAmount", caption: "Local Amount", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "TotalForexDebit", caption: "Debit", format: { type: "fixedPoint", precision: 2 }, width: "9%" },
				{ dataField: "TotalForexCredit", caption: "Credit", format: { type: "fixedPoint", precision: 2 }, width: "9%" },
				{ dataField: "TransactionTypeCode", caption: "Type", width: "7.5%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				}
			]
		}
	];
}

function cashBookDetail() {
	return [
		{
			detailColumns: [
				{ dataField: "ChartCode", caption: "Chart Code" },
				{ dataField: "GLDescription" },
				{ dataField: "Description", caption: "Description" },
				{ dataField: "DetailProjectCode", caption: "Project" },
				{ dataField: "ForexAmount", caption: "Amount", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 } }
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailChartCode",
					column: "ChartCode",
					summaryType: "sum",
					showInColumn: "ChartCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailForexAmount",
					column: "ForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function orpv() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No", width: "12.5%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "10%" },
				{ dataField: "Description", width: "20%" },
				{ dataField: "ChequeNo", caption: "Cheque", width: "10%" },
				{ dataField: "PaymentMethod", caption: "Payment Method", width: "12.5%" },
				{ dataField: "MainProjectCode", caption: "Project", width: "5%" },
				{ dataField: "ForexCode", caption: "Forex", width: "5%" },
				{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7.5%" },
				{ dataField: "TotalForexAmount", caption: "Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "TransactionTypeCode", caption: "Type", width: "7.5%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				}
			]
		}
	];
}

function salPur() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No", width: "10%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "10%" },
				{ dataField: "Type", caption: "Code", width: "10%" },
				{ dataField: "Name", caption: "Company Name", width: "20%" },
				{ dataField: "MainProjectCode", caption: "Project", width: "10%" },
				{ dataField: "AreaCode", caption: "Area", width: "10%" },
				{ dataField: "SalesmanCode", caption: "Salesman", width: "10%" },
				{ dataField: "ForexCode", caption: "Forex", width: "10%" },
				{ dataField: "TotalForexAmount", caption: "Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailTotalForexAmount",
					column: "TotalForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function salPurDetail(isImport = false) {
	return [
		{
			detailColumns: [
				{ dataField: "StockCode" },
				{ dataField: "StockDesc", caption: "Description" },
				{ dataField: "DetailProjectCode", caption: "Project" },
				{ dataField: "Quantity" },
				{ dataField: "UOMName", caption: "UOM" },
				{ dataField: "UnitPrice", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "NettPrice", caption: "Net Price", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexTaxAmount", caption: "Tax Amount", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ImportDocumentNo", visible: isImport, caption: "From Doc No" },
				{ dataField: "ImportTransCode", visible: isImport, caption: "From Doc Type" }
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailStockCode",
					column: "StockCode",
					summaryType: "sum",
					showInColumn: "StockCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailUnitPrice",
					column: "UnitPrice",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailNettPrice",
					column: "NettPrice",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexTaxAmount",
					column: "ForexTaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexAmount",
					column: "ForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function workshop() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No", width: "8%" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "8%" },
				{ dataField: "CustomerCode", caption: "Code", width: "5%" },
				{ dataField: "RegisterCode", caption: "Reg No", width: "10%" },
				{ dataField: "CustomerName", caption: "Customer", width: "12%" },
				{ dataField: "Description", caption:"Desc", width: "8%" },
				{ dataField: "MechanicCode", caption: "Mechanic", width: "8%" },
				{ dataField: "ServiceAdvisorCode", caption: "Service Advisor", width: "8%" },
				{ dataField: "ForexCode", caption: "Forex", width: "5%" },
				{ dataField: "ForexAmount", caption: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "ForexTaxAmount", caption: "Tax Amt", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "MainForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
				{ dataField: "MainLocalAmount", caption: "Local Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "7%" },
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailForexAmount",
					column: "ForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexTaxAmount",
					column: "ForexTaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailMainForexAmount",
					column: "MainForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailMainLocalAmount",
					column: "MainLocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function soPo(isSales = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy", width: "10%" },
				{ dataField: "DocumentNo", caption: "Doc. No.", width: "10%" },
				{ dataField: "Name", caption: isSales ? "Debtor" : "Creditor", width: "19%" },
				{ dataField: "Type", caption: "Code", width: "10%" },
				{ dataField: "SalesmanCode", caption: "Salesman", width: "7%" },
				{ dataField: "AreaCode", caption: "Area", width: "7%" },
				{ dataField: "ForexCode", caption: "Forex", width: "7%" },
				{ dataField: "TotalNetPrice", caption: "Net Price", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "TotalTaxAmount", caption: "Tax Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
				{ dataField: "TotalForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number", width: "10%" },
			]
		},
		{
			summaryItems: [
				{
					key: "detailDate",
					column: "Date",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailTotalNetPrice",
					column: "TotalNetPrice",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTotalTaxAmount",
					column: "TotalTaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTotalForexAmount",
					column: "TotalForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
			]
		}
	];
}

function soPoDetail(isSales = false) {
	return [
		{
			detailColumns: [
				{ dataField: "StockCode" },
				{ dataField: "Description" },
				{ dataField: "UOMCode" },
				{ dataField: "NetQuantity", caption: "Orig. Qty", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "DeliveryQty", caption: "Transfer Qty", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "BOQty", caption: "Bal. Qty", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "UnitPrice", caption: isSales ? "Unit Price" : "Unit Cost", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "SubTotal", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 } },
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailStockCode",
					column: "StockCode",
					summaryType: "sum",
					showInColumn: "StockCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailNetQuantity",
					column: "NetQuantity",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailBOQty",
					column: "BOQty",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTypeBOQty",
					column: "TypeBOQty",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexAmount",
					column: "ForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

export default function DocumentListing() {
	const [isAR, setIsAR] = useState("null");
	const [isWK, setIsWK] = useState(false);
	const [isSales, setIsSales] = useState("null");
	const [isGL, setIsGL] = useState(false);
	const [isBackOrder, setIsBackOrder] = useState(false);
	const [reportName, setReportName] = useState("Customer Invoice Listing by Area Code");
	const [currentEditID, setCurrentEditID] = useState("");
	const [form, setForm] = useState("");
	const [documentModule, setDocumentModule] = useState();
	const [combined, setCombined] = useState([]);
	const [combinedDetails, setCombinedDetails] = useState([]);
	const [url, setUrl] = useState("");
	const [storageName, setStorageName] = useState("");

	useEffect(() => {
		const pathname = window.location.pathname;

		const lastIndex = pathname.lastIndexOf("/");
		const lastSegment = lastIndex !== -1 ? pathname.substring(lastIndex + 1) : pathname;
		setUrl(lastSegment);
	}, []);

	useEffect(() => {
		if (url === "ARDocumentListing") {
			setIsGL(false);
			setDocumentModule("Customer Invoice Listing");
			setIsAR(true);
			setIsSales("null");
			// setStorageName("CustomerDocumentReportListingColumnWidth");
		} else if (url === "APDocumentListing") {
			setIsGL(false);
			setIsAR(false);
			setIsSales("null");
			setReportName("Supplier Invoice Listing by Supplier Code");
			setDocumentModule("Supplier Invoice Listing");
			// setStorageName("SupplierDocumentReportListingColumnWidth");
		} else if (url === "GLDocumentListing") {
			setIsGL(true);
			setIsAR("null");
			setIsSales("null");
			setReportName("GL - Cash Book Listing");
			setDocumentModule("GL Cash Book Listing");
		} else if (url === "SalesDocumentListing") {
			setIsGL(false);
			setIsSales(true);
			setIsAR("null");
			setReportName("Sales Quotation Listing");
			setDocumentModule("Sales Quotation Document Listing");
			setStorageName("SalesDocumentReportListingColumnWidth");
		} else if (url === "PurchaseDocumentListing") {
			setIsGL(false);
			setReportName("Purchase Quotation Listing");
			setDocumentModule("Purchase Quotation Listing");
			setIsSales(false);
			setIsAR("null");
			setStorageName("PurchaseDocumentReportListingColumnWidth");
		} else if (url === "WorkshopDocumentListing") {
			setIsGL(false);
			setReportName("WK Jobsheet Listing");
			setDocumentModule("Workshop Jobsheet Listing");
			setIsSales("null");
			setIsAR("null");
			setIsWK(true);
			setStorageName("WorkshopDocumentReportListingColumnWidth");
		} else if (url === "SOBackOrderDocumentListing") {
			setIsGL(false);
			setReportName("Sales - SO Back Order Listing");
			setDocumentModule("SO Back Order Listing");
			setIsSales(true);
			setIsAR("null");
			setIsBackOrder(true);
			setStorageName("SalesBODocumentReportListingColumnWidth");
		} else if (url === "POBackOrderDocumentListing") {
			setIsGL(false);
			setReportName("Purchase PO Back Order Listing");
			setDocumentModule("PO Back Order Listing");
			setIsSales(false);
			setIsAR("null");
			setIsBackOrder(true);
			setStorageName("PurchaseBODocumentReportListingColumnWidth");
		}
	}, [url]);

	useEffect(() => {
		if (documentModule === "Customer Invoice Listing") {
			setCombined(invoice(isAR));
			setCombinedDetails([]);
			setStorageName("CustomerInvoiceReportListingColumnWidth");
		} else if (documentModule === "Customer Receipt Listing") {
			setCombined(payment(isAR));
			setCombinedDetails([]);
			setStorageName("CustomerReceiptReportListingColumnWidth");
		} else if (documentModule === "Customer Credit Note Listing") {
			setCombined(creditNote(isAR));
			setCombinedDetails([]);
			setStorageName("CustomerCNReportListingColumnWidth");
		} else if (documentModule === "Customer Debit Note Listing") {
			setCombined(debitNote(isAR));
			setCombinedDetails([]);
			setStorageName("CustomerDNReportListingColumnWidth");
		} else if (documentModule === "Customer Refund Listing") {
			setCombined(refund(isAR));
			setCombinedDetails([]);
			setStorageName("CustomerRefundReportListingColumnWidth");
		} else if (documentModule === "Customer Contra Listing") {
			setCombined(contra(isAR));
			setCombinedDetails([]);
			setStorageName("CustomerContraReportListingColumnWidth");
		} else if (documentModule === "Supplier Invoice Listing") {
			setCombined(invoice(isAR));
			setCombinedDetails([]);
			setStorageName("SupplierInvoiceReportListingColumnWidth");
		} else if (documentModule === "Supplier Credit Note Listing") {
			setCombined(creditNote(isAR));
			setCombinedDetails([]);
			setStorageName("SupplierCNReportListingColumnWidth");
		} else if (documentModule === "Supplier Debit Note Listing") {
			setCombined(debitNote(isAR));
			setCombinedDetails([]);
			setStorageName("SupplierDNReportListingColumnWidth");
		} else if (documentModule === "Supplier Payment Listing") {
			setCombined(payment(isAR));
			setCombinedDetails([]);
			setStorageName("SupplierPaymentReportListingColumnWidth");
		} else if (documentModule === "Supplier Refund Listing") {
			setCombined(refund(isAR));
			setCombinedDetails([]);
			setStorageName("SupplierRefundReportListingColumnWidth");
		} else if (documentModule === "Supplier Contra Listing") {
			setCombined(contra(isAR));
			setCombinedDetails([]);
			setStorageName("SupplierContraReportListingColumnWidth");
		} else if (documentModule === "GL Cash Book OR Listing" || documentModule === "GL Cash Book PV Listing") {
			setStorageName("GLDocumentReportORPVListingColumnWidth");
			setCombined(orpv());
			setCombinedDetails(cashBookDetail());
		} else if (documentModule === "GL Journal Listing") {
			setStorageName("GLDocumentReportJournalListingColumnWidth");
			setCombined(journal());
			setCombinedDetails(journalDetail());
		} else if (documentModule === "GL Cash Book Listing") {
			setStorageName("GLDocumentReportListingColumnWidth");
			setCombined(cashBook());
			setCombinedDetails(cashBookDetail());
			setReportName("GL - Cash Book Listing");
		} else if (documentModule === "Debit Note Listing" || documentModule === "Credit Note Listing" || documentModule === "Cash Sales Listing" || documentModule === "Invoice Listing" || documentModule === "Delivery Order Listing") {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(true));
		} else if (
			documentModule === "Purchase Debit Note Listing" ||
			documentModule === "Cash Purchase Listing" ||
			documentModule === "Goods Received Listing" ||
			documentModule === "Purchase Return Listing" ||
			documentModule === "Purchase Invoice Listing" ||
			documentModule === "Purchase Request Listing"
		) {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(true));
		} else if (documentModule === "Quotation Listing" || documentModule === "Sales Order Listing") {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(false));
		} else if (documentModule === "Purchase Quotation Listing" || documentModule === "Purchase Order Listing") {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(false));
		} else if (documentModule === "Workshop Jobsheet Listing" || documentModule === "Workshop Invoice Listing") {
			setCombined(workshop());
			setCombinedDetails([]);
		} else if (documentModule === "SO Back Order Listing" || documentModule === "PO Back Order Listing") {
			setCombined(soPo(isSales));
			setCombinedDetails(soPoDetail(isSales));
		}
	}, [documentModule]);

	return (
		<div>
			<Listing
				className='listing-datagrid'
				onReportModuleChanged={(e) => {
					// console.log(e.value);
					if(e.value !== documentModule) {
						setCombined([]);
						setCombinedDetails([]);
						setDocumentModule(e.value);
					}
				}}
				isListing={true}
				storageName={storageName}
				disableDetails={isGL ? true : isBackOrder ? true : false }
				isGL={isGL}
				isWK={isWK}
				isBackOrder={isBackOrder}
				keyDetail={"DocumentID"}
				reportName={reportName}
				detailColumns={combinedDetails.length !== 0 ? combinedDetails[0].detailColumns : []}
				summaryDetail={combinedDetails.length !== 0 ? combinedDetails[1].detailSummaryItems : []}
			>
					{combined.length !== 0 && combined[0].columns.map((column, i) => {
						if (column && column.dataField) {
						const columnWidth = utils.getColumnWidth(column.dataField, storageName) === null ? column.width : utils.getColumnWidth(column.dataField, storageName) ?? undefined;
						const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) ?? i;
						const columnEditorOptions = column.editorOptions || {};

						// Dynamic grid properties
						const gridProps = {
							...column, // Spread existing properties
							width: columnWidth,
							visibleIndex: columnIndex,
							caption: column.caption || utils.childGridDefaultSetting(column.dataField, "Caption"),
							editorOptions: columnEditorOptions,
							format: column.format || utils.childGridDefaultSetting(column.dataField, "Format"),
							customizeText: column.customizeText || utils.childGridDefaultSetting(column.dataField, "Customize Text"),
						};

						// Special handling for DocumentNo column
						if (column.dataField === "DocumentNo") {
							return (
							<Column
								{...gridProps}
								key={column.dataField}
								cellRender={(e) => {
								const data = e.data;
								const id = data.DocumentID;
								const nonIntegerRegex = /[a-zA-Z-]+/g;

								if (data.DocumentNo !== undefined) {
									return (
									<span
										className="clickable-span-tag"
										onClick={() => {
											let valueToCheck = "";

											if (!isGL && !isWK && isSales === "null") {
												// AR, AP
												if (data.hasOwnProperty("OriginalDocumentID") && data.OriginalDocumentID) {
													// In AR/AP document listing, for Credit and Debit notes, 
													// we still  open the AR/AP version.
													if (["PPD", "PRE", "SC", "SD"].includes(data.OriginalTransactionTypeCode)) {
														valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
														setCurrentEditID(id);
													} else {
														valueToCheck = data.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
														setCurrentEditID(data.OriginalDocumentID);
													}
												} else {
													valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
													setCurrentEditID(id);
												}
											} else if (!isGL) {
												// Sales, Purchase, Wk 
												valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
												setCurrentEditID(id);
											} else {
												// GL
												if (data.hasOwnProperty("OriginalDocumentID") && data.OriginalDocumentID) {
													if (["PPD", "PRE", "SC", "SD"].includes(data.OriginalTransactionTypeCode)) {
														valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
														setCurrentEditID(id);
													} else {
														valueToCheck = data.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
														setCurrentEditID(data.OriginalDocumentID);
													}
												} else {
													valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
													setCurrentEditID(id);
												}
											}
											valueToCheck = valueToCheck ? valueToCheck.join("") : "";

											utils.transactionCodeList.find((item) => {
											if (item.TransType === valueToCheck) {
												setForm(item.DocumentType);
											}
											});
										}}
									>
										{e.displayValue}
									</span>
									);
								} else {
									return e.displayValue;
								}
								}}
							/>
							);
						}

						// Default handling for all other columns
						return <Column key={column.dataField} {...gridProps} />;
						}
						return null; // Handle invalid columns
					})}

					<Summary>{combined.length !== 0 && combined[1].summaryItems.map((item) => <TotalItem key={item.key} column={item.column} summaryType={item.summaryType} showInColumn={item.showInColumn} customizeText={item.customizeText} />)}</Summary>
			</Listing>

			{isAR === true && !isGL && (
				<div>
					{form === "Sales" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "ARDebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
					{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
					{form === "ARCreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
					{form === "Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "Contra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Customer"} lockedForm={false} />}
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
					{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{!isAR && !isGL && (
				<div>
					{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
					{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
					{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
					{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
					{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "APContra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Supplier"} lockedForm={false} />}
					{form === "CashPurchase" && <PurchaseCashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
					{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{isGL === true && isAR === "null" && isSales === "null" && (
				<div>
					{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
					{form === "CashPurchase" && <PurchaseCashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
					{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
					{form === "GLPayment" && <CashBookEntryForm formType={"Payment Voucher"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Voucher"} />}
					{form === "GLReceipt" && <CashBookEntryForm formType={"Official Receipt"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Official Receipt"} />}
					{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "Journal" && <JournalEntryForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Journal Entry"} />}
					{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{isSales === true && isAR === "null" && !isGL && (
				<div>
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Sales Entry"} />}
					{form === "Sales Credit Note" && <SalesCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note"} />}
					{form === "Sales Debit Note" && <SalesDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note"} />}
					{form === "Sales Delivery Order" && <SalesDeliveryOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Delivery Order"} />}
					{form === "Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "Sales Order" && <SalesOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Order"} />}
					{form === "Sales Quotation" && <SalesQuotationForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Quotation"} />}
					{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{!isSales && (
				<div>
					{form === "CashPurchase" && <PurchaseCashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Purchase Entry"} />}
					{form === "Purchase Request" && <PurchaseRequestForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Request"} />}
					{form === "Purchase Debit Note" && <PurchaseDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note"} />}
					{form === "Purchase Received" && <PurchaseReceivedForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Received"} />}
					{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "Purchase Order" && <PurchaseOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Order"} />}
					{form === "Purchase Return" && <PurchaseReturnForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Return"} />}
					{form === "Purchase Quotation" && <PurchaseQuotationForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Quotation"} />}
				</div>
			)}

			{isWK && (
				<div> 
					{form === "WKInvoice" && <WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "WKJobsheet" && <JobSheetForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} clearTransfer={() => false} title={"Job Sheet"} />}
				</div>
			)}
		</div>
	);
}
