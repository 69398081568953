import React, { useState, useEffect, useRef } from "react";
import { Popup } from "devextreme-react/popup";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import { loading, closeLoading } from "../../../utils/common";
import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import DropDownButton from "devextreme-react/drop-down-button";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";

export default function FiscalYearForm(props) {
	const tempForm = useRef(null);
	const formStay = useRef(false);
	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);

	const [startValidation, setStartValidation] = useState(0);
	const [formPopup, setFormPopup] = useState(false);
	const [formValue, setFormValue] = useState({});
	const [submitForm, setSubmitForm] = useState(false);
	const [actionsVisible, setActionVisible] = useState(true);
	const popupMessageRef = useRef(null);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			// console.log(props.formID);
			if (props.formID === "new") {
				setActionVisible(false);
			} else {
				setActionVisible(true);
			}
			formID.current = props.formID;
			setFormPopup(true);
		}
	}, [props.formID]);

	useEffect(() => {
		if (startValidation !== 0) {
			if (submitForm) {
				if (formStay.current) {
					formStay.current = false;
					handleSubmit(true);
				} else {
					handleSubmit();
				}
			}
		}
	}, [startValidation]);

	function handleSubmit(stay = false) {
		//console.log(dropDownList, e.validationGroup.validate(), form);
		loading("Saving Fiscal Year...");

		// End of children validation
		baseapi
			.httppost(
				utils.extendUrlVar("/api/FiscalYear/save", {
					id: formID.current || "",
					del: false
				}),
				formValue
			)
			.then((response) => {
				const { data } = response;

				if (data.status) {
					clearForm();

					//Reset Form State
					setSubmitForm(false);
				}
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
			});
		closeLoading();
	}

	const actionOptions = ["Delete"];

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Fiscal Year...");
		else loading("Loading Existing Fiscal Year...");
		baseapi.httpget("/api/FiscalYear/get", { id: formID.current || "", year: props.title }).then((response) => {
			const { data } = response;
			// console.log(data);
			if (formID.current === "new") {
				// console.log("Form", data)
				tempForm.current = data.model;
				setFormValue(tempForm.current);
			} else {
				// console.log("Form", data)
				setFormValue(data.model);
				tempForm.current = data.model;
			}

			// readDropdown(tempForm.current, data.model.Children);
			closeLoading();
			formRead.current = true;
		});
	}

	const clearForm = () => {
		setFormPopup(false);
		props.closePopup(null);
		closeLoading();
		formRead.current = false;
		setStartValidation(0);

		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);

				if (dataField === "FiscalYearName" && formRead) {
					const copied = { ...tempForm.current };

					copied["FiscalYearName"] = e.value;

					setFormValue(copied);
				} else if (dataField === "StartDate" && formRead) {
					const copied = { ...tempForm.current };

					copied["StartDate"] = e.value;

					setFormValue(copied);
				} else if (dataField === "EndDate" && formRead) {
					const copied = { ...tempForm.current };
					const toDateObj = new Date(tempForm.current["EndDate"]);
					const fromDateObj = new Date(tempForm.current["StartDate"]);

					if (fromDateObj > toDateObj) {
						utils.displayPopupMessage(popupMessageRef, {
							visible: true,
							message: "To Date cannot be earlier than From Date.",
							type: "Warning"
						});

						copied["EndDate"] = formValue["EndDate"];
					} else {
						copied["EndDate"] = e.value;
					}

					setFormValue(copied);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const onHandleDelete = () => {
		loading("Deleting Fiscal Year");
		baseapi.httppost(utils.extendUrlVar("/api/FiscalYear/delete", { id: formID.current || "", del: true }), formValue).then((response) => {
			if (props.onDeleted !== undefined) {
				props.onDeleted(formID.current);
			}
			const { data } = response;

			if (data.status) {
				setFormPopup(false);
			}
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: data.message,
				type: data.status ? "Success" : "Danger"
			});
		});
	};

	return (
		<div>
			<Popup
				id="fiscal-year-popup-id-testing"
				className="fiscal-year-popup"
				onShown={(e) => {
					if (formValue !== null) onInitialized();
				}}
				ref={formRef}
				visible={formPopup}
				hideOnOutsideClick={true}
				onHiding={clearForm}
				showTitle={true}
				title={props.title}
				dragEnabled={false}
				position='center'
				width={"auto"}
				height={"auto"}>

				<div className='tools-module-popup-form'>

				<div className='popup-group-form-item'>
					
					<div className='popup-group-form-label'>Fiscal Year Name</div>

					<div className='popup-group-form-input'>
						<TextBox
							value={formValue["FiscalYearName"]}
							elementAttr={{ dataField: "FiscalYearName" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}
							disabled={props.lockedForm === true}>
							{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Customer Name is required!"} />}</Validator> */}
						</TextBox>
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Start Date </div>

						<div className='popup-group-form-input'>

							<DateBox
								value={formValue["StartDate"]}
								elementAttr={{ dataField: "StartDate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								disabled={(props.title === "New Next Fiscal Year") === true}>
								{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Date is required!"} />}</Validator> */}
							</DateBox>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>End Date </div>

						<div className='popup-group-form-input'>
							<DateBox
								value={formValue["EndDate"]}
								elementAttr={{ dataField: "EndDate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								disabled={(props.title === "New Previous Fiscal Year") === true}>
								{/* <Validator>{startValidation !== 0 && <ValidatorRequired message={"Date is required!"} />}</Validator> */}
							</DateBox>
						</div>
					</div>
				</div>
				</div>

				<div className='popup-form-item-container2' style={{ marginTop: "30px" }}>
					<div></div>
					<div className='popup-group-form-item form-item-justify-right'>
						<Button
							onClick={() => {
								setFormPopup(false);
							}}
							className='popup-group-form-btn'
							text='Close'
							type='danger'
							width={"auto"}
						/>
						<DropDownButton
							useSelectMode={false}
							visible={actionsVisible}
							text='Actions'
							className='popup-group-form-btn'
							items={actionOptions}
							dropDownOptions={{ width: 180 }}
							onItemClick={(e) => {
								onHandleDelete();
							}}
							type='default'
							stylingMode='contained'
							elementAttr={{ class: "form-action-dropdown" }}
							style={{ marginRight: "5px" }}
						/>
						<Button
							onClick={() => {
								handleSubmit({
									formStay: false
								});
							}}
							className='popup-group-form-btn'
							text='Save'
							type='success'
							width={"auto"}
						/>
					</div>
				</div>
			</Popup>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
