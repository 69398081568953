import React, { useState, useEffect, useRef } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import JobOrderForm from './form';
import utils from "../../../utils/common";

export default function JobOrderListing() {

    const apiURL = `/api/WKJobOrder`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [lockForm, setLockForm] = useState(false);
    const [transfer, setTransfer] = useState(false);

    function handleEdit(e){
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/WKJobOrder/list"
                className={"workshop-listing-datagrid"}
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Job Sheet"}
                storageName={"WorkshopJobOrderListing"}
                onAddClick={setCurrentEditID}
                addButtonOnClick={() => {setLockForm(false);}}
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="RegNo" caption={"Reg No"} />
                <Column dataField="ModelCode" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
            </Listing>
        </div>

        <JobOrderForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            lockedForm={lockForm}
            transfer={transfer}
            clearTransfer={setTransfer}
        />

    </Container>;
}