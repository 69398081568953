import React, { useState, useEffect, useRef } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import { customerOptions, workshopRefInv, workshopRegistration, workshopTypeOfService } from '../../../utils/lookupstore';
import QuotationForm from './form';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Button } from 'devextreme-react/button';
import { useNavigate } from "react-router-dom";
import { saveToLS } from '../../../utils/localstorage';
import baseapi from '../../../api/baseapi';
import { loading, done } from '../../../utils/common';
import TransferForm from "../transfer/transfer";
import utils from "../../../utils/common";
export default function QuotationListing() {
    const apiURL = `/api/WKQuotation`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [inputType, setInputType] = useState(null);
    const [lockForm, setLockForm] = useState(false);
    const transformRef = useRef(null);
    const [transfer, setTransfer] = useState(false);

    function handleEdit(e){
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }

    const transferFormData = () => {
        setCurrentEditID("new");
        if (transformRef.current) {
            transformRef.current.onClosing();
        }
        setTransfer(true);

    };

    const onItemClick = (e) => {
        const data = e.itemData;
        if (data.name === "Transfer From Job Order") {
            utils.transformOpen(transformRef);
        }
    }
    
    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/WKQuotation/list"
                className={"workshop-listing-datagrid"}
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"Job Sheet"}
                storageName={"WorkshopQuotationListing"}
                customizedAddButton={
                    <DropDownButton
                        className="workshop-listing-page-add-btn customized-listing-add-btn"
                        splitButton={true}
                        useSelectMode={false}
                        icon='add'
                        text="Add"
                        items={[{ id: 1, name: 'Transfer From Job Order' }]}
                        displayExpr="name"
                        keyExpr="id"
                        width={"100px"}
                        dropDownOptions={
                            { width: "176px" }
                        }
                        onButtonClick={() => { setCurrentEditID("new"); setInputType(null); setLockForm(false); }}
                        onItemClick={onItemClick}
                    />
                }
                handleEdit={handleEdit}
            >
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="DocumentNo" />
                <Column dataField="RegNo" caption={"Reg No"} />
                <Column dataField="ModelCode" />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="MainForexAmount" caption="Amount" format={{ type: "fixedPoint", precision: 2 }} />
             
            </Listing>
        </div>

        <QuotationForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            inputType={inputType}
            lockedForm={lockForm}
            transfer={transfer}
            clearTransfer={setTransfer}
        />

        <TransferForm
            ref={transformRef}
            title={"Transfer From Job Order"}
            transferData={transferFormData}
            filterUrl={`api/Utilities/GetWKJobOrderFilter`}
            apiUrl={"api/WKJobOrder/ItemFilter"}
            localStorage={"WorkshopJobOrderAddToQuotation"}
        />
    </Container>;
}