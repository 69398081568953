import React, { useEffect, useState, } from 'react';
import { Column, Lookup,} from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import ARDebitNoteForm from './form';
import Listing from '../../../components/listing/newListing';

export default function ARDebitNoteListing(){

    const apiURL = `/api/ARDebitNote`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e) {
        const id = e ? e.row.data.id : 0;
        setCurrentEditID(id);
    }

    return  <Container>
        <div id='normal-listing'>
        <Listing
            className="listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/ARDebitNote/list"
            sortColumn={"DocumentDate"}
            listingTitle={"AR Debit Note"}
            storageName={"ARDebitNoteListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="CustomerCode" />
            <Column dataField="CustomerName" />
            <Column dataField="ForexID" caption={"Forex"} >
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>
        
        <ARDebitNoteForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            title={"A/R Debit Note"}
            apiURL={apiURL}
        />
    </Container>;
}