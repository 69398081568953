import { Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import appInfo from "./app-info";
import routes from "./app-routes";
import SideNavigationMenu from "./components/side-navigation-menu/SideNavigationMenuV2";
import { Footer } from "./components";
import { useEffect } from "react";
import { HeaderWithNoSide } from "./layouts/header-with-no-side/header-with-no-side";
import utils, { loading, closeLoading, showLoadingAlert, closeLoadingAlert } from "./utils/common";
import baseapi from "./api/baseapi";
import EInvoiceSubmissionComponent from "./components/e-invoice/EInvoiceSubmission";

export default function Content() {
  const location = useLocation();
  const page =
    routes.find((route) =>
      location.pathname.toLowerCase().includes(route.path.split(":id")[0].toLowerCase())
    ) === undefined
      ? "Loading..."
      : routes.find((route) =>
          location.pathname.toLowerCase().includes(route.path.split(":id")[0].toLowerCase())
        );
  const pageTitle =
    page.title !== undefined ? page.title : page.text !== undefined ? page.text : "Unknown Page";
  const renderedPage = useRef([]);
  const [cssLoaded, setCssLoaded] = useState(false);

  const extractStyles = (data) => {
    let styles = data.styling || []; // Extract styles from current level

    // Recursively extract styles from child levels
    if (data.parent) {
      styles = styles.concat(extractStyles(data.parent));
    }

    return styles;
  };

  const renderStyling = async () => {
    if (utils.isObject(page)) {
      const arr = extractStyles(page).reverse();
      const allExist = arr.every((route) => renderedPage.current.includes(route));

      if (!allExist) {
        setCssLoaded(false);
      }

      // console.log("style arr", arr);
      if (!utils.isNullOrEmpty(arr)) {
        for (const path of arr) {
          await import(`./styles/${path}`);

          if (!renderedPage.current.includes(path)) {
            renderedPage.current.push(path);
          }
        }
        setCssLoaded(true);
      }
    }
  };

  useEffect(() => {
    if (pageTitle.includes("Report Designer") || pageTitle.includes("Document Viewer")) {
      document.title = "Loading Report...";
      const reportID = new URLSearchParams(location.search).get("report");
      // console.log("id", reportID)
      baseapi
        .httpget(utils.ReportURL + `/api/Report/GetReportByID?id=${reportID}`)
        .then((response) => {
          const { data } = response;
          document.title = `${data.ReportName} - Greenplus Accounting Report`;
          // console.log("Data", data)
        })
        .catch(() => {
          throw "Network error";
        });
    } else {
      document.title = pageTitle + " - Greenplus Accounting";
    }

    renderStyling();
  }, [location]);

  useEffect(() => {
    if (cssLoaded) {
      closeLoadingAlert();
    } else {
      showLoadingAlert("Loading Styling");
    }
  }, [cssLoaded]);

  if (
    pageTitle.includes("Report Designer") ||
    pageTitle.includes("Document Viewer") ||
    pageTitle.includes("Add Company") ||
    pageTitle.includes("PDF Viewer")
  ) {
    return (
      <HeaderWithNoSide>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </HeaderWithNoSide>
    );
  } else {
    return (
      <>
        <SideNavigationMenu title={appInfo.title}>
          <Routes>
            {routes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
          <Footer>
            Copyright © {new Date().getFullYear()} {appInfo.title}
            <br />
            All trademarks or registered trademarks are property of their respective owners.
          </Footer>
        </SideNavigationMenu>
        <EInvoiceSubmissionComponent />
      </>
    );
  }
}
