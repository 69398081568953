import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../api/baseapi";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import TextArea from "devextreme-react/text-area";
import { loading, closeLoading } from "../../utils/common";
import CustomizedLookup from "../../components/lookup/Lookup";
import { Column } from "devextreme-react/data-grid";
import {
  Validator,
  RequiredRule as ValidatorRequired,
} from "devextreme-react/validator";
import PopupForm from "../../components/popup-form/PopupFormV2";

export default function SupplierForm(props) {
  const formID = useRef(null);
  const formRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [startValidation, setStartValidation] = useState(0);
  const [creditTermList, setCreditTermList] = useState([]);
  const [forexList, setForexList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [salesmanList, setSalesmanList] = useState([]);
  const [hasTransaction, setHasTransaction] = useState(false);
  const [statementTypeList, setStatementTypeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [chartAccountList, setChartAccountList] = useState([]);
  const formRead = useRef(false);
  const tempForm = useRef(null);
  const [formValue, setFormValue] = useState({});
  const forexLookupRef = useRef(null);
  const areaLookupRef = useRef(null);
  const salesmanLookupRef = useRef(null);
  const chartAccountLookupRef = useRef(null);

  useEffect(() => {
    // console.log("Form ID Changed", props.formID);
    if (props.formID !== null) {
      formID.current = props.formID;
      utils.popupFormOpen(formRef);

      if (formID.current !== "new") {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }
  }, [props.formID]);

  function valueOnChange(e, receivedDataField = null) {
    // console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
    try {
      const dataField =
        receivedDataField !== null
          ? receivedDataField
          : e.element.getAttribute("dataField");
      const copied = { ...tempForm.current };
      copied[dataField] = e.value;
      tempForm.current = copied;
      setFormValue(tempForm.current);

      if (!utils.isNullOrEmpty(e.value))
        if (dataField === "Name" && formID.current === "new") {
          let stringName = e.value;
          if (!utils.isNullOrEmpty(stringName)) {
            baseapi
              .httpget("/api/Supplier/GetSupplierRunningNumber", {
                name: e.value,
              })
              .then((response) => {
                const { data } = response;
                console.log("running number", data);
                const copied = { ...tempForm.current };
                copied["Code"] = data;
                copied["Name"] = utils.toUpperCase(e.value);

                // copied[dataField] = e.value;
                tempForm.current = copied;
                setFormValue(tempForm.current);
              });
          }
        } else if (dataField === "SupplierAddress" && formRead.current) {
          const lines = e.value.split(/\r?\n|\\n/);
          // const copied = {...tempForm.current};
          copied["SupplierAddress"] = utils.toUpperCase(e.value);
          copied["Address1"] = utils.toUpperCase(lines[0]);
          copied["Address2"] = utils.toUpperCase(lines[1]);
          copied["Address3"] = utils.toUpperCase(lines[2]);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (
          dataField === "SupplierDeliveryAddress" &&
          formRead.current
        ) {
          const lines = e.value.split(/\r?\n|\\n/);
          // const copied = {...tempForm.current};
          copied["DeliveryAddress1"] = utils.toUpperCase(lines[0]);
          copied["DeliveryAddress2"] = utils.toUpperCase(lines[1]);
          copied["DeliveryAddress3"] = utils.toUpperCase(lines[2]);
          copied["SupplierDeliveryAddress"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "ContactPerson" && formRead.current) {
          copied["ContactPerson"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "RegisterNo" && formRead.current) {
          copied["RegisterNo"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "RegisterNoOld" && formRead.current) {
          copied["RegisterNoOld"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "Remarks" && formRead.current) {
          copied["Remarks"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "GSTREGNO" && formRead.current) {
          copied["GSTREGNO"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "SSTSalesNo" && formRead.current) {
          copied["SSTSalesNo"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "SSTServiceNo" && formRead.current) {
          copied["SSTServiceNo"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        } else if (dataField === "SSTExemptionNo" && formRead.current) {
          copied["SSTExemptionNo"] = utils.toUpperCase(e.value);

          tempForm.current = copied;
          setFormValue(tempForm.current);
        }

      if (dataField === "GSTREGDATE" && formRead) {
        if (e.value === null) {
          copied["GSTREGDATE"] = new Date();
        } else {
          copied["GSTREGDATE"] = e.value;
        }
        tempForm.current = copied;
        setFormValue(tempForm.current);
      } else if (dataField === "SSTExemptionDate" && formRead) {
        // console.log(e.value);
        if (e.value === null) {
          copied["SSTExemptionDate"] = new Date(1900, 0, 1);
        } else {
          copied["SSTExemptionDate"] = e.value;
        }
        tempForm.current = copied;
        setFormValue(tempForm.current);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleCopyAddress() {
    const copiedForm = { ...formValue };

    if (copiedForm["SupplierAddress"] !== "") {
      copiedForm["DeliveryAddress1"] = copiedForm["Address1"];
      copiedForm["DeliveryAddress2"] = copiedForm["Address2"];
      copiedForm["DeliveryAddress3"] = copiedForm["Address3"];
      copiedForm["SupplierDeliveryAddress"] = copiedForm["SupplierAddress"];
    }

    setFormValue(copiedForm);
  }

  function handleSubmit(stay = false) {
    const submittedForm = formValue;
    loading("Saving Supplier...");
    baseapi
      .httppost(
        utils.extendUrlVar("/api/Supplier/save", {
          id: formID.current || "",
          del: false,
        }),
        submittedForm
      )
      .then((response) => {
        const { data } = response;
        utils.popupFormSuccessfulSubmit(formRef, data);
        utils.popupFormSetErrorForm(formRef, {
          visible: true,
          message: data.message,
          type: data.status ? "Success" : "Danger",
          action: data.action,
        });
      });
  }

  const clearForm = () => {
    props.closePopup(null);
    formRead.current = false;
    tempForm.current = {};
    setFormValue(tempForm.current);
  };

  function onInitialized(e) {
    if (formID.current === "new") loading("Loading New Supplier...");
    else loading("Loading Existing Supplier...");

    baseapi
      .httpget("/api/Supplier/Get?id=" + (formID.current || ""))
      .then((response) => {
        const { data } = response;
        if (!utils.isNullOrEmpty(data.hasTransaction)) {
          setHasTransaction(data.hasTransaction);
        } else {
          setHasTransaction(false);
        }
        console.log(data.model);
        tempForm.current = data.model;
        setFormValue(tempForm.current);
        closeLoading();
        formRead.current = true;
      });
  }

  return (
    <div>
      <PopupForm
        ref={formRef}
        onClosing={clearForm}
        width={"95%"}
        height={"90%"}
        headerClassName={"form-title-bg popup-form-title"}
        creditChecking={false}
        title={props.title}
        apiURL={props.apiURL}
        fullScreen={false}
        dropdownButtonEnabled={false}
        saveButtonNoSplit={true}
        saveContinueNoSplit={true}
        onShown={onInitialized}
        onSuccessfulSubmit={({ stay }) => {
          if (stay) onInitialized();
        }}
        disabledCopyColumns={{
          Parent: ["OutstandingAmount", "OutstandingBalance"],
        }}
        copyFields={{
          Parent: formValue,
        }}
        onPasteForm={(e) => {
          tempForm.current = e.Parent;
          setFormValue(e.Parent);
        }}
        copyStorage={"APSupplierCopiedData"}
        formData={formValue}
        onValidating={setStartValidation}
        startValidation={startValidation}
        isEditing={isEditing}
        lookupValidateRef={[
          chartAccountLookupRef,
          areaLookupRef,
          salesmanLookupRef,
        ]}
        onSaving={handleSubmit}
        cancelDocumentCustomization={
          <span className="popup-form-header-cancel">
            <CheckBox
              value={formValue["Cancelled"]}
              onValueChanged={(e) => {
                valueOnChange(e);
              }}
              elementAttr={{ dataField: "Cancelled" }}
              alignment="left"
              width={"12px"}
              height={"12px"}
              iconSize={"12px"}
              className="popup-header-cancel-input"
            />

            <span className="popup-header-cancel-text">Active</span>
          </span>
        }
      >
        <div className="popup-from-group-container">
          <div className="popup-from-group-container">
            <div className="ap-popup-form-group-name">Profile</div>
            <hr className="custom-hr2" />

            <div className={"popup-form-item-container1"}>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Supplier Name </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["Name"]}
                    elementAttr={{ dataField: "Name" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                    // disabled={formID.current !== "new"}
                  >
                    <Validator>
                      {startValidation !== 0 && (
                        <ValidatorRequired
                          message={"Company Name is required!"}
                        />
                      )}
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </div>

            <div className={"popup-group-item-container-flex"}>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Chart Account </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={chartAccountLookupRef}
									className={"listing-datagrid"}
									dataSource={chartAccountList}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["ChartAccountID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "ChartAccountID");
									}}
									startValidation={startValidation !== 0}
									required={true}
									requiredMessage={"Chart Account is required!"}
									onDataSourceChanged={setChartAccountList}
									displayText={formValue["ChartAccountCode"]}
									dataSourceURL={"api/Utilities/GetGLCreditorAccounts"}>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
						</div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Code </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["Code"]}
                    elementAttr={{ dataField: "Code" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                    disabled={false}
                  >
                    <Validator>
                      {startValidation !== 0 && (
                        <ValidatorRequired message={"Code is required!"} />
                      )}
                    </Validator>
                  </TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">
                  Business Registration No (BRN) (New){" "}
                </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["RegisterNo"]}
                    elementAttr={{ dataField: "RegisterNo" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">
                  Business Registration No (BRN) (Old){" "}
                </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["RegisterNoOld"]}
                    elementAttr={{ dataField: "RegisterNoOld" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-from-group-container">
            <div className="ap-popup-form-group-name">General</div>
            <hr className="custom-hr2" />
            <div className={"popup-form-item-container2"}>
              <div className="popup-group-form-item group-form-item-align-top">
                <div className="popup-group-form-label">Address </div>

                <div className="popup-group-form-input">
                  <TextArea
                    value={formValue["SupplierAddress"]}
                    elementAttr={{ dataField: "SupplierAddress" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                    height={100}
                  ></TextArea>
                </div>
              </div>

              <div className="popup-group-form-item group-form-item-align-top"></div>
            </div>

            <div className={"popup-form-item-container2"}>
              <div className={"popup-form-item-container2"}>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Tel </div>

                  <div className="popup-group-form-input">
                    <TextBox
                      value={formValue["Telephone"]}
                      elementAttr={{ dataField: "Telephone" }}
                      onValueChanged={(e) => {
                        valueOnChange(e);
                      }}
                      alignment="left"
                      width={"auto"}
                    ></TextBox>
                  </div>
                </div>

                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Fax </div>

                  <div className="popup-group-form-input">
                    <TextBox
                      value={formValue["Fax"]}
                      elementAttr={{ dataField: "Fax" }}
                      onValueChanged={(e) => {
                        valueOnChange(e);
                      }}
                      alignment="left"
                      width={"auto"}
                    ></TextBox>
                  </div>
                </div>

                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Mobile </div>

                  <div className="popup-group-form-input">
                    <TextBox
                      value={formValue["Mobile"]}
                      elementAttr={{ dataField: "Mobile" }}
                      onValueChanged={(e) => {
                        valueOnChange(e);
                      }}
                      alignment="left"
                      width={"auto"}
                    ></TextBox>
                  </div>
                </div>

                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Contact </div>

                  <div className="popup-group-form-input">
                    <TextBox
                      value={formValue["ContactPerson"]}
                      elementAttr={{ dataField: "ContactPerson" }}
                      onValueChanged={(e) => {
                        valueOnChange(e);
                      }}
                      alignment="left"
                    ></TextBox>
                  </div>
                </div>
              </div>

              <div className={"popup-form-item-container1"}>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Email </div>

                  <div className="popup-group-form-input">
                    <TextBox
                      value={formValue["Email"]}
                      elementAttr={{ dataField: "Email" }}
                      onValueChanged={(e) => {
                        valueOnChange(e);
                      }}
                      alignment="left"
                    ></TextBox>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={"popup-form-item-container2"}>
							<div className="popup-group-form-item">
								<div className='popup-group-form-label'>Email: </div>

								<div className="popup-group-form-input">
									<TextBox
										value={formValue["Email"]}
										elementAttr={{dataField : "Email"}}
										onValueChanged={(e) => {valueOnChange(e)}}
										alignment="left"
									>
									</TextBox>
								</div>
							</div>
						</div> */}

            <div className={"popup-form-item-container2"}></div>
          </div>

          <div className="popup-from-group-container">
            <div className="popup-form-group-name">Others</div>
            <hr className="custom-hr2" />
            <div className={"popup-form-item-container2"}>
              <div className={"popup-form-item-container1"}>
                <div className={"popup-form-item-container2"}>
                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">Area </div>

										<div className='popup-group-form-input'>
											<CustomizedLookup
												ref={areaLookupRef}
												className={"listing-datagrid"}
												dataSource={areaList}
												displayExpr={"code"}
												valueExpr={"id"}
												value={formValue["AreaID"]}
												onSelectionChanged={(e) => {
													valueOnChange(e, "AreaID");
												}}
												onDataSourceChanged={setAreaList}
												displayText={formValue["AreaCode"]}
												dataSourceURL={"api/Utilities/GetAreas"}
												startValidation={startValidation !== 0}
												required={true}
												requiredMessage={"Area is required!"}>
												<Column dataField='code'></Column>
												<Column dataField='name'></Column>
											</CustomizedLookup>
										</div>
									</div>

                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">Forex </div>

                    <div className="popup-group-form-input">
                      <CustomizedLookup
                        className={"listing-datagrid"}
                        dataSource={forexList}
                        displayExpr={"code"}
                        valueExpr={"id"}
                        value={formValue["ForexID"]}
                        onSelectionChanged={(e) => {
                          valueOnChange(e, "ForexID");
                        }}
                        startValidation={startValidation !== 0}
                        onDataSourceChanged={setForexList}
                        displayText={formValue["ForexCode"]}
                        dataSourceURL={"api/Utilities/GetForexs"}
                        required={true}
                        requiredMessage={"Forex is required!"}
                        disabled={hasTransaction}
                      >
                        <Column dataField="code"></Column>
                        <Column dataField="name"></Column>
                      </CustomizedLookup>
                    </div>
                  </div>
                </div>

                <div className={"popup-form-item-container1"}>
                  <div className="popup-group-form-item group-form-item-align-top">
                    <div className="popup-group-form-label">Remarks </div>
                    <div className="popup-group-form-input">
                      <TextArea
                        value={formValue["Remarks"]}
                        elementAttr={{ dataField: "Remarks" }}
                        onValueChanged={(e) => {
                          valueOnChange(e);
                        }}
                        alignment="left"
                        width={"auto"}
                        height={80}
                      ></TextArea>
                    </div>
                  </div>
                </div>
              </div>

              <div className={"popup-form-item-container1"}>
                <div className={"popup-form-item-container2"}>
                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">Credit Term </div>

                    <div className="popup-group-form-input">
                      <CustomizedLookup
                        className={"listing-datagrid"}
                        dataSource={creditTermList}
                        displayExpr={"code"}
                        valueExpr={"id"}
                        value={formValue["CreditTermID"]}
                        onSelectionChanged={(e) => {
                          valueOnChange(e, "CreditTermID");
                        }}
                        onDataSourceChanged={setCreditTermList}
                        startValidation={startValidation !== 0}
                        required={true}
                        requiredMessage={"Credit Term is required!"}
                        displayText={formValue["CreditTermCode"]}
                        dataSourceURL={"api/Utilities/GetCreditTerms"}
                      >
                        <Column dataField="code"></Column>
                        <Column dataField="name"></Column>
                      </CustomizedLookup>
                    </div>
                  </div>

                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">Credit Limit </div>

                    <div className="popup-group-form-input">
                      <NumberBox
                        value={formValue["CreditLimit"]}
                        elementAttr={{ dataField: "CreditLimit" }}
                        onValueChanged={(e) => {
                          valueOnChange(e);
                        }}
                        alignment="left"
                        width={"auto"}
                        format="#,##0.00"
                      ></NumberBox>
                    </div>
                  </div>
                </div>

                <div className={"popup-form-item-container1"}>
                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">Bank Acc </div>

                    <div className="popup-group-form-input">
                      <TextBox
                        value={formValue["BankAccount"]}
                        elementAttr={{ dataField: "BankAccount" }}
                        onValueChanged={(e) => {
                          valueOnChange(e);
                        }}
                        alignment="left"
                        width={"auto"}
                      />
                    </div>
                  </div>
                </div>

                <div className={"popup-form-item-container2"}>
                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">
                      Statement Type{" "}
                    </div>

                    <div className="popup-group-form-input">
                      <CustomizedLookup
                        className={"listing-datagrid"}
                        dataSource={statementTypeList}
                        displayExpr={"code"}
                        valueExpr={"id"}
                        value={formValue["StatementTypeID"]}
                        onSelectionChanged={(e) => {
                          valueOnChange(e, "StatementTypeID");
                        }}
                        onDataSourceChanged={setStatementTypeList}
                        displayText={formValue["StatementTypeCode"]}
                        dataSourceURL={"api/Utilities/GetStatementTypes"}
                      >
                        <Column dataField="code"></Column>
                        <Column dataField="name"></Column>
                      </CustomizedLookup>
                    </div>
                  </div>

                  <div className="popup-group-form-item">
                    <div className="popup-group-form-label">Salesman </div>

                    <div className="popup-group-form-input">
                      <CustomizedLookup
                        ref={salesmanLookupRef}
                        className={"listing-datagrid"}
                        dataSource={salesmanList}
                        displayExpr={"code"}
                        valueExpr={"id"}
                        value={formValue["SalesmanID"]}
                        onSelectionChanged={(e) => {
                          valueOnChange(e, "SalesmanID");
                        }}
                        onDataSourceChanged={setSalesmanList}
                        displayText={formValue["SalesmanCode"]}
                        dataSourceURL={"api/Utilities/GetSalesmans"}
                        startValidation={startValidation !== 0}
                        required={true}
                        requiredMessage={"Salesman is required!"}
                      >
                        <Column dataField="code"></Column>
                        <Column dataField="name"></Column>
                      </CustomizedLookup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="popup-from-group-container">
            <div className="ap-popup-form-group-name">Tax</div>
            <hr className="custom-hr2" />
            <div className={"popup-form-item-container2"}>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">GST Reg No </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["GSTREGNO"]}
                    elementAttr={{ dataField: "GSTREGNO" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">GST Reg Date </div>

                <div className="popup-group-form-input">
                  <DateBox
                    value={formValue["GSTREGDATE"]}
                    elementAttr={{ dataField: "GSTREGDATE" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                    displayFormat="dd/MM/yyyy"
                    dateSerializationFormat="yyyy-MM-dd"
                  ></DateBox>
                </div>
              </div>
            </div>

            <div className={"popup-form-item-container2"}>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Sales Tax No </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["SSTSalesNo"]}
                    elementAttr={{ dataField: "SSTSalesNo" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Service Tax No </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["SSTServiceNo"]}
                    elementAttr={{ dataField: "SSTServiceNo" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">
                  Tax Identification Number (TIN){" "}
                </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["TinNo"]}
                    elementAttr={{ dataField: "TinNo" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className={"popup-form-item-container2"}>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">
                  Sales Tax Exemption No{" "}
                </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={formValue["SSTExemptionNo"]}
                    elementAttr={{ dataField: "SSTExemptionNo" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className={"popup-form-item-container2"}>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Expired Date </div>

                <div className="popup-group-form-input">
                  <DateBox
                    value={formValue["SSTExemptionDate"]}
                    elementAttr={{ dataField: "SSTExemptionDate" }}
                    onValueChanged={(e) => {
                      valueOnChange(e);
                    }}
                    alignment="left"
                    width={"auto"}
                    displayFormat="dd/MM/yyyy"
                    dateSerializationFormat="yyyy-MM-dd"
                  ></DateBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopupForm>
    </div>
  );
}
