import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import StockGroupForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';

export default function StockGroupListing() {

    const apiURL = `/api/StockGroup`;
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleEdit(e){
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }
    
    return  <Container>
    <div id='normal-listing'>
        <Listing
            apiURL={apiURL}
            listingURL="/api/StockGroup/list"
            sortColumn={"Code"}
            className={"listing-datagrid"}
            listingTitle={"Stock Group"}
            storageName={"StockGroupListingColumnWidth"}
            onAddClick={setCurrentEditID}
            dateFilter={false}
            handleEdit={handleEdit}
        >
            <Column visible={true} dataField="Code" caption="Stock Group Code"/>
            <Column visible={true} dataField="Description" width={200} />
            <Column visible={true} dataField="SalesCode" caption="Sales Code" />
            <Column visible={true} dataField="CashSalesCode" caption="Cash Sales Code" />
            <Column visible={true} dataField="SalesReturnCode" caption="Sales Return Code" />
            <Column visible={true} dataField="PurchaseCode" caption="Purchase Code" />
            <Column visible={true} dataField="CashPurchaseCode" caption="Cash Purchase Code" />
            <Column visible={true} dataField="PurchaseReturnCode" caption="Purchase Return Code" />
            <Column visible={true} dataField="Active" caption="Active" width={100}/>
        </Listing>
    </div>

    <StockGroupForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            lockedForm={lockForm}
            apiURL={apiURL}
        />
        
</Container>;
       
 
}