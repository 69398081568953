import defaultUser from '../utils/default-user';
import baseapi from './baseapi';
import Swal from 'sweetalert2';
import { getFromLS, saveJsonToLS } from '../utils/localstorage';
import utils from '../utils/common';
import notify from 'devextreme/ui/notify';
export async function signIn(companycode, email, password) {
  let user = { email, companycode };
  try {
    const response = await baseapi.httppost('/oauth/token', { username: email, password, companycode, resubmit: false });
    const { data } = response;

    if (typeof data === 'string') {
      return {
        isOk: false,
        message: "Verification Needed"
      };
    } else if (data.status === 409) {
      // Handle duplicate login detected
      const result = await Swal.fire({
        title: data.message,
        text: 'Do you want to continue logging in? This will terminate the current session.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false
      });

      if (result.isConfirmed) {
        // Retry login request
        try {
          const retryResponse = await baseapi.httppost('/oauth/token', { username: email, password, companycode, resubmit: true });
          const { data: retryData } = retryResponse;

          if (retryData.status === 409) {
            return {
              isOk: false,
              message: "Duplicate Login Detected"
            };
          }

          // Handle other scenarios for successful login
          if (retryData.status === false) {
            return {
              isOk: false,
              message: retryData.message || "Account locked"
            };
          }

          const userProfile = await handleUserProfileAndAuthorization(retryData.access_token);
          return {
            isOk: true,
            data: { ...userProfile, email: userProfile.LoginID }
          };
        } catch (error) {
          return {
            isOk: false,
            message: error.response?.data?.message || "Authentication failed"
          };
        }
      } else {
        return {
          isOk: false,
          message: "Login canceled due to duplicate session."
        };
      }
    } else {
      // Handle other scenarios for successful login
      if (data.status === false) {
        return {
          isOk: false,
          message: data.message || "Account locked"
        };
      }

      const userProfile = await handleUserProfileAndAuthorization(data.access_token);
      return {
        isOk: true,
        data: { ...userProfile, email: userProfile.LoginID }
      };
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.response?.data?.message || "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    let user = getFromLS('DisplayName');
    // Send request
    const token = baseapi.getToken();
    if (token) {
      await baseapi.httpget('/api/UserProfile/Get')
        .then(({ data }) => {
          const { IsLoggedIn, DisplayName, Role, LoginID, userID, CompanyID, IdleTime, IsSubscriptionExpired, IsFreeTrialExpired, IsSubscriptionExpiringSoon,IsFreeTrialExpiringSoon, DayLeft,TimeLeft, CompanySubscriptionEndDate,IsUsingFreeTrial, UpcomingSubscriptionPlan, PlanAccessControl, AccessRights , EInvoiceAccessRight} = data;
          saveJsonToLS({ IsLoggedIn, LoginID, DisplayName, Role, userID, CompanyID, IdleTime });
          user = { ...user, IsLoggedIn, LoginID, DisplayName, Role, email: LoginID, userID, CompanyID, IdleTime,IsSubscriptionExpired, IsFreeTrialExpired, IsSubscriptionExpiringSoon,IsFreeTrialExpiringSoon,DayLeft,TimeLeft,CompanySubscriptionEndDate,IsUsingFreeTrial, UpcomingSubscriptionPlan, PlanAccessControl, AccessRights, EInvoiceAccessRight}
        
        });
      return {
        isOk: true,
        data: user
      };
    }
  }
  catch {

  }
  return {
    isOk: false
  };
}


export async function handleUserProfileAndAuthorization(accessToken) {
  try {
    // Set the authorization token
    baseapi.setAuthorization(accessToken);

    // Fetch user profile
    const userProfileResponse = await baseapi.httpget('/api/UserProfile/Get');
    const { data: userProfileData } = userProfileResponse;

    // Extract and save user details
    const { IsLoggedIn, DisplayName, Role, LoginID, userID, CompanyID, IsWorkshop, IdleTime,IsSubscriptionExpired, IsFreeTrialExpired, IsSubscriptionExpiringSoon,IsFreeTrialExpiringSoon,DayLeft,TimeLeft,CompanySubscriptionEndDate,IsUsingFreeTrial, UpcomingSubscriptionPlan,PlanAccessControl, AccessRights, EInvoiceAccessRight } = userProfileData;
    saveJsonToLS({ IsLoggedIn, LoginID, DisplayName, Role, userID, CompanyID, IdleTime });


    return {
      IsLoggedIn, LoginID, DisplayName, Role, userID, IsWorkshop, IdleTime, CompanyID,IdleTime,IsSubscriptionExpired, IsFreeTrialExpired, IsSubscriptionExpiringSoon,IsFreeTrialExpiringSoon,DayLeft,TimeLeft,CompanySubscriptionEndDate,IsUsingFreeTrial, UpcomingSubscriptionPlan, PlanAccessControl, AccessRights, EInvoiceAccessRight
    };
  } catch (error) {
    throw new Error("Failed to handle user profile and authorization");
  }
}
export async function createAccount(email, password) {
  try {
    // Send request
    // console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    // console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    // console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
