import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column, Button as ButtonColumn} from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import ServiceAdvisorForm from './form';

export default function ServiceAdvisorListing(){
    const apiURL = `/api/ServiceAdvisor`;
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }
    return <Container>
        <div id='normal-listing'>
        <Listing
             apiURL={apiURL}
             listingURL="/api/ServiceAdvisor/list"
             sortColumn={"Code"}
             className={"listing-datagrid"}
             listingTitle={"Service Advisor"}
             storageName={"ServiceAdvisorListingColumnWidth"}
             onAddClick={setCurrentEditID}
             dateFilter={false}
             handleEdit={handleEdit}
        >
            <Column dataField="Code" caption={"Advisor Code"} />
            <Column dataField="Name" dataType={"string"}/>
            <Column dataField="ContactNo"  dataType={"string"}/>
            <Column dataField="Commission"  dataType={"number"}/>
            <Column dataField={"Active"} />
        </Listing>
        </div>

        <ServiceAdvisorForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>;
}