import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import baseapi from '../../api/baseapi';
import utils, { done, processing, notfound } from '../../utils/common';
// import Form, { Item, GroupItem, Label, ButtonItem, ButtonOptions, ColCountByScreen } from 'devextreme-react/form';
import Container from '../../components/container';
import { stateOptions, countryOptions, industryCodeOptions, } from '../../utils/lookupstore';
import { TextBox } from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import FileUploader from 'devextreme-react/file-uploader';
import { loading, closeLoading } from "../../utils/common";
import Validator, { RequiredRule, PatternRule,EmailRule, CompareRule, CustomRule, AsyncRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import CustomizedHtmlEditor from '../../components/html-editor/CustomizedHtmlEditor';
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import CustomizedLookup from "../../components/lookup/Lookup";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
export default function UserCompany() {
    const navigate = useNavigate();
    const tempForm = useRef(null);
    const validatorRef = useRef(null);
    const logoRef = useRef(null);
    const htmlEditorRef = useRef(null);
    const [startValidation, setStartValidation] = useState(false);
    const [dropDownList, setDropDownList] = useState({
        stateList: [], countryList: [], industryCodeList: [],
    });

    const insutryCodeLookUpRef = useRef(null);
    const [formValue, setFormValue] = useState({});
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [textVisible, setTextVisible] = useState(true);
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
    const [industryCodeList, setIndustryCodeList] = useState([]);
    const popupMessageRef = useRef(null);
    useEffect(() => {
        Promise.allSettled([stateOptions.store.load(), countryOptions.store.load(), industryCodeOptions.store.load()]).then((lists) => {
            setDropDownList({
                stateList: lists[0].value.data, countryList: lists[1].value.data, industryCodeList: lists[2].value.data
            })

            onInitialized();
        });
    }, [])

    useEffect(() => {
        if (startValidation) {
            handleSubmit();
        }
    }, [startValidation]);

    function onInitialized(e) {
        loading("Loading Company Profile...");
        setStartValidation(false);
        baseapi.httpget('/api/UserCompany/Get')
            .then(response => {
                const { data } = response;
                tempForm.current = data[0];
                setFormValue(tempForm.current);
                const reportHeaderContent = tempForm.current["ReportHeader"] === undefined ? "" : tempForm.current["ReportHeader"];

                htmlEditorRef.current.setValue(reportHeaderContent);
                logoRef.current.instance.reset();

                if (data[0].Logo) {
                    setTextVisible(false);
                }
                closeLoading();
            }).catch(error => {
                closeLoading();

            });


    }

    function handleSubmit(e) {
        const res = validatorRef.current.instance.validate();
        const logores = logoRef.current.instance;

        if (res.status === "valid" && res.isValid && (logores._files.length < 1 || logores._files[0].isValid())) {
            let tid = formValue.id;
            formValue["ReportHeader"] = htmlEditorRef.current.getValue();
            var formData = new FormData();
            for (var i in formValue)
                if (i != 'id') formData.append(i, formValue[i]);

            if (selectedFiles !== null) formData.append('LogoFile', selectedFiles);

            baseapi.upload(utils.extendUrlVar('/api/UserCompany/save?', { id: (tid || ''), del: false }), formData)
                .then((response) => {
                    const { data } = response;

                    utils.displayPopupMessage(popupMessageRef, {
                        visible: true,
                        message: data.message,
                        type: data.status ? "Success" : "Warning",
                    });

                    if (data.status) {

                        onInitialized()

                    }
                });
        } else {
            setStartValidation(false);
        }
    }

    function handleCancel() {
        navigate('/home');
    }

    function valueOnChange(e, receivedDataField = null) {

        try {
            // console.log("I am in value changed", e.value, "data field", e.element.getAttribute("dataField"))
            const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
            const copied = { ...tempForm.current };
            copied[dataField] = e.value;
            tempForm.current = copied;
            setFormValue(tempForm.current);
          
        }
        catch (err) {
            console.log(err)
        }
    }

    function onSelectedFilesChanged(e) {
        if (e.value.length > 0) {
            setIsDropZoneActive(false);

            setTextVisible(false);
            setSelectedFiles(e.value[0]);
            var reader = new FileReader();
            reader.onload = (function (theFile) {
                return function (e) {
                    setFormValue({ ...formValue, ...{ "Logo": e.target.result } })
                };
            })(e.value[0]);

            reader.readAsDataURL(e.value[0]);
        }
    }

    return <Container>
        <ValidationGroup ref={validatorRef}>


            <div className='company-profile-form-title-bg'>Company Profile</div>
            <div className='profile-parent-section'>

                <div className="popup-form-item-container2">
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Name: </div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Name"]}
                                elementAttr={{ dataField: "Name" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >

                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Register No: </div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["RegisterNo"]}
                                elementAttr={{ dataField: "RegisterNo" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >

                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Address 1: </div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Address1"]}
                                elementAttr={{ dataField: "Address1" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Tin No: </div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["TinNo"]}
                                elementAttr={{ dataField: "TinNo" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Address 2:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Address2"]}
                                elementAttr={{ dataField: "Address2" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Sale Tax Reg No:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["SaleTaxRegNo"]}
                                elementAttr={{ dataField: "SaleTaxRegNo" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Address 3:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Address3"]}
                                elementAttr={{ dataField: "Address3" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Service Tax Reg No:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["ServiceTaxRegNo"]}
                                elementAttr={{ dataField: "ServiceTaxRegNo" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Post Code:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Postcode"]}
                                elementAttr={{ dataField: "Postcode" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Phone No:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["PhoneNo"]}
                                elementAttr={{ dataField: "PhoneNo" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >

                                <Validator>
                                    <PatternRule
                                        pattern="^\d{10,11}$"
                                        message="Phone Number must contain only numbers and be 10 or 11 digits"
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>State:</div>

                        <div className="popup-group-form-input">
                            <SelectBox
                                dataSource={dropDownList.stateList}
                                value={formValue["StateID"]}
                                elementAttr={{ dataField: "StateID" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                                displayExpr={"name"}
                                valueExpr={"id"}
                            >
                            </SelectBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Fax No:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["FaxNo"]}
                                elementAttr={{ dataField: "FaxNo" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                            </TextBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Country:</div>

                        <div className="popup-group-form-input">
                            <SelectBox
                                dataSource={dropDownList.countryList}
                                value={formValue["CountryID"]}
                                elementAttr={{ dataField: "CountryID" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                                displayExpr={"name"}
                                valueExpr={"id"}
                            >
                            </SelectBox>
                        </div>
                    </div>

                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Email:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Email"]}
                                elementAttr={{ dataField: "Email" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                            >
                                <Validator>
                                    <EmailRule message="Email is invalid" />
                                </Validator>
                            </TextBox>
                        </div>
                    </div>
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Industry Code:</div>

                        <div className="popup-group-form-input">
                            <CustomizedLookup
                                ref={insutryCodeLookUpRef}
                                dataSource={industryCodeList}
                                displayExpr={"code"}
                                valueExpr={"code"}
                                value={formValue["IndustryCode"]}
                                onSelectionChanged={(e) => {
                                    valueOnChange(e, "IndustryCode");
                                }}
                                
                                dataSourceURL={"api/Utilities/GetCompanyBusinessCode"}
                                onDataSourceChanged={setIndustryCodeList}

                                displayText={formValue["IndustryCode"] === "null" ? "" : formValue["IndustryCode"]}>
                                <Column dataField='code'></Column>
                                <Column dataField='name' caption={"Descripion"}></Column>
                            </CustomizedLookup>
                        </div>
                    </div>
                </div>
            </div>

            <div className='form-grid-layout'>
                <div className='popup-form-item-container1'>
                    <div className='company-profile-form-title-bg' style={{ marginBottom: '10px' }}>Company Logo</div>

                    {/* <div style={{ marginRight: '20px' }}>
                        {formValue['Logo'] ? <img src={formValue['Logo']} className='img-thumbnail' alt="Company Logo" /> : null}
                    </div> */}

                    <div className="popup-group-form-item">
                        <div className="widget-container drag-and-drop-image-flex-box">
                            <div
                                id="companyprofile-dropzone-external"
                                className={`drag-and-drop-image-flex-box ${isDropZoneActive
                                    ? 'dx-theme-accent-as-border-color dropzone-active'
                                    : 'dx-theme-border-color'
                                    }`}
                            >

                                {textVisible && (
                                    <div
                                        id="dropzone-text"
                                        className="drag-and-drop-image-flex-box"
                                    >
                                        <span>Drag & Drop the desired file …</span>
                                        <span>or click to browse for a file instead.</span>
                                    </div>
                                )}
                                {formValue['Logo'] ? <img src={formValue['Logo']} className='img-thumbnail' alt="Company Logo"></img> : null}


                            </div>
                            <div className="center-content">
                                <FileUploader
                                    id="file-uploader1"
                                    dialogTrigger="#companyprofile-dropzone-external"
                                    dropZone="#companyprofile-dropzone-external"
                                    multiple={false}
                                    allowedFileExtensions={allowedFileExtensions}
                                    ref={logoRef}
                                    maxFileSize={10000000}
                                    selectButtonText="Select Logo"
                                    labelText=""
                                    accept="image/*"
                                    buttonOptions={{
                                        stylingMode: 'contained',
                                        type: 'success',
                                    }}
                                    uploadMode="useButtons"
                                    onValueChanged={onSelectedFilesChanged}
                                    className="custom-file-uploader"
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='popup-form-item-container1'>
                    <div className='company-profile-form-title-bg' style={{ padding: '0 0px', marginBottom: '10px' }}>Report Header</div>
                    <CustomizedHtmlEditor
                        ref={htmlEditorRef}
                        imageEnabled={true}
                        spaceCustomized={true}
                        enabledDivTag={true}
                    />
                    <div className="profile-modal-footer">
                        <Button
                            width={"auto"}
                            text="Save Changes"
                            type="success"
                            stylingMode="contained"
                            useSubmitBehavior={false}
                            className="popup-form-submit-btn"
                            onClick={(e) => {
                                setStartValidation(true);
                            }}
                        />

                        <Button
                            width={"auto"}
                            text="Cancel"
                            type="danger"
                            stylingMode="contained"
                            onClick={handleCancel}
                            className="popup-group-form-btn"
                        />

                    </div>
                </div>
            </div>
        </ValidationGroup>

        <ErrorPopUpForm ref={popupMessageRef} />
    </Container >;
}