import React, { useEffect, useState, useRef } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import baseapi from "../../api/baseapi";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import DataGrid, {
  LoadPanel,
  Column,
  Editing,
  Selection,
  Scrolling,
  Lookup,
  KeyboardNavigation,
  ColumnChooser,
  Summary,
  TotalItem,
  Sorting,
  Pager,
  Paging,
  Item as ToolbarItem,
} from "devextreme-react/data-grid";
import { DateBox, NumberBox } from "devextreme-react";
import { Popup, Button } from "devextreme-react";
import Swal from "sweetalert2";
import CustomizedLookup from "../../components/lookup/Lookup";

export default function EInvoiceMain(props) {
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [invoiceDetailDataSource, setInvoiceDetailDataSource] = useState([]);
  const [isCancelEinovoicePopupVisible, setCancelEinovoicePopupVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [isHistoryPopupVisible, setHistoryPopupVisible] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    if (props.formID !== null) {
      utils.popupFormOpen(formRef);
    }
  }, [props.formID]);

  async function onInitialized(e) {
    loading();
    let result = await baseapi.httpget(
      "/api/einvoice/GetPreviewDocument?docNo=" + props.formID + "&invCode=" + props.invoiceType
    );
    setInvoiceDetail(result?.data?.data);
    setInvoiceDetailDataSource(result?.data?.data?.InvoiceDetail);
    closeLoading();
  }

  async function submitEInvoice(e) {
    loading("Submitting E-Invoice");
    let result = await baseapi.httpget("/api/einvoice/SubmitDocument", {
      docNo: props.formID,
      invCode: props.invoiceType,
      transactionTypeID: invoiceDetail?.TransactionTypeID,
    });
    setInvoiceDetail(result?.data?.data);
    setInvoiceDetailDataSource(result?.data?.data?.InvoiceDetail);
    closeLoading();
    if (result?.data?.status == "invalid") {
      Swal.fire({
        type: "info",
        title: "Opps!",
        text: result?.data?.error?.message,
        showConfirmButton: false,
      });
    }
  }

  const clearForm = () => {
    setInvoiceDetail(null);
    setHistoryData(null);
    props.closePopup(null);
  };

  // Handle reason input change
  const handleReasonChange = (e) => {
    setCancelReason(e.value);
  };

  const cancelEInvoiceDocument = async () => {
    try {
      const endpoint = "/api/EInvoice/CancelDocument";

      const response = await baseapi.httppost(endpoint, {
        uuid: invoiceDetail?.UUID,
        reason: cancelReason,
        documentNo: invoiceDetail?.DocumentNo,
        invCode: props.invoiceType,
      });

      setCancelEinovoicePopupVisible(false);
      const { data } = response;

      // Show the popup with success message
      utils.popupFormSuccessfulSubmit(formRef, data);
      utils.popupFormSetErrorForm(formRef, {
        visible: true,
        message: data.message,
        type: data.status ? "Success" : "Danger",
        action: data.action,
      });

      if (data.status === true) {
        // Set the latest invoice detail data
        setInvoiceDetail((prevInvoiceDetail) => ({
          ...prevInvoiceDetail,
          cancelDateTime: data.data.CancelDate,
          documentStatusReason: data.data.CancelReason,
          status: data.data.Status,
        }));
      }
    } catch (error) {
      console.error("Error cancelling/rejecting document:", error);
    }
  };

  const handleViewHistoryClick = () => {
    setHistoryPopupVisible(true);
    fetchEInvoiceHistory(); // Fetch Data when opening Popup
  };

  async function fetchEInvoiceHistory() {
    loading("Fetching Invoice History...");
    const response = await baseapi.httpget("/api/einvoice/GetEInvoiceHistory", {
      docNo: invoiceDetail?.DocumentNo,
      transactionTypeID: invoiceDetail?.TransactionTypeID,
    });

    closeLoading();
    if (response?.data.status) {
      setHistoryData(response.data.data); // Set data to DataGrid
    }
  }

  return (
    <div>
      <PopupForm
        ref={formRef}
        onClosing={clearForm}
        fullScreen={false}
        actionButtonEnabled={false}
        disableButtons={true}
        width={"95%"}
        height={"90%"}
        headerClassName={"e-invoice-module-form-title-bg popup-form-title"}
        title={props.title}
        onShown={onInitialized}
        Einvoice={true}
        submitEInvoice={submitEInvoice}
        EinvoiceSubmitShow={invoiceDetail?.status?.toLowerCase() !== "valid"}
        setCancelEinovoicePopupVisible={setCancelEinovoicePopupVisible}
        LongID={invoiceDetail?.LongID}
        cancelDocumentCustomization={
          <span className="popup-form-header-view-history">
            <Button
              icon="clock"
              hint="View History"
              stylingMode="text"
              text="View History"
              onClick={handleViewHistoryClick}
            />
          </span>
        }
      >
        <div>
          <div className="popup-group-form-group" id="bottom-form-group">
            <h4 style={{ marginBlock: "10px", marginLeft: "40px", paddingTop: "10px" }}>
              E-Invoice Info
            </h4>
          </div>
          <div className="popup-form-main-content">
            <div className="e-invoice-module-parent-section custom-form-grid-section1">
              <div className="">
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">UUID: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.UUID}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Submission UID: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.submissionUID}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Long ID: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.LongID}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Digital Signature: </div>
                  <div className="popup-group-form-input">
                    <TextBox alignment="left" width={"auto"} readOnly={true} />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Version: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.typeVersionName}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Link: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.url}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Reception Date: </div>
                  <div className="popup-group-form-input">
                    <DateBox
                      type="datetime"
                      value={invoiceDetail?.dateTimeIssued}
                      dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Validate Date: </div>
                  <div className="popup-group-form-input">
                    <DateBox
                      type="datetime"
                      value={invoiceDetail?.dateTimeValidated}
                      dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Cancel Date: </div>
                  <div className="popup-group-form-input">
                    <DateBox
                      type="datetime"
                      value={invoiceDetail?.cancelDateTime}
                      dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Cancel Reason: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.documentStatusReason}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="popup-group-form-item">
                  <div className="popup-group-form-label">Status: </div>
                  <div className="popup-group-form-input">
                    <TextBox
                      value={invoiceDetail?.status}
                      alignment="left"
                      width={"auto"}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="popup-form-item-container">
                <div className="popup-group-form-item">
                  {invoiceDetail?.qrcode && (
                    <img
                      src={invoiceDetail.qrcode}
                      style={{ width: "250px", height: "250px" }}
                      alt="QR Code"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "40px" }}>Document Info</h4>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Document Type: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.DocumentType}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Internal ID: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.DocumentNo}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Forex Code: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ForexCode}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container2">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Local Rate: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.LocalRate}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Transaction Date: </div>

                <div className="popup-group-form-input">
                  <DateBox
                    type="datetime"
                    value={invoiceDetail?.TransactionDate}
                    dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></DateBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Forex Rate: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ForexRate}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-input">
                  <CheckBox text="Foreign Buyer" />
                  <CheckBox style={{ marginTop: 10 }} text="Government Buyer" />
                </div>
                <div className="popup-group-form-input">
                  <CheckBox value={invoiceDetail?.IsConsolidated} text="Consolidated" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "40px" }}>Buyer Info</h4>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Name: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.CustomerName}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Address 1: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.CustomerAddress1}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Address 2: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.CustomerAddress2}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Address 3: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.CustomerAddress3}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Post Code: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.PostCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container2">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Register No: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerRegistrationNumber}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Identity Type: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Identification (TIN): </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerTIN}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">SST: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SSTSalesNo}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Handphone: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.HandPhone}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Email: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Email}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">City: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">State: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.State}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.StateCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Country: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Country}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.CountryCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "40px" }}>Seller Info</h4>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Name: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierName}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Address 1: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierAddress1}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Address 2: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierAddress2}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Address 3: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierAddress3}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Post Code: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierPostCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Business Activity: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BusinessActivity}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container2">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Register No: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierRegistrationNumber}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">MSIC: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.MSIC}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Tax Identification (TIN): </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierTIN}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S SST: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierSSTSalesNo}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Handphone: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierHandPhone}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Email: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierEmail}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">City: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S State: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierState}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierStateCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Country: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierCountry}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierCountryCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Line Ext Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    value={invoiceDetail?.LineExtAmt}
                    format="#,##0.00"
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Ext Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    format="#,##0.00"
                    value={invoiceDetail?.TaxExtAmt}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Inc Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    format="#,##0.00"
                    value={invoiceDetail?.RoundingAmount}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Discount Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    format="#,##0.00"
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.ItemDiscountAmt}
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Payable Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    value={invoiceDetail?.RoundingAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    format="#,##0.00"
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Total Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    value={invoiceDetail?.RoundingAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    format="#,##0.00"
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Taxable Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.LineExtAmt}
                    alignment="left"
                    width={"auto"}
                    format="#,##0.00"
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Amt: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    value={invoiceDetail?.TaxExtAmt}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                    format="#,##0.00"
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Rounding: </div>

                <div className="popup-group-form-input">
                  <NumberBox
                    value={invoiceDetail?.RoundingAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    format="#,##0.00"
                    alignment="left"
                    width={"auto"}
                    readOnly={true}
                  ></NumberBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content" style={{}}>
          <div
            className="inventory-module-border-bottom custom-form-grid-section e-invoice-grid-section"
            style={{}}
          >
            <DataGrid
              id="id"
              showBorders={true}
              width={"100%"}
              dataSource={invoiceDetailDataSource}
              allowColumnReordering={true}
              allowColumnResizing={true}
              rowHeight={50}
              hoverStateEnabled={true}
              rowAlternationEnabled={false}
              columnAutoWidth={true}
              errorRowEnabled={false}
              loadPanel={{ enabled: true }}
              height={400}
              focusedRowEnabled={false}
            >
              <Sorting mode="multiple" />
              <Column
                dataField="No"
                width="120px"
                caption="No"
                editorOptions={{ disabled: true }}
              />
              <Column
                dataField="StockCode"
                caption={"Stock Code"}
                editorOptions={{ readOnly: true }}
                width="250px"
              />
              <Column
                dataField="Description"
                caption={"Description"}
                editorOptions={{ readOnly: true }}
                width="400px"
              />
              <Column
                dataField="Loc"
                caption="Loc"
                editorOptions={{ disabled: true }}
                width="80px"
              />
              <Column
                dataField="Qty"
                caption="Qty"
                dataType="number"
                editorOptions={{ disabled: true }}
                width="100px"
              />
              <Column
                dataField="UOMCode"
                caption="UOM"
                dataType="number"
                editorOptions={{ disabled: true }}
                width="80px"
              />
              <Column
                dataField="U_Price"
                format="#,##0.00"
                caption="U/Price"
                dataType="number"
                editorOptions={{ disabled: true }}
                width="120px"
              />
              <Column dataField="Disc" caption="Disc%" width="90px" />
              <Column dataField="Amount" format="#,##0.00" caption="Amount" width="90px" />
              <Column
                dataField="DescAmt"
                format="#,##0.00"
                caption="Desc Amt"
                editorOptions={{ disabled: true }}
                width="80px"
              />
              <Column
                dataField="InternalTaxCode"
                caption="Tax Code"
                editorOptions={{ disabled: true }}
              />

              <Scrolling columnRenderingMode="standard" showScrollbar={"onHover"} />

              <Pager
                allowedPageSizes={[10, 25, 50, 100]}
                showPageSizeSelector={true}
                visible={true}
                displayMode={"full"}
                showInfo={true}
              />
              <Paging
                defaultPageSize={10}
                onPageSizeChange={(e) => {}}
                onPageIndexChange={(e) => {}}
              />
            </DataGrid>
          </div>
        </div>
      </PopupForm>

      {/* Cancel E-Invoice Reason Popup */}
      <Popup
        visible={isCancelEinovoicePopupVisible}
        onHiding={() => setCancelEinovoicePopupVisible(false)}
        title={"Cancel E-Invoice Document"}
        showTitle={true}
        width={400}
        height={200}
      >
        <div>
          <CustomizedLookup
            displayExpr={"name"}
            valueExpr={"name"}
            value={cancelReason}
            onSelectionChanged={handleReasonChange}
            required={true}
            requiredMessage={"Cancel Reason Is Required"}
            dataSourceURL={"api/Utilities/GetEInvoiceCancelReason"}
            acceptCustomValue={true}
            placeholder={"Enter a custom cancellation rejection here..."}
          >
            <Column dataField="code" caption={"Reason"}></Column>
            <Column dataField="name" caption={"Description"}></Column>
          </CustomizedLookup>

          <br />
          <div style={{ marginTop: "10px" }}>
            <Button
              text="Submit"
              onClick={async () => await cancelEInvoiceDocument()}
              width="100%"
              style={{ backgroundColor: "#46CF64", borderRadius: "4px", marginRight: "5px" }}
              stylingMode="text"
              elementAttr={{ class: "popup-form-submit-btn" }}
            />
          </div>
        </div>
      </Popup>

      {/* View History Popup */}
      <Popup
        visible={isHistoryPopupVisible}
        onHiding={() => setHistoryPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        title="E-Invoice History"
        width={1000}
        height={500}
      >
        <DataGrid dataSource={historyData} keyExpr="ID" showBorders={true}>
          <Column dataField="DocumentNo" caption="Document No" />
          <Column dataField="UUID" caption="UUID" />
          <Column dataField="LongID" />
          <Column dataField="DocumentNo" />
          <Column
            dataField="ReceptionDate"
            caption="Reception Date"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="Status" caption="Status" />
          <Column dataField="CancelDate" caption="Cancel Date" format="dd/MM/yyyy" />
          <Column dataField="CancelReason" caption="Cancel Reason" />
        </DataGrid>
      </Popup>
    </div>
  );
}
