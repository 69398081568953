import React, { useEffect, useState, useRef } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import baseapi from "../../api/baseapi";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { loading, closeLoading } from "../../utils/common";
import DataGrid, {
  LoadPanel,
  Column,
  Editing,
  Selection,
  Scrolling,
  Lookup,
  KeyboardNavigation,
  ColumnChooser,
  Summary,
  TotalItem,
  Sorting,
  Pager,
  Paging,
  Item as ToolbarItem,
} from "devextreme-react/data-grid";
import { DateBox } from "devextreme-react";
import Swal from "sweetalert2";

export default function EInvoiceDownload(props) {
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [invoiceDetailDataSource, setInvoiceDetailDataSource] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    if (props.formID !== null) {
      utils.popupFormOpen(formRef);
    }
  }, [props.formID]);

  async function onInitialized(e) {
    loading();
    let result = await baseapi.httpget("/api/einvoice/GetDocument?uuid=" + props.formID);
    setInvoiceDetail(result?.data?.data);
    setInvoiceDetailDataSource(result?.data?.data?.InvoiceDetail);

    closeLoading();
    if (result?.data?.status == "invalid") {
      Swal.fire({
        type: "info",
        title: "Opps!",
        text: result?.data?.error?.message,
        showConfirmButton: false,
      });
    }
  }

  const clearForm = () => {
    setInvoiceDetail(null);

    props.closePopup(null);
  };

  return (
    <div>
      <PopupForm
        ref={formRef}
        onClosing={clearForm}
        fullScreen={false}
        actionButtonEnabled={false}
        disableButtons={true}
        width={"95%"}
        height={"90%"}
        headerClassName={"e-invoice-module-form-title-bg popup-form-title"}
        title={props.title}
        onShown={onInitialized}
      >
        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "20px" }}>E-Invoice Info</h4>
        </div>
        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">UUID: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.uuid}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Submission UID: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.submissionUid}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Long ID: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.longId}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Digital Signature: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Signature}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Version: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.typeVersionName}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Link: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.url}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Reception Date: </div>

                <div className="popup-group-form-input">
                  <DateBox
                    type="datetime"
                    value={invoiceDetail?.dateTimeReceived}
                    elementAttr={{ dataField: "CustomerName" }}
                    dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                    alignment="left"
                    width={"auto"}
                  ></DateBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Validate Date: </div>

                <div className="popup-group-form-input">
                  <DateBox
                    type="datetime"
                    value={invoiceDetail?.dateTimeValidated}
                    elementAttr={{ dataField: "CustomerName" }}
                    dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                    alignment="left"
                    width={"auto"}
                  ></DateBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Cancel Date: </div>

                <div className="popup-group-form-input">
                  <DateBox
                    type="datetime"
                    value={invoiceDetail?.cancelDateTime}
                    elementAttr={{ dataField: "CustomerName" }}
                    dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                    alignment="left"
                    width={"auto"}
                  ></DateBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Cancel Reason: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Status: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.status}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container">
              <div className="popup-group-form-item">
                <img src={invoiceDetail?.qrcode} style={{ width: "250px", height: "250px" }} />
              </div>
            </div>
          </div>
        </div>

        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "20px" }}>Document Info</h4>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Document Type: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.typeName}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Internal ID: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.internalId}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Forex Code: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ForexCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container2">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Local Rate: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.LocalRate}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Transaction Date: </div>

                <div className="popup-group-form-input">
                  <DateBox
                    type="datetime"
                    value={invoiceDetail?.dateTimeReceived}
                    elementAttr={{ dataField: "CustomerName" }}
                    dateSerializationFormat="yyyy-MM-dd HH:mm:ss"
                    alignment="left"
                    width={"auto"}
                  ></DateBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Forex Rate: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ForexRate}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-input">
                  <CheckBox text="Foreign Buyer" />
                  <CheckBox style={{ marginTop: 10 }} text="Government Buyer" />
                </div>
                <div className="popup-group-form-input">
                  <CheckBox text="Consolidated" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "20px" }}>Buyer Info</h4>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Name: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Buyer_sName}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Address 1: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerAddressLine0}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Address 2: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerAddressLine1}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Address 3: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerAddressLine2}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Post Code: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerPostalZone}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container2">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Register No: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Buyer_sRegistration}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Identity Type: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Identification (TIN): </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Buyer_sTIN}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">SST: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Buyer_sSSTRegistrationNumber}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Handphone: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Buyer_sContactNumber}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Email: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Buyer_se_mail}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">City: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.BuyerCityName}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.CityCode}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">State: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerState}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.StateCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Country: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BuyerCountry}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.CountryCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-group-form-group" id="bottom-form-group">
          <h4 style={{ marginBlock: "10px", marginLeft: "20px" }}>Seller Info</h4>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Name: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Supplier_sName}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Address 1: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ShippingAddressLine0}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Address 2: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ShippingAddressLine1}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Address 3: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ShippingAddressLine2}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Post Code: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ShippingPostalZone}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Business Activity: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.BusinessActivity}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container2">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Register No: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Supplier_sRegistration}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">MSIC: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.MSIC}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Tax Identification (TIN): </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Supplier_sTIN}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S SST: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Supplier_sSSTRegistrationNumber}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Handphone: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Supplier_sContactNumber}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Email: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.Supplier_se_mail}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">City: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.ShippingCityName}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S State: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ShippingState}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierStateCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">S Country: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.ShippingCountry}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.SupplierCountryCode}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content">
          <div className="e-invoice-module-parent-section custom-form-grid-section1">
            <div className="">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Line Ext Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.TotalIncludingTax}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Ext Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.TotalTaxAmount}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Inc Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.TotalExcludingTax}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Discount Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.TotalDiscountValue}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Payable Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.RoundingAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="popup-form-item-container">
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Total Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.RoundingAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Taxable Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    elementAttr={{ dataField: "CustomerName" }}
                    value={invoiceDetail?.TotalIncludingTax}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>

              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Tax Amt: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.TotalTaxAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
              <div className="popup-group-form-item">
                <div className="popup-group-form-label">Rounding: </div>

                <div className="popup-group-form-input">
                  <TextBox
                    value={invoiceDetail?.RoundingAmount}
                    elementAttr={{ dataField: "CustomerName" }}
                    alignment="left"
                    width={"auto"}
                  ></TextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-form-main-content" style={{}}>
          <div
            className="border-bottom e-invoice-module-parent-section custom-form-grid-section"
            style={{}}
          >
            <DataGrid
              id="id"
              showBorders={true}
              width={"100%"}
              dataSource={invoiceDetailDataSource}
              allowColumnReordering={true}
              allowColumnResizing={true}
              rowHeight={50}
              hoverStateEnabled={true}
              rowAlternationEnabled={false}
              columnAutoWidth={true}
              errorRowEnabled={false}
              loadPanel={{ enabled: true }}
              height={400}
              focusedRowEnabled={false}
            >
              <Sorting mode="multiple" />
              <Column
                dataField="No"
                width="120px"
                caption="No"
                editorOptions={{ disabled: true }}
              />
              <Column
                dataField="InvoiceItemID"
                caption={"Stock Code"}
                editorOptions={{ readOnly: true }}
                width="250px"
              />
              <Column
                dataField="DescriptionofProductorService"
                caption={"Description"}
                editorOptions={{ readOnly: true }}
                width="400px"
              />
              <Column
                dataField="Loc"
                caption="Loc"
                editorOptions={{ disabled: true }}
                width="80px"
              />
              <Column
                dataField="Quantity"
                caption="Qty"
                dataType="number"
                editorOptions={{ disabled: true }}
                width="100px"
              />
              <Column
                dataField="Measurement"
                caption="UOM"
                dataType="number"
                editorOptions={{ disabled: true }}
                width="80px"
              />
              <Column
                dataField="UnitPrice"
                caption="U/Price"
                dataType="number"
                editorOptions={{ disabled: true }}
                width="120px"
              />
              <Column dataField="DiscountRate" caption="Disc%" width="90px" />
              <Column dataField="Subtotal" caption="Amount" width="90px" />
              <Column
                dataField="DiscountAmount"
                caption="Desc Amt"
                editorOptions={{ disabled: true }}
                width="80px"
              />
              <Column dataField="TaxType" caption="Tax Code" editorOptions={{ disabled: true }} />

              <Scrolling columnRenderingMode="standard" showScrollbar={"onHover"} />

              <Pager
                allowedPageSizes={[10, 25, 50, 100]}
                showPageSizeSelector={true}
                visible={true}
                displayMode={"full"}
                showInfo={true}
              />
              <Paging
                defaultPageSize={10}
                onPageSizeChange={(e) => {}}
                onPageIndexChange={(e) => {}}
              />
            </DataGrid>
          </div>
        </div>
      </PopupForm>
    </div>
  );
}
