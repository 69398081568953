import React, { useEffect, useState, useRef, useCallback, Children } from "react";
import baseapi from "../../../api/baseapi";
import { TextBox } from "devextreme-react/text-box";
import utils, { closeLoading } from "../../../utils/common";
import DataGrid, { Column, Lookup, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { loading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import TextArea from "devextreme-react/text-area";
import { DocumentSettings } from "../../../utils/documentSettings";
import DocumentNumberLookup from "../../../components/lookup/DocumentNoLookup";

export default function JobOrderForm(props) {
	const formID = useRef(null);
	const regNoLookupRef = useRef(null);
	const modelLookupRef = useRef(null);
	const customerLookupRef = useRef(null);
	const formRef = useRef(null);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const projectLookupRef = useRef(null);
	const [projectMainList, setProjectMainList] = useState([]);

	// Lookup List
	const [customerList, setCustomerList] = useState([]);
	const [registrationList, setRegistrationList] = useState([]);
	const [modelList, setModelList] = useState([]);
	const [lockedForm, setLockedForm] = useState(false);
	const formRead = useRef(false);
	const tempForm = useRef(null);
	const [formValue, setFormValue] = useState({});
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const childrenGridRef = useRef(null);
	const [locationList, setLocationList] = useState([]);
	const [defaultValue, setDefaultValue] = useState({});


	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			}
			else {
				setIsEditing(false);
			}

		}
	}, [props.formID]);


	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;

			// Update state immediately with the new value for the current field
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (dataField === "CustomerID" && formRead.current) {
				copied["Telephone"] = "";
				baseapi
					.httpget(`api/Customer/Get`, { id: e.value })
					.then((response) => {
						const { data } = response;
						const copied = { ...tempForm.current };

						// Update the copied object with new data
						copied["CustomerName"] = data.model.Name;
						copied["CustomerCode"] = data.model.Code;
						copied["Telephone"] = data.model.Telephone;
						copied["SalesmanID"] = data.model?.SalesmanID;
						copied["ForexID"] = data.model?.ForexID;
						// Update the state with the new data from the API response
						tempForm.current = copied;
						setFormValue(tempForm.current);
					})
					.catch((err) => {
						console.error("Error fetching customer data:", err);
					});
			} else if (dataField === "RegisterID" && formRead.current) {
				const regNoFound = registrationList.find((c) => c.id === e.value);
				copied["Telephone"] = "";
				if (regNoFound !== undefined) {

					copied["CustomerName"] = regNoFound?.customerName;
					copied["CustomerCode"] = regNoFound?.customerCode;
					copied["Telephone"] = regNoFound?.telephone;
					copied["CarColor"] = regNoFound?.colour;
					copied["CustomerID"] = regNoFound?.customerID;
					copied["ModelID"] = regNoFound?.modelID;
					copied["ModelCode"] = regNoFound?.modelCode;
					copied["Mileage"] = regNoFound?.mileage;
					copied["SalesmanID"] = regNoFound?.salesmanID;
					copied["ForexID"] = regNoFound?.forexID;
					tempForm.current = copied;
					setFormValue(tempForm.current);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit(e) {
		if (!utils.isNullOrEmpty(e.validData)) {
			const validData = e.validData;

			// Set document number prefix
			DocumentSettings.setDocumentPrefix(formValue["DocumentPrefix"]);

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"],
			};

			if (props.onSaved !== undefined) {
				props.onSaved(submittedForm);
			}
			loading(`Saving Job Order...`);
			baseapi.httppost(utils.extendUrlVar("/api/WKJobOrder/save", { id: formID.current || "", del: false }), submittedForm).then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
			});

			if (props.clearTransfer) {
				props.clearTransfer(false);
			}
		}
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, []);
		props.clearTransfer(false);
		setLockedForm(false);
	};

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Job Order...");
		else loading("Loading Existing Job Order...");

		baseapi.httpget("/api/WKJobOrder/Get", { id: formID.current || "new", documentNumberPrefix: DocumentSettings.getDocumentPrefix() || "" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);

			//Set Default Values
			if (!utils.isNullOrEmpty(data.defaultValues)) {
				setDefaultValue(data.defaultValues);
			}

			if (data.isTransfer) {
				setLockedForm(true);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: "This Job Order is being transferred from quotation, Cannot be edited",
					type: "Warning"
				});
			}
			if (formID.current === "new") {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
				utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
			} else {
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const JobOrderRowDefaultValue = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			Quantity: 0,
			UnitPrice: 0,
			ItemDiscountAmt: 0,
			Amount: 0,
			DateRef: new Date(),
			NettAmount: 0,
			LocationID: utils.popupFormGetDefaultValues(formRef)["LocationID"],
			LocationCode: utils.popupFormGetDefaultValues(formRef)["LocationCode"],
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
		};
	};

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = moduleCalculation(currentRow, parentValue, defaultValue["QuantityFormat"], defaultValue["UnitCostFormat"], defaultValue["UnitPriceFormat"]);
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		const JobSheetChildren = utils.childrenGridGetSource(childrenGridRef);
		const recordIndex = JobSheetChildren.findIndex(record => record.ID === key)
		JobSheetChildren[recordIndex][columnName] = e.value;

		if (columnName === "StockID") {
			JobSheetChildren[recordIndex]["StockCode"] = selectedItem?.code;
			JobSheetChildren[recordIndex]["Description"] = selectedItem?.name;
			JobSheetChildren[recordIndex]["Description2"] = selectedItem?.description2;
			JobSheetChildren[recordIndex]["MoreDescription"] = selectedItem?.moreDescription;
			JobSheetChildren[recordIndex]["UnitPrice"] = selectedItem?.price1;
			JobSheetChildren[recordIndex]["UOMID"] = selectedItem?.uomID;
			JobSheetChildren[recordIndex]["UOMCode"] = selectedItem?.uomCode;
			JobSheetChildren[recordIndex]["ChartAccountID"] = selectedItem?.cashSalesChartAccountID;
			JobSheetChildren[recordIndex]["ChartAccountCode"] = selectedItem?.cashSalesChartAccountCode;
			JobSheetChildren[recordIndex]["MechanicID"] = formValue["MechanicID"];
		} else if (columnName === "LocationID") {
			JobSheetChildren[recordIndex]["LocationCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = JobSheetChildren[recordIndex];
		onRowUpdated(e, JobSheetChildren);
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				ReportDocumentNo={formValue["DocumentNo"]}
				onClosing={clearForm}
				lockFormOnChanged={setLockedForm}
				disableButtons={lockedForm === true}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"workshop-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"Job Order"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
					Children: utils.childrenGridGetSource(childrenGridRef),
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
					onRowUpdated({ key: null }, e.Children);
				}}
				requiredColumns={{
					Children: ["StockID", "LocationID"]
				}}
				filterRules={{
					Children: [
						{ name: "StockID", rules: [{ name: "Required" }] },
						{ name: "Description", rules: [{ name: "Required" }] },
						{ name: "Quantity", rules: [{ name: "Min", value: 1 }] },
					]
				}}
				copyStorage={"WorkshopJobOrderCopiedData"}
				formData={formValue}
				setFormValue={(newValues) => {
					tempForm.current = { ...tempForm.current, ...newValues };
					setFormValue((prevState) => ({ ...prevState, ...newValues }));
				}}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[regNoLookupRef, modelLookupRef, customerLookupRef, projectLookupRef]}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							disabled={lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div>
					<div className='popup-group-form-container workshop-module-border-bottom'>
						<div className='popup-form-item-container3'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Registration No </div>
								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={regNoLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={registrationList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["RegisterID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "RegisterID");
										}}
										disabled={lockedForm === true}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Reg No is required!"}
										dataSourceURL={"api/Utilities/GetWKRegistration"}
										onDataSourceChanged={setRegistrationList}
										displayText={formValue["RegisterCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='modelCode' caption='Model'></Column>
										<Column dataField='customerName' caption='Customer'></Column>
										<Column dataField='mileage'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>Job Order No</div>
								<div className='popup-group-form-input'>
									<div className='next-doc-display'>Next Doc {formValue["NextDocNo"]}</div>
									<DocumentNumberLookup
										formValue={formValue}
										setFormValue={setFormValue}
										tempForm={tempForm}
										formID={formID}
										lockedForm={lockedForm}
										valueOnChange={valueOnChange}
									/>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Date </div>
								<div className='popup-group-form-input'>
									<DateBox
										value={formValue["DocumentDate"]}
										elementAttr={{ dataField: "DocumentDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										disabled={lockedForm === true}></DateBox>
								</div>
							</div>
						</div>
						<div className='popup-form-item-container6'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Code </div>
								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={customerLookupRef}
										className={"workshop-listing-datagrid"}
										dataSource={customerList}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["CustomerID"]}
										onSelectionChanged={(e) => valueOnChange(e, "CustomerID")}
										disabled={lockedForm === true}
										dataSourceURL={"api/Utilities/GetCustomers"}
										onDataSourceChanged={setCustomerList}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Customer is required!"}
										displayText={formValue["CustomerCode"]}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Customer Name"}></Column>
										<Column dataField='forexCode' caption='Currency'></Column>
									</CustomizedLookup>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Customer Name </div>
								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["CustomerName"]}
										elementAttr={{ dataField: "CustomerName" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={lockedForm === true}></TextBox>
								</div>
							</div>
						</div>

						<br />

						<div className='custom-hr2'></div>
						<div className='popup-form-item-container2'>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Car Model </div>
									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={modelLookupRef}
											className={"workshop-listing-datagrid"}
											dataSource={modelList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ModelID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ModelID");
											}}
											disabled={lockedForm === true}
											dataSourceURL={"api/Utilities/GetItemModels"}
											onDataSourceChanged={setModelList}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Model is required!"}
											displayText={formValue["ModelCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Description"}></Column>
										</CustomizedLookup>

									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Telephone </div>
									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Telephone"]}
											elementAttr={{ dataField: "Telephone" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={lockedForm === true}></TextBox>
									</div>
								</div>

							</div>

							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Project </div>
									<div className='popup-group-form-input' id='customized-lookup1'>
										<CustomizedLookup
											ref={projectLookupRef}
											className={"ar-listing-datagrid"}
											dataSource={projectMainList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ProjectID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ProjectID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Project is required!"}
											dataSourceURL={"api/Utilities/GetProjects"}
											onDataSourceChanged={setProjectMainList}
											disabled={lockedForm === true}
											displayText={formValue["ProjectCode"]}>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Project Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Ref No </div>
									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["RefNo"]}
											elementAttr={{ dataField: "RefNo" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											disabled={lockedForm === true}></TextBox>
									</div>
								</div>

							</div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Mileage In </div>
									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["Mileage"]}
											elementAttr={{ dataField: "Mileage" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											format="#,##0"
											disabled={lockedForm === true}></NumberBox>
									</div>
								</div>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Date Due Out </div>
									<div className='popup-group-form-input'>
										<DateBox
											value={formValue["DateDueOut"]}
											elementAttr={{ dataField: "DateDueOut" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											displayFormat='dd/MM/yyyy'
											dateSerializationFormat='yyyy-MM-dd'
											useMaskBehavior={true}
											disabled={lockedForm === true}></DateBox>
									</div>
								</div>
							</div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Time Due Out </div>
									<div className='popup-group-form-input' id='customized-lookup1'>
										<TextBox
											value={formValue["TimeDueOut"]}
											elementAttr={{ dataField: "TimeDueOut" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											mask={"Hh:Mm"}
											maskRules={utils.TimeFormatMask}
											disabled={lockedForm === true}></TextBox>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Issue By </div>
									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["IssueBy"]}
											elementAttr={{ dataField: "IssueBy" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}></TextBox>
									</div>
								</div>

							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label' style={{ paddingBottom: "20px" }}>
								Remarks
							</div>

							<div className='popup-group-form-input'>
								<TextArea
									value={formValue["Remarks"]}
									elementAttr={{ dataField: "Remarks" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									height={50}
									disabled={lockedForm === true}></TextArea>
							</div>
						</div>

					</div>

					<div className='popup-group-form-container workshop-module-border-bottom popup-form-datagrid-container'>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={JobOrderRowDefaultValue}
							allowColumnReordering={true}
							allowColumnResizing={true}
							keyExpr='ID'
							showBorders={false}
							lookupFields={["StockID"]}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							gridLookupOpen={setGridLookupOpen}
							storageName={"WorkshopJobOrderChildrenGrid"}
							disabled={lockedForm === true}
							enabledDescription={false}
							height={"auto"}>

							<Column dataField='Line' dataType='number' editorOptions={{ disabled: true }} caption={"No"} width={"50px"} />

							<Column
								visible={true}
								dataField='StockID'
								dataType='string'
								width={"200px"}
								cellRender={(e) => {
									const data = e.data;
									return data.StockCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										dataSourceURL={"/api/Utilities/GetStock"}
										opened={gridLookupOpen}
										className={"workshop-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}

									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column dataField='Description' caption='Description / Complaint' width={"400px"} />

							<Column visible={true} dataField='Quantity' dataType='number' format={utils.getDecimalFormat(defaultValue["QuantityFixCheck"], defaultValue["QuantityFormat"])} width={"200px"} />

							<Column
								visible={true}
								dataField='UOMID'
								calculateCellValue={(data) => data?.UOMCode}
								editorOptions={{ readOnly: true }}
							/>

							<Column
								visible={true}
								dataField='Remarks'
							/>

							<Column visible={false} dataField='UnitPrice' dataType='number' format={utils.getDecimalFormat(defaultValue["UnitPriceFixCheck"], defaultValue["UnitPriceFormat"])} />

							<Column visible={false} dataField='ItemDiscount' dataType='number' />

							<Column visible={false} dataField='ForexAmount' dataType={"number"} allowEditing={false} />

							<Column visible={false} dataField='TaxRate' width={"auto"} dataType='number' />

							<Column visible={false} dataField='TaxInclusive' dataType='boolean' />

							<Column visible={false} dataField='ForexTaxable' dataType='number' />

							<Column visible={false} dataField='ForexTaxAmount' dataType='number' />

							<Column visible={false} dataField='NettAmount' dataType='number' caption={"Sub Total"} />

							<Column visible={false} dataField='ImportDocumentNo' caption='From Document No' editorOptions={{ disabled: true }} width={150} />

							<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />
							
							<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />
							
							<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

							<Column visible={false} dataField='DateRef' format='dd/MM/yyyy' dataType={"date"} />

							<Column
								visible={false}
								dataField='LocationID'
								caption="Location"
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.LocationCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"inventory-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
										dataSourceURL={"api/Utilities/GetLocations"}
										onDataSourceChanged={setLocationList}

										sourceList={locationList}>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Summary>
								<TotalItem column='Quantity' summaryType='sum' customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>
				</div>
			</PopupForm>
		</div>
	);
}
