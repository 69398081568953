import { Popup } from "devextreme-react";
import FixedAssetsForm from "./form-layout/FixedAssetsForm";
import CostGoodSoldForm from "./form-layout/CostGoodSoldForm";
import OtherAccountForm from "./form-layout/OtherAccounForm";
import ScrollView from "devextreme-react/scroll-view";
import { useState, useRef, useEffect } from "react";
import utils, { yesno, loading, closeLoading } from "../../../utils/common";
import Button from "devextreme-react/button";
import baseapi from "../../../api/baseapi";
import { taxCodeOptions, forexOptions } from "../../../utils/lookupstore";
import CustomizedValidationGroup from "../../../components/customized-validation-group/CustomizedValidationGroup";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";

export default function ChartAccountForm(props) {
	const [formPopup, setFormPopup] = useState(false);
	const formID = useRef(null);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState(null);
	const [formData, setFormData] = useState({});
	const [dropDownRead, setDropDownRead] = useState(false);
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [forexList, setForexList] = useState([]);
	const [node, setNode] = useState(null);
	const [startValidation, setStartValidation] = useState(0);
	const [defaultValue, setDefaultValue] = useState({});
	const FixedAssetsRef = useRef(null);
	const CostGoodsSoldRef = useRef(null);
	const OtherAccountRef = useRef(null);
	const validatorRef = useRef(null);
	const [parentName, setParentName] = useState("");
	const [isRoot, setIsRoot] = useState(false);
	const popupMessageRef = useRef(null);

	const SpecialAccountTypeList = [
		{ code: "BK", name: "Bank Account", available: ["IV", "OA", "CA", "CL", "OL", "OT"] },
		{ code: "CS", name: "Cash Account", available: ["IV", "OA", "CA", "CL", "OL", "OT"] },
		//{ code: "DP", name: "Deposit Account", available: ["IV", "OA", "CA", "CL", "OL", "OT"] },
		{ code: "CC", name: "Customer Control Account", available: ["CA"] },
		{ code: "BI", name: "Balance Inventory Account", available: ["CA"] },
		{ code: "OP", name: "Other Payment Account", available: ["CA"] },
		{ code: "SC", name: "Supplier Control Account", available: ["OL", "CL"] },
		{ code: "OI", name: "Opening Inventory", available: ["CO"] },
		{ code: "CI", name: "Closing Inventory", available: ["CO"] }
	];

	const formTitle = [
		{ accountType: "FA", name: "Fixed Assets" },
		{ accountType: "CO", name: "Cost of Goods Sold" },
		{ accountType: "IV", name: "Invesment" },
		{ accountType: "OA", name: "Other Assets" },
		{ accountType: "CA", name: "Current Assets" },
		{ accountType: "CL", name: "Current Liabilities" },
		{ accountType: "OL", name: "Other Liabilities" },
		{ accountType: "CE", name: "Capital / Equity" },
		{ accountType: "RS", name: "Reserve" },
		{ accountType: "RE", name: "Retained Earning" },
		{ accountType: "OT", name: "Long Term Liabilities" },
		{ accountType: "SL", name: "Sales" },
		{ accountType: "SA", name: "Sales Adjustment" },
		{ accountType: "OI", name: "Other Income" },
		{ accountType: "EO", name: "Extra Ordinary Income" },
		{ accountType: "EP", name: "Expenses" },
		{ accountType: "TX", name: "Taxation" },
		{ accountType: "AP", name: "Appropriation Account" }
	];

	useEffect(() => {
		if (dropDownRead !== true) {
			taxCodeOptions.store.load().then((list) => {
				setTaxCodeList(list.data);
			});

			forexOptions.store.load().then((list) => {
				setForexList(list.data);
			});

			setDropDownRead(true);
		}
	}, [dropDownRead]);

	useEffect(() => {
		if (props.formData !== null) {
			const data = props.formData;
			setFormData(data);
			if (props.node.level === 0) {
				setIsRoot(true);
				setParentName("");
			} else {
				setIsRoot(false);
				setParentName(findRootName(props.node.parent));
				// setParentName(props.node.parent.data.Name);
			}
		}
	}, [props.formData]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			setFormPopup(true);

			if (formID.current === "new") {
				setIsEditing(false);
			} else {
				setIsEditing(true);
			}
			if (props.node.level === 0) {
				setIsRoot(true);
				setParentName("");
			} else {
				setIsRoot(false);
				setParentName(findRootName(props.node.parent));
				// setParentName(props.node.parent.data.Name);
			}
		}
	}, [props.formID]);

	useEffect(() => {
		if (props.node !== null) {
			setNode(props.node);
			if (props.node.level === 0) {
				setIsRoot(true);
				setParentName("");
			} else {
				setIsRoot(false);
				setParentName(findRootName(props.node.parent));
				// setParentName(props.node.parent.data.Name);
			}
		}
	}, [props.node]);

	function findRootID(node) {
		if (node.level !== 0) {
			return findRootID(node.parent);
		} else {
			return node.key;
		}
	}

	function findRootName(node) {
		if (node.level !== 0) {
			return findRootName(node.parent);
		} else {
			return node.data.Name;
		}
	}

	const closePopup = () => {
		setFormValue(null);
		setFormPopup(false);
		setStartValidation(0);
		formID.current = null;
		props.closePopup(formID.current);
		setParentName("");
	};

	const getParentData = (arr = [], parentData) => {
		if (parentData instanceof Object) {
			if (parentData.hasOwnProperty("parent") && parentData.level !== -1) {
				arr.push(parentData.data);
				return getParentData(arr, parentData.parent);
			} else {
				return arr;
			}
		} else {
			return arr;
		}
	};

	const onInitialized = () => {
		loading("Loading Account Info...");
		baseapi.httpget("/api/GLChartAccount/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;

			//Set Default Values
			if(!utils.isNullOrEmpty(data.defaultValues)){
				setDefaultValue(data.defaultValues);
			}

			if (formData["AccountType"] === "FA") {
				FixedAssetsRef.current.setValue({ Parent: data.model, Children: {} });
			} else if (formData["AccountType"] === "CO") {
				CostGoodsSoldRef.current.setValue({ Parent: data.model });
			} else {
				OtherAccountRef.current.setValue({ Parent: data.model });
			}
			closeLoading();
			// console.log("data", data)
		});
	};

	const handleSubmit = () => {
		loading("Saving Account...");
		const nodeData = utils.isNullOrEmpty(node) ? null : node.data;
		const parentData = getParentData([], node);
		
		//Add Parent ID and Account Type
		if (formValue["Children"] !== undefined) {
			if(node.level !== 0){
				formValue["Children"]["ParentID"] = FixedAssetsRef.current.getDepAccParentID();
			}
			formValue["Children"]["GroupLevel"] = utils.isNullOrEmpty(node) ? 0 : node.level + 2;
			formValue["Children"]["ParentID"] = !utils.isNullOrEmpty(formValue["Children"]["ParentID"]) ? formValue["Children"]["ParentID"] : formData["id"];
			formValue["Children"]["AccountType"] = formData["AccountType"];
			formValue["Children"]["ForexID"] = defaultValue["ForexID"];
			formValue["Children"]["SpecialAccountType"] = "AD";
			formValue["Children"]["ChartType"] = utils.isNullOrEmpty(nodeData) ? null : nodeData.ChartType;
		}

		//Remove child if is editing
		if (isEditing) {
			delete formValue["Children"];
		} else {
			formValue["Parent"]["ParentID"] = formData["id"];
			formValue["Parent"]["GroupLevel"] = utils.isNullOrEmpty(node) ? 0 : node.level + 2;
			formValue["Parent"]["GroupID"] = utils.isNullOrEmpty(node) ? null : node.data.GroupID;
			formValue["Parent"]["AccountType"] = formData["AccountType"];
			formValue["Parent"]["ChartType"] = utils.isNullOrEmpty(nodeData) ? null : nodeData.ChartType;
			formValue["Parent"]["RootParentID"] = findRootID(node);
			formValue["Parent"]["GroupIDLevel2"] = formValue["Parent"]["GroupLevel"] === 2 ? formValue["Parent"]["Code"] : utils.isNullOrEmpty(parentData.find((c) => c.GroupLevel === 2)) ? null : parentData.find((c) => c.GroupLevel === 2).Code;
			formValue["Parent"]["GroupIDLevel3"] = formValue["Parent"]["GroupLevel"] === 3 ? formValue["Parent"]["Code"] : utils.isNullOrEmpty(parentData.find((c) => c.GroupLevel === 3)) ? null : parentData.find((c) => c.GroupLevel === 3).Code;
			formValue["Parent"]["GroupIDLevel4"] = formValue["Parent"]["GroupLevel"] === 4 ? formValue["Parent"]["Code"] : utils.isNullOrEmpty(parentData.find((c) => c.GroupLevel === 4)) ? null : parentData.find((c) => c.GroupLevel === 4).Code;
			formValue["Parent"]["GroupIDLevel5"] = formValue["Parent"]["GroupLevel"] === 5 ? formValue["Parent"]["Code"] : null;
		}

		if (formValue["Parent"]["ForexID"] === undefined) {
			formValue["Parent"]["ForexID"] = defaultValue["ForexID"];
		}

		baseapi.httppost(utils.extendUrlVar("/api/GLChartAccount/save", { id: formID.current || "", del: false }), formValue).then((response) => {
			const { data } = response;
			if (props.onSubmit !== undefined) {
				props.onSubmit(formValue);
			}

			if (data.status) {
				closePopup();
			}

			closeLoading();
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: data.status ? `Data Successfully Saved!` : data.message,
				type: data.status ? "Success" : "Danger",
				action: data.action
			});
		});
	};

	const deleteAccount = async () => {
		var deleteMessage = "Are you sure you want to delete this account?";
		const nodeData = node.data;

		//Delete account message
		if(formData["AccountType"] === "FA"){
			if(formData["SpecialAccountType"] === "AD"){
				deleteMessage = `Account ${nodeData["Code"]} (${nodeData["Name"]})
				is a fixed asset account accumulated depreciation special account, 
				if you delete it, the fixed asset link will be broken. 
				Do you still want to delete?`
			}
			else{
				deleteMessage = `Account ${nodeData["Code"]} (${nodeData["Name"]})
				is a fixed asset account, if you delete it, the fixed asset accumulated
				depreciation link will be broken. Do you still want to delete?`
			}
		}

		const result = await yesno(deleteMessage, "Delete Confirmation", ["Yes", "No"]);
		// console.log(result);

		if (result.isConfirmed) {
			loading("Deleting Account...");
			baseapi.httppost(utils.extendUrlVar("/api/GLChartAccount/save", { id: formID.current || "", del: true }), formValue).then((response) => {
				if (props.onDeleted !== undefined) {
					props.onDeleted(formID.current);
				}

				const { data } = response;
				if (data.status) {
					closePopup();
				} 

				closeLoading();
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: data.status ? `Data deleted successfully` : data.message,
					type: data.status ? "Success" : "Danger",
				});
			});
		}
	};

	const validationReady = (ready) => {
		if (ready) {
			validatorRef.current.validateForm().then((result) => {
				if (result === true) {
					handleSubmit();
				}
			});
		}
	};

	function returnTitle() {
		if (isRoot) {
			return props.node.data.Name;
		} else {
			return parentName + " - (" + props.node.data.Name + ")";
		}
	}

	return (
		<div>
			<Popup
				visible={formPopup}
				onHiding={(e) => {
					setFormPopup(false);
				}}
				dragEnabled={false}
				hideOnOutsideClick={false}
				showCloseButton={false}
				showTitle={false}
				container='.dx-viewport'
				shading={false}
				onShown={(e) => {
					// console.log("ON SHOWN", e);
					onInitialized();
				}}
				width={"50%"}
				height={"auto"}>
				<div className='chart-account-popup-header'>
					<div>
						<span className={"gl-module-header-title"}>{formTitle.find((c) => c.accountType === formData["AccountType"]) === undefined ? "Other Account" : returnTitle()}</span>
					</div>

					<div className='gl-popup-close-btn-container'>
						<Button icon='close' stylingMode='text' onClick={closePopup} />
					</div>
				</div>

				<ScrollView width='100%' height={"auto"}>
					<CustomizedValidationGroup ref={validatorRef}>
						<div>
							{formData["AccountType"] === "FA" && (
								<FixedAssetsForm
									ref={FixedAssetsRef}
									onValueChanged={(e) => {
										setFormValue(e);
									}}
									clearForm={!formPopup}
									node={node}
									isEditing={isEditing}
									startValidation={startValidation}
									validationReady={validationReady}
								/>
							)}

							{formData["AccountType"] === "CO" && (
								<CostGoodSoldForm
									ref={CostGoodsSoldRef}
									onValueChanged={(e) => {
										setFormValue(e);
									}}
									clearForm={!formPopup}
									dropDownList={{ taxCodeList: taxCodeList, forexList: forexList, SpecialAccountTypeList: SpecialAccountTypeList.filter((c) => c.available.includes("CO")) }}
									node={node}
									isEditing={isEditing}
									taxCodeListChanged={setTaxCodeList}
									startValidation={startValidation}
									validationReady={validationReady}
								/>
							)}

							{formData["AccountType"] !== "FA" && formData["AccountType"] !== "CO" && (
								<OtherAccountForm
									ref={OtherAccountRef}
									onValueChanged={(e) => {
										setFormValue(e);
									}}
									accountType={formData["AccountType"]}
									clearForm={!formPopup}
									dropDownList={{ taxCodeList: taxCodeList, forexList: forexList, SpecialAccountTypeList: SpecialAccountTypeList.filter((c) => c.available.includes("CO") === false && c.available.includes(formData["AccountType"])) }}
									node={node}
									isEditing={isEditing}
									taxCodeListChanged={setTaxCodeList}
									startValidation={startValidation}
									validationReady={validationReady}
								/>
							)}
						</div>
					</CustomizedValidationGroup>
				</ScrollView>

				<div className='gl-module-popup-footer'>
					<Button
						text='Save'
						className='gl-popup-save-btn'
						onClick={() => {
							setStartValidation(crypto.randomUUID());
						}}
					/>

					<Button text='Delete' className='gl-popup-delete-btn' visible={isEditing && node["hasChildren"] === false} onClick={() => deleteAccount()} />
				</div>
			</Popup>

			<ErrorPopUpForm
				ref={popupMessageRef}
			/>
		</div>
	);
}
