import React, { useEffect, useState, useRef } from "react";
import { Column, Lookup, Summary, TotalItem } from "devextreme-react/data-grid";
import Container from "../../components/container";
import { forexOptions, areaOptions } from "../../utils/lookupstore";
import utils from "../../utils/common";
import CustomerForm from "./form";
import Listing from "../../components/listing/newListing";
import baseapi from "../../api/baseapi";

export default function CustomerListing() {
	const [currentEditID, setCurrentEditID] = useState(null);
	const [forexList, setForexList] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [dropDownRead, setDropDownRead] = useState(false);
	const [totalOffsetAmount, setTotalOffsetAmount] = useState("Loading...");
	const apiURL = `/api/Customer`;

	useEffect(() => {

		if (dropDownRead !== true) {
			forexOptions.store.load().then((list) => {
				setForexList(list.data);
			});

			areaOptions.store.load().then((list) => {
				setAreaList(list.data);
			});

			//Get total offset amount
			baseapi.httpget("/api/Customer/GetCustomerTotalOutstanding").then((response) => {
				const { data } = response;
				setTotalOffsetAmount(utils.numberToCurrency(data));
			});

			setDropDownRead(true);
		}
	}, [dropDownRead]);

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='listing-datagrid'
					apiURL={apiURL}
					listingURL='/api/Customer/list'
					sortColumn={"Code"}
					listingTitle={"Customer"}
					storageName={"CustomerListingColumnWidth"}
					onAddClick={setCurrentEditID}
					dateFilter={false}
					handleEdit={handleEdit}
                    viewReportButton={true}
					ReportID={"78EM7gVavwQ%3d"}>
					<Column visible={true} dataField='Code' />

					<Column visible={true} dataField='Name' caption={"Company Name"} />

					<Column dataField="ForexCode" caption="Forex"/>

					<Column dataField="AreaCode"/>
				
					<Column visible={true} dataField='Person' caption={"Contact"} />

					<Column visible={true} dataField='Telephone' caption={"Tel"} />

					<Column visible={true} dataField='OutstandingAmount' caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />

					<Summary>
						<TotalItem
							column='OutstandingAmount'
							summaryType='sum'
							customizeText={(data) => {
								return `Total : ${totalOffsetAmount}`;
							}}
						/>
					</Summary>
				</Listing>
			</div>

			<CustomerForm 
				formID={currentEditID} 
				closePopup={setCurrentEditID}
				title={"Customer"}
			/>
		</Container>
	);
}
