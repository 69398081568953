import React, { useRef, useEffect, useState } from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';
import LocationsForm from './LocationsForm';
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";
import Listing from '../../components/listing/newListing';
import baseapi from '../../api/baseapi';


export default function Locations() {
    const apiURL = "/api/Locations";
    const [currentEditID, setCurrentEditID] = useState(null);

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }
    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/Locations/list"
                sortColumn={"Code"}
                className={"listing-datagrid"}
                listingTitle={"Locations"}
                storageName={"LocationsListingColumnWidth"}
                onAddClick={setCurrentEditID}
                dateFilter={false}
                handleEdit={handleEdit}
            >
                <Column
                    dataField="Code"
                    cellTemplate={(container, options) => {
                        // Render the code text
                        container.textContent = options.data.Code;
                        // If the Default field is true, append the word "default"
                        if (options.data.IsDefault) {
                            const defaultSpan = document.createElement("span");
                            defaultSpan.textContent = "Default";
                            defaultSpan.style.marginLeft = "60px";
                            defaultSpan.style.fontStyle = "italic";
                            defaultSpan.style.padding = "5px 6px"; // Add padding
                            defaultSpan.style.borderRadius = "10%"; // Set border radius to 50%
                            defaultSpan.style.backgroundColor = "#74B973"; // Set background color to green
                            defaultSpan.style.color = "white"; // Optional: change text color to white for better contrast
                            container.appendChild(defaultSpan);
                        }
                    }}
                    width={250} />
                <Column visible={true} dataField="Name" caption="Description" width={500} />
                <Column visible={true} dataField="ModifiedDate" width={200} dataType="date" // Ensure this is set
                    format="dd/MM/yyyy" />

                <Column visible={true} dataField="Active" caption="Active" width={100} />

            </Listing>
        </div>

        <LocationsForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>;
}