import React, { useEffect, useState } from 'react';
import { Column, Lookup } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import ARDebitNoteForm from './form';
import SalesDebitNoteForm from '../../Sales-Module/debit-note/form';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";

export default function ARDebitNoteListing() {

    const apiURL = `/api/ARDebitNote`;
    const nonIntegerRegex = /[a-zA-Z-]+/g;

    const [currentDetailID, setCurrentDetailID] = useState(null);

    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);

    function handleEInvoice(e) {
        setCurrentDetailID(e.row.data.DocumentNo);
    }

    function handleEdit(e) {
        const rowData = e.row.data;
        let valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);

        if (rowData.hasOwnProperty("OriginalDocumentID") && rowData.OriginalDocumentID) {
            valueToCheck = rowData.OriginalTransactionTypeCode.trim().match(nonIntegerRegex);
        } else {
            valueToCheck = rowData.TransactionTypeCode.trim().match(nonIntegerRegex);
        }

        valueToCheck = valueToCheck ? valueToCheck.join("") : "";

        utils.transactionCodeList.find((item) => {
            if (item.TransType === valueToCheck) {
                if (item.DocumentType === "Sales Debit Note") {
                    setCurrentEditID2(rowData.OriginalDocumentID);
                } else {
                    setCurrentEditID(rowData.id);
                }
            }
        })
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentDetailID(id);
    }

    return (<Container>
        <div id='normal-listing'>
            <Listing
                className="listing-datagrid"
                apiURL={apiURL}
                einvoiceshow={true}
                listingURL="/api/ARDebitNote/list"
                sortColumn={"DocumentDate"}
                sortOrder={"desc"}
                listingTitle={"AR Debit Note"}
                storageName={"ARDebitNoteListingColumnWidth"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
                handleEInvoice={handleEInvoice}
            >
                <Column dataField="DocumentNo" />
                <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
                <Column dataField="CustomerCode" />
                <Column dataField="CustomerName" />
                <Column dataField="ForexCode" caption="Forex" />
                <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2 }} />
            </Listing>
        </div>


        {currentEditID !== null && (
            <ARDebitNoteForm
                formID={currentEditID}
                closePopup={closePopup}
                title={"A/R Debit Note"}
                apiURL={apiURL}
            />
        )}

        {currentEditID2 !== null && (
            <SalesDebitNoteForm
                formID={currentEditID2}
                closePopup={closePopup}
                title={"Debit Note"}
                apiURL={`/api/SalesDebitNote`}
            />
        )}

            {currentDetailID !== null && (
                <EInvoiceMain
                    formID={currentDetailID}
                    closePopup={closePopup}
                    apiURL={apiURL}
                    invoiceType="03"
                    title={"E-Invoice Main"}
                    EinvoiceCancelShow={true}
                />
            )}
        </Container>
    );
}
