import React, { useEffect, useState, useRef } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import CashPurchaseForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import Transfer from '../transfer/Transfer';
import DropDownButton from 'devextreme-react/drop-down-button';
import EInvoiceMain from "../../../components/e-invoice/EInvoiceMain";
import EInvoiceDownload from '../../../components/e-invoice/EInvoiceDownload';

export default function CashPurchaseListing() {
    const [currentDetailID, setCurrentDetailID] = useState(null);
    const [currentDownloadID, setCurrentDownloadID] = useState(null);
    const apiURL = `/api/PurchaseCashPurchase`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [transformTitle, setTransformTitle] = useState(null);
    const [transferData, setTransferData] = useState(null);
    const transformRef = useRef(null);

    function handleEInvoice(e) {
        setCurrentDetailID(e.row.data.DocumentNo);
    }

    function handleDownload(e) {
        setCurrentDownloadID(e.row.data.UUID);
    }

    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
        setTransferData(null);

    }

    const transferFormData = (data) => {
        setCurrentEditID("new");
        setTransferData(data);
    };

    const onItemClick = (e) => {
        const { action } = e.itemData;
        setTransformTitle(action);
        utils.transformOpen(transformRef);
    };

    const closePopup = (value) => {
        setCurrentEditID(value);
        setCurrentDetailID(value);
        setTransferData(null);
        setCurrentDownloadID(value);
    };

    return <Container>
        <div id='normal-listing'>
        <Listing
            className="listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/PurchaseCashPurchase/list"
            sortColumn={"DocumentDate"}
            sortOrder={"desc"}
            listingTitle={"Cash Purcahse"}
            storageName={"CashPurchaseListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
            einvoiceshow={true}
            handleEInvoice={handleEInvoice}
            downloadShow={true}
            handleDownload={
                handleDownload
            }
            customizedAddButton={
                <DropDownButton
                    className="customized-listing-add-btn listing-btn"
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text="Add"
                    items={[
                        { id: 1, action: 'Transfer from Request' },
                        { id: 2, action: 'Transfer from Quotation' },
                        { id: 3, action: 'Transfer from Order' },
                        { id: 4, action: 'Transfer from Received' }
                    ]}
                    displayExpr="action"
                    keyExpr="id"
                    width={"100px"}
                    dropDownOptions={
                        {width: "180px"}
                    }
                    onItemClick={onItemClick}
                    onButtonClick={() => {
                        setCurrentEditID("new");
                    }}
              />
            }
        >
            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexCode" caption="Forex"/>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <CashPurchaseForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            transferData={transferData}
            clearTransfer={setTransferData}
            title={"Cash Purchase"}
            apiURL={apiURL}
        />


        <EInvoiceMain
            formID={currentDetailID}
            closePopup={closePopup}
            apiURL={apiURL}
            title={"E-Invoice Main"}
            invoiceType="11"
            transferData={transferData}
            clearTransfer={setTransferData}
        />

        <EInvoiceDownload
            formID={currentDownloadID}
            closePopup={closePopup}
            apiURL={apiURL}
            title={"E-Invoice Main"}
            invoiceType="11"
            transferData={transferData}
            clearTransfer={setTransferData}
        />

        <Transfer
            ref={transformRef}
            title={transformTitle}
            apiPath={{
                "Transfer from Request": {
                    FullDocumentListing: "api/PurchaseTransfer/RequestFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/RequestPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/RequestFullTransfer",
                    PartialDocumentTransfer: "api/PurchaseTransfer/RequestPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/RequestFilter"
                },
                "Transfer from Quotation": {
                    FullDocumentListing: "api/PurchaseTransfer/QuotationFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/QuotationPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/QuotationFullTransfer",
                    PartialDocumentTransfer: "api/PurchaseTransfer/QuotationPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/QuotationFilter"
                },
                "Transfer from Order": {
                    FullDocumentListing: "api/PurchaseTransfer/OrderFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/OrderPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/OrderFullTransfer",
                    PartialDocumentTransfer: "api/PurchaseTransfer/OrderPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/OrderFilter"
                },
                "Transfer from Received": {
                    FullDocumentListing: "api/PurchaseTransfer/ReceivedFullDocument",
                    PartialDocumentListing: "api/PurchaseTransfer/ReceivedPartial",
                    FullDocumentTransfer: "api/PurchaseTransfer/ReceivedFullTransfer",
                    PartialDocumentTransfer: "api/PurchaseTransfer/ReceivedPartialTransfer",
                    FilterPath: "api/PurchaseTransfer/ReceivedFilter"
                }
            }}
            transferData={transferFormData}
        />
    </Container>;
}