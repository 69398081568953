import React, { useEffect, useState } from "react";
import { Column, Lookup, Summary, TotalItem } from "devextreme-react/data-grid";
import Container from "../../components/container";
import { forexOptions } from "../../utils/lookupstore";
import utils from "../../utils/common";
import SupplierForm from "./form";
import Listing from "../../components/listing/newListing";
import baseapi from "../../api/baseapi";

export default function SupplierListing() {
	const [currentEditID, setCurrentEditID] = useState(null);
	const [forexList, setForexList] = useState([]);
	const [dropDownRead, setDropDownRead] = useState(false);
	const [totalOffsetAmount, setTotalOffsetAmount] = useState("Loading...");
	const apiURL = `/api/Supplier`;
	const [lockForm, setLockForm] = useState(false);

	useEffect(() => {
		// Promise.allSettled([forexOptions.store.load(), areaOptions.store.load(), salesmanOptions.store.load(), statementTypeOptions.store.load(), projectOptions.store.load(), glChartAccountOptions.store.load()]).then((lists) => {
		//     setDropDownList({
		//         forexList: lists[0].value.data, areaList: lists[1].value.data, salesmanList: lists[2].value.data, statementTypeList: lists[3].value.data, projectList: lists[4].value.data, chartAccountList: lists[5].value.data
		//     });
		// });

		if (dropDownRead !== true) {
			forexOptions.store.load().then((list) => {
				setForexList(list.data);
			});

			//Get total offset amount
			baseapi.httpget("/api/Supplier/GetSupplierTotalOutstanding").then((response) => {
				const { data } = response;
				// console.log("total outstanding", data);
				setTotalOffsetAmount(utils.numberToCurrency(data));
			});

			setDropDownRead(true);
		}
	}, [dropDownRead]);

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
		// console.log(e);
	}

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					className='listing-datagrid'
					apiURL={apiURL}
					listingURL='/api/Supplier/list'
					sortColumn={"Code"}
					listingTitle={"Supplier"}
					storageName={"SupplierListingColumnWidth"}
					onAddClick={setCurrentEditID}
					dateFilter={false}
					addButtonOnClick={() => {
						setLockForm(false);
					}}
					handleEdit={handleEdit}
					viewReportButton={true}
					ReportID={"o0FKfKRBCRE%3d"}>
					<Column visible={true} dataField='Code' allowSorting={true} sortIndex={0} sortOrder='asc' />

					<Column visible={true} dataField='Name' caption={"Company Name"} />

					<Column dataField="ForexCode" caption="Forex"/>

		
					<Column visible={true} dataField='ContactPerson' caption={"Contact Person"} />

					{/* <Column visible={true} dataField="Person" caption={"Contact"} 
                width={PersonColumnWidth}
                visibleIndex={PersonVisibleIndex}
            /> */}

					<Column visible={true} dataField='Telephone' caption={"Tel"} />

					<Column visible={true} dataField='OutstandingAmount' caption={"Outstanding Balance"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} />

					<Summary>
						<TotalItem
							column='OutstandingAmount'
							summaryType='sum'
							customizeText={(data) => {
								return `Total : ${totalOffsetAmount}`;
							}}
						/>
					</Summary>
				</Listing>
			</div>

			<SupplierForm 
				formID={currentEditID} 
				closePopup={setCurrentEditID} 
				lockedForm={lockForm}
				title={"Supplier"}
				apiURL={apiURL}
			/>
		</Container>
	);
}
