import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { DataGrid, Button as Pager, Toolbar, Column, Selection } from "devextreme-react/data-grid";
import { customerBalance, supplierBalance, customerOptions, statementTypeDebtorOptions, projectOptions, areaOptions, salesmanOptions, supplierOptions, debtorChartAccount, creditorChartAccount } from "../../../../utils/lookupstore";
import CustomizedLookup from "../../../../components/lookup/Lookup";
import utils, { loading, closeLoading } from "../../../../utils/common";
import baseapi from "../../../../api/baseapi";
import { Button } from "devextreme-react/button";
import { TextBox, SelectBox, DropDownBox, Popover, CheckBox } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import ErrorPopUpForm from "../../../../components/popup-form/ErrorPopupForm";

export default forwardRef(function AgeingReport(props, ref) {
	const className = props.className;
	const reportName = props.reportName !== undefined ? props.reportName : "";
	const isAR = props.isAR !== undefined ? props.isAR : false;

	// Customer Code Filter by Range 
	const fromCustomerLookupRef = useRef(null);
	const toCustomerLookupRef = useRef(null);
	const [fromCustomerList, setFromCustomerList] = useState([]);
	const [toCustomerList, setToCustomerList] = useState([]);

	// Salesman Code Filter by Range 
	const fromSalesmanLookupRef = useRef(null);
	const toSalesmanLookupRef = useRef(null);
	const [fromSalesmanList, setFromSalesmanList] = useState([]);
	const [toSalesmanList, setToSalesmanList] = useState([]);

	// Area Code Filter by Range 
	const fromAreaLookupRef = useRef(null);
	const toAreaLookupRef = useRef(null);
	const [fromAreaList, setFromAreaList] = useState([]);
	const [toAreaList, setToAreaList] = useState([]);

	// Supplier Code Filter by Range 
	const fromSupplierLookupRef = useRef(null);
	const toSupplierLookupRef = useRef(null);
	const [fromSupplierList, setFromSupplierList] = useState([]);
	const [toSupplierList, setToSupplierList] = useState([]);

	// Project Code Filter by Range 
	const fromProjectLookupRef = useRef(null);
	const toProjectLookupRef = useRef(null);
	const [fromProjectList, setFromProjectList] = useState([]);
	const [toProjectList, setToProjectList] = useState([]);

	// Chart Code Filter by Range 
	const fromChartLookupRef = useRef(null);
	const toChartLookupRef = useRef(null);
	const [fromChartList, setFromChartList] = useState([]);
	const [toChartList, setToChartList] = useState([]);

	const [dropDownList, setDropDownList] = useState({
		reportList: [],
		customerList: [],
		statementList: [],
		projectList: [],
		areaList: [],
		salesmanList: [],
		supplierList: []
	});
	const [originalDropDownList, setOriginalDropDownList] = useState({});
	const userID = JSON.parse(localStorage.getItem("data"));
	
	const [selectedProject, setSelectedProject] = useState(0);
	const [selectedCust, setSelectedCust] = useState(0);
	const [selectedSales, setSelectedSales] = useState(0);
	const [selectedArea, setSelectedArea] = useState(0);
	const [selectedSupp, setSelectedSupp] = useState(0);
	const [selectedChart, setSelectedChart] = useState(0);
	const [openProjectRange, setOpenProjectRange] = useState();
	const [openCustRange, setOpenCustRange] = useState();
	const [openSalesRange, setOpenSalesRange] = useState();
	const [openAreaRange, setOpenAreaRange] = useState();
	const [openSuppRange, setOpenSuppRange] = useState();
	const [openChartRange, setOpenChartRange] = useState();
	const [ageingDateParam, setAgeingDateParam] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [paramValue, setParamValue] = useState([
		{
			ParameterName: "AgeingDateParam",
			ParameterValue: utils.dateboxFormat(ageingDateParam)
		},
		{
			ParameterName: "IsZero",
			ParameterValue: false
		}
	]);
	const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];
	const [mainDataSource, setMainDataSource] = useState([]);
	const [detailDataSource, setDetailDataSource] = useState([]);
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const tempForm = useRef(null);
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const popupMessageRef = useRef(null);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(dropDownList)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		// if (Array.isArray(listingDataSource)) {
		// 	previousDataSource.current = [...listingDataSource];
		// }
	}, [dropDownList]);

	// Triggers onValueChanged when main data source changes.
	// Usually to set the main and detail data sources on the listing component.
	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ parent: mainDataSource, children: detailDataSource });
		}
	}, [mainDataSource]);

	// Enables/Disables the Print Report button.
	useEffect(() => {
		if (props.onButtonDisable !== undefined) {
			props.onButtonDisable({ value: printRepDisabled });
		}
	}, [printRepDisabled]);

	useImperativeHandle(ref, () => ({
		getMain() {
			onInitialized();
		},
		getParams() {
			return paramValue;
		}
	}));

	// Fetch required data for the dropdown list data sources.
	useEffect(() => {
		Promise.allSettled([
			isAR ? customerBalance.store.load() : supplierBalance.store.load(),
			customerOptions.store.load(),
			statementTypeDebtorOptions.store.load(),
			projectOptions.store.load(),
			salesmanOptions.store.load(),
			areaOptions.store.load(),
			supplierOptions.store.load(),
			isAR ? debtorChartAccount.store.load() : creditorChartAccount.store.load()
		]).then((lists) => {
			setDropDownList({
				reportList: lists[0].value.data,
				customerList: lists[1].value.data,
				statementList: lists[2].value.data,
				projectList: lists[3].value.data,
				salesmanList: lists[4].value.data,
				areaList: lists[5].value.data,
				supplierList: lists[6].value.data,
				chartList: lists[7].value.data
			});
			setOriginalDropDownList({
				reportList: lists[0].value.data,
				customerList: lists[1].value.data,
				statementList: lists[2].value.data,
				projectList: lists[3].value.data,
				salesmanList: lists[4].value.data,
				areaList: lists[5].value.data,
				supplierList: lists[6].value.data,
				chartList: lists[7].value.data
			});

			// console.log(lists[0].value.data)
		});
	}, [isAR]);

	// Build request body from selected parameters and send request.
	// Fetch data, set Main and Detail data sources.
	const onInitialized = () => {
		// console.log(selectedSalesKey);
		// loading("Loading Data");
		var parameters = null;
		if (isAR) {
			parameters = `?userID=${userID?.userID}&reportListingType=isAgeing isAR&reportName=${reportName}`;
		} else {
			parameters = `?userID=${userID?.userID}&reportListingType=isAgeing&reportName=${reportName}`;
		}
		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;

		// console.log("Parameters", paramValue);
		baseapi
			.httppost(`${utils.ReportURL}/api/Report/ReturnSQLResults2${parameters}`, copied)
			.then((response) => {
				const { data } = response;
				var mainDataArray = [];
				var detailsDataArray = [];
				if (data !== null) {
					for (var i = 0; i < data.length; i++) {
						// console.log(data[i]);
						if (data[i].Details[0].DocumentID !== "") {
							mainDataArray.push(data[i].MainData);
						}

						for (var j = 0; j < data[i].Details.length; j++) {
							// console.log(data[i].Details[j]);
							if (data[i].Details[j].DocumentID !== null) detailsDataArray = detailsDataArray.concat(data[i].Details[j]);
						}
					}
					// console.log(detailsDataArray);
					setMainDataSource(mainDataArray);
					setDetailDataSource(detailsDataArray);

					// Enable Print Report button.
					if (data.length > 0) {
						setPrintRepDisabled(false);
					}
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}
				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "IsZero") {
				param.ParameterValue = param.ParameterValue === "true";
			}
		});
	};

	// Setting the filter mode for the corresponding field.
	// 0 for No Filter, 1 for Range, 2 for Multi-select.
	function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "AgeingDateParam") {
					setAgeingDateParam(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "Customer":
								setSelectedCust(e.value);
								setOpenCustRange(true);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								setOpenSalesRange(true);
								break;
							case "Area":
								setSelectedArea(e.value);
								setOpenAreaRange(true);
								break;
							case "Supplier":
								setSelectedSupp(e.value);
								setOpenSuppRange(true);
								break;
							case "Project":
								setSelectedProject(e.value);
								setOpenProjectRange(true);
								break;
							case "Chart":
								setSelectedChart(e.value);
								setOpenChartRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "Customer":
								setSelectedCust(e.value);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								break;
							case "Area":
								setSelectedArea(e.value);
								break;
							case "Supplier":
								setSelectedSupp(e.value);
								break;
							case "Project":
								setSelectedProject(e.value);
								break;
							case "Chart":
								setSelectedChart(e.value);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	// Updates the field object in the paramValue state,
	// creates and pushes a new object if param is not found in state.
	const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (key.includes("List")) {
				if (index !== -1) {
					// Update the existing item if it exists
					updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
				} else {
					// Add a new item if the key doesn't exist
					updatedParamValue.push({ ParameterName: key, ParameterValue: value });
				}
			} else {
				if (index !== -1) {
					// Update the existing item if it exists
					updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
				} else {
					// Add a new item if the key doesn't exist
					updatedParamValue.push({ ParameterName: key, ParameterValue: value });
				}
			}

			return updatedParamValue;
		});
	};

	// Remove param field from state.
	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			const updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	// Return the values selected for the corresponding field.
	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "Customer" && paramObject["CustList"]) {
			selectedKeys = ensureArray(paramObject["CustList"]);
		} else if (type === "Salesman" && paramObject["SalesList"]) {
			selectedKeys = ensureArray(paramObject["SalesList"]);
		} else if (type === "Area" && paramObject["AreaList"]) {
			selectedKeys = ensureArray(paramObject["AreaList"]);
		} else if (type === "Supplier" && paramObject["SuppList"]) {
			selectedKeys = ensureArray(paramObject["SuppList"]);
		} else if (type === "Project" && paramObject["ProjectList"]) {
			selectedKeys = ensureArray(paramObject["ProjectList"]);
		} else if (type === "Chart" && paramObject["ChartList"]) {
			selectedKeys = ensureArray(paramObject["ChartList"]);
		}

		return selectedKeys;
	};

	// Format value to be an array.
	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	// Return selected value for given field.
	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	// Retunrns the data for the corresponding dropdown.
	const returnDropDownList = (type) => {
		if (type === "Customer") {
			return dropDownList.customerList;
		} else if (type === "Salesman") {
			return dropDownList.salesmanList;
		} else if (type === "Area") {
			return dropDownList.areaList;
		} else if (type === "Supplier") {
			return dropDownList.supplierList;
		} else if (type === "Project") {
			return dropDownList.projectList;
		} else if (type === "Chart") {
			return dropDownList.chartList;
		}
	};

	// Returns the data URL for the corresponding dropdown.
	const returnDropDownListURL = (type) => {
		if (type === "Customer") {
			return "api/Utilities/GetCustomers";
		} else if (type === "Salesman") {
			return "api/Utilities/GetSalesmans";
		} else if (type === "Area") {
			return "api/Utilities/GetAreas";
		} else if (type === "Supplier") {
			return "api/Utilities/GetSuppliers";
		} else if (type === "Project") {
			return "api/Utilities/GetProjects";
		} else if (type === "Chart") {
			return `api/Utilities/${isAR ? "GetGLDebtorAccount" : "GetGLCreditorAccounts"}`;
		}
	};

	// Refreshes the dropdown list data to the original data.
	function refreshLookupData(type) {
		if (type === "Customer") {
			setDropDownList((prevState) => ({
				...prevState,
				customerList: [...originalDropDownList.customerList]
			}));
		} else if (type === "Salesman") {
			setDropDownList((prevState) => ({
				...prevState,
				salesmanList: [...originalDropDownList.salesmanList]
			}));
		} else if (type === "Area") {
			setDropDownList((prevState) => ({
				...prevState,
				areaList: [...originalDropDownList.areaList]
			}));
		} else if (type === "Supplier") {
			setDropDownList((prevState) => ({
				...prevState,
				supplierList: [...originalDropDownList.supplierList]
			}));
		} else if (type === "Project") {
			setDropDownList((prevState) => ({
				...prevState,
				projectList: [...originalDropDownList.projectList]
			}));
		} else if (type === "Chart") {
			setDropDownList((prevState) => ({
				...prevState,
				chartList: [...originalDropDownList.chartList]
			}));
		}
	}

	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};

	// Use when searching in the multi select data grid.
	const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Customer: "customerList",
			Salesman: "salesmanList",
			Area: "areaList",
			Supplier: "supplierList",
			Project: "projectList",
			Chart: "chartList"
		};
		// console.log(paramValue);
		var value = "";

		if (!paging) {
			page = 0;
		}

		if (paramValue !== undefined) {
			value = paramValue;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

		baseapi
			.httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
			.then((response) => {
				const data = response.data;
				setDropDownList((prevState) => {
					const existingArray = prevState[arrayName] || [];
					const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
					return {
						...prevState,
						[arrayName]: [...existingArray, ...newData]
					};
				});
			})
			.catch(() => {
				throw "Network error";
			});
	};

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Customer") {
								// 	removeParamValue("CustList");
								// } else if (type === "Salesman") {
								// 	removeParamValue("SalesList");
								// } else if (type === "Area") {
								// 	removeParamValue("AreaList");
								// } else if (type === "Supplier") {
								// 	removeParamValue("SuppList");
								// } else if (type === "Project") {
								// 	removeParamValue("ProjectList");
								// } else if (type === "Chart") {
								// 	removeParamValue("ChartList");
								// }

								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "Customer") {
									removeParamValue("CustList");
									removeParamValue("FromCust");
									removeParamValue("ToCust");
									setSelectedCust(0);
								} else if (type === "Salesman") {
									removeParamValue("SalesList");
									removeParamValue("FromSales");
									removeParamValue("ToSales");
									setSelectedSales(0);
								} else if (type === "Area") {
									removeParamValue("AreaList");
									removeParamValue("FromArea");
									removeParamValue("ToArea");
									setSelectedArea(0);
								} else if (type === "Supplier") {
									removeParamValue("SuppList");
									removeParamValue("FromSupp");
									removeParamValue("ToSupp");
									setSelectedSupp(0);
								} else if (type === "Project") {
									removeParamValue("ProjectList");
									removeParamValue("FromProject");
									removeParamValue("ToProject");
									setSelectedProject(0);
								} else if (type === "Chart") {
									removeParamValue("ChartList");
									removeParamValue("FromChart");
									removeParamValue("ToChart");
									setSelectedChart(0);
								}
								refreshLookupData(type);
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr='code'
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "Customer") {
									removeParamValue("CustList");
								} else if (type === "Salesman") {
									removeParamValue("SalesList");
								} else if (type === "Area") {
									removeParamValue("AreaList");
								} else if (type === "Supplier") {
									removeParamValue("SuppList");
								} else if (type === "Project") {
									removeParamValue("ProjectList");
								} else if (type === "Chart") {
									removeParamValue("ChartList");
								}
								refreshLookupData(type);
							}
						}
					}}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
										// console.log(e);
									}
								}
							});
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<Column
						dataField='code'
						elementAttr={{ dataField: type === "Customer" ? "CustomerCode" : type === "Salesman" ? "SalesmanCode" : type === "Area" ? "AreaCode" : type === "Chart" ? "ChartCode" : "SupplierCode" }}
						caption={type === "Customer" ? "Customer Code" : type === "Salesman" ? "Salesman Code" : type === "Area" ? "Area Code" : type === "Chart" ? "Chart Code" : "Supplier Code"}
						visible={true}
						defaultSortOrder={"asc"}
						alignment='left'
						allowGrouping={false}
					/>

					<Column
						dataField='name'
						elementAttr={{ dataField: type === "Customer" ? "CustomerName" : type === "Salesman" ? "SalesmanName" : type === "Area" ? "AreaName" : type === "Chart" ? "ChartName" : "SupplierName" }}
						caption={type === "Customer" ? "Customer Name" : type === "Salesman" ? "Salesman Name" : type === "Area" ? "Area Name" : type === "Chart" ? "Chart Name" : "Supplier Name"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

					{(type === "Customer" || type === "Supplier")  && <Column
						dataField='forexCode'
						elementAttr={{ dataField: type === "Customer" ? "CustomerForexCode" : "SupplierForexCode" }}
						caption={"Currency"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>}

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "Customer") {
			updateParamValue("CustList", e.selectedRowKeys);
		} else if (type === "Salesman") {
			// console.log(e.selectedRowKeys);
			updateParamValue("SalesList", e.selectedRowKeys);
		} else if (type === "Area") {
			updateParamValue("AreaList", e.selectedRowKeys);
		} else if (type === "Supplier") {
			updateParamValue("SuppList", e.selectedRowKeys);
		} else if (type === "Project") {
			updateParamValue("ProjectList", e.selectedRowKeys);
		} else if (type === "Chart") {
			updateParamValue("ChartList", e.selectedRowKeys);
		}
	};

	// Closes popup when exiting.
	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "Customer") {
				setOpenCustRange(false);
			} else if (type === "Salesman") {
				setOpenSalesRange(false);
			} else if (type === "Area") {
				setOpenAreaRange(false);
			} else if (type === "Supplier") {
				setOpenSuppRange(false);
			} else if (type === "Project") {
				setOpenProjectRange(false);
			} else if (type === "Chart") {
				setOpenChartRange(false);
			}
		} else {
			if (type === "Customer") {
				setOpenCustRange(true);
			} else if (type === "Salesman") {
				setOpenSalesRange(true);
			} else if (type === "Area") {
				setOpenAreaRange(true);
			} else if (type === "Supplier") {
				setOpenSuppRange(true);
			} else if (type === "Project") {
				setOpenProjectRange(true);
			} else if (type === "Chart") {
				setOpenChartRange(true);
			}
		}
	};

	return (
		<div>
			<div className='popup-form-item-container3'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Date </div>
					<DateBox
						type='date'
						value={ageingDateParam}
						elementAttr={{ dataField: "AgeingDateParam" }}
						useMaskBehavior={true}
						width={"250px"}
						label='To'
						labelMode='floating'
						displayFormat='dd/MM/yyyy'
						dateSerializationFormat='yyyy-MM-dd'
						onValueChanged={(e) => {
							valueOnChange(e, "AgeingDateParam");
						}}
					/>
				</div>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Project </div>
					{selectedProject === 0 && (
						<SelectBox
							width={"250px"}
							dataSource={filterTypes}
							value={selectedProject}
							displayExpr={"Type"}
							valueExpr={"ID"}
							elementAttr={{ dataField: "FilterNumber" }}
							onValueChanged={(e) => {
								valueOnChange(e, "Project");
							}}
						/>
					)}
					{selectedProject === 1 && (
						<div onClick={() => setOpenProjectRange(true)}>
							<TextBox
								value={returnSelectedValue("FromProject") || returnSelectedValue("ToProject") ? `${returnSelectedValue("FromProject")} to ${returnSelectedValue("ToProject")}` : "No Range Selected Yet"}
								// useMaskBehavior={true} // Enable mask behavior
								// mask="cccccc" // Define the input mask
								id='Project'
								style={{ marginRight: "10px" }}
								width={"250px"}>
								<Popover
									target='#Project'
									showEvent={"click"}
									// hideEvent="mouseleave"
									position='bottom'
									visible={openProjectRange}
									onHiding={(e) => popFunction(e, "Project")}
									dragEnabled={false}
									hideOnOutsideClick={true}
									width={500}
									height={125}>
									<div>
										<div className='popup-form-item-container2'>
											<div>
												<label>From</label>
												<CustomizedLookup
													ref={fromProjectLookupRef}
													className={"listing-datagrid"}
													dataSource={fromProjectList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("FromProject")}
													onSelectionChanged={(e) => {
														updateParamValue("FromProject", e.value);
													}}
													dataSourceURL={"api/Utilities/GetProjects"}
													displayText={returnSelectedValue("FromProject")}
													onDataSourceChanged={setFromProjectList}>
													<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
													<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
											<div>
												<label>To</label>

												<CustomizedLookup
													ref={toProjectLookupRef}
													className={"listing-datagrid"}
													dataSource={toProjectList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("ToProject")}
													onSelectionChanged={(e) => {
														updateParamValue("ToProject", e.value);
													}}
													dataSourceURL={"api/Utilities/GetProjects"}
													displayText={returnSelectedValue("ToProject")}
													onDataSourceChanged={setToProjectList}>
													<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
													<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
										</div>
										<div>
											<Button
												style={{ position: "absolute", top: "65%", left: "75%" }}
												text='Clear Filter'
												stylingMode='text'
												type='default'
												onClick={() => {
													removeParamValue("ProjectList");
													removeParamValue("FromProject");
													removeParamValue("ToProject");
													setSelectedProject(0);
												}}
												elementAttr={{ dataField: "FilterNumber" }}
											/>
										</div>
									</div>
								</Popover>
							</TextBox>
						</div>
					)}
					{selectedProject === 2 && (
						<div>
							<TextBox
								value={returnSelectedKeys("Project").length !== 0 ? `${returnSelectedKeys("Project").length} Selected  (${returnSelectedKeys("Project")})` : `${returnSelectedKeys("Project").length} Selected`}
								style={{ marginTop: "-35px" }}
								height={0}
								showClearButton={false}
								width={"250px"}>
								<DropDownBox
									// onClosed={(e) => closeDropDownBox(e, "Project")}
									ref={dropdownRef}
									// opened={openCustSpecific}
									valueExpr='id'
									deferRendering={false}
									displayExpr='code'
									showClearButton={false}
									contentRender={(e) => DataGridMultiRender("Project")}
									defaultOpened={true}
									width={"250px"}
									style={{ marginTop: "-2.5px" }}
									dropDownOptions={{ width: "auto" }}
								/>
							</TextBox>
						</div>
					)}
				</div>
			</div>

			{isAR && (
				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Customer </div>
						{selectedCust === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedCust}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Customer");
								}}
							/>
						)}
						{selectedCust === 1 && (
							<div onClick={() => setOpenCustRange(true)}>
								<TextBox
									value={returnSelectedValue("FromCust") || returnSelectedValue("ToCust") ? `${returnSelectedValue("FromCust")} to ${returnSelectedValue("ToCust")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Customer'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Customer'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openCustRange}
										onHiding={(e) => popFunction(e, "Customer")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromCustomerLookupRef}
														className={"listing-datagrid"}
														dataSource={fromCustomerList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromCust")}
														onSelectionChanged={(e) => {
															updateParamValue("FromCust", e.value);
														}}
														dataSourceURL={"api/Utilities/GetCustomers"}
														displayText={returnSelectedValue("FromCust")}
														onDataSourceChanged={setFromCustomerList}>
														<Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
														<Column dataField='forexCode' elementAttr={{ dataField: "CustomerForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toCustomerLookupRef}
														className={"listing-datagrid"}
														dataSource={toCustomerList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToCust")}
														onSelectionChanged={(e) => {
															updateParamValue("ToCust", e.value);
														}}
														dataSourceURL={"api/Utilities/GetCustomers"}
														displayText={returnSelectedValue("ToCust")}
														onDataSourceChanged={setToCustomerList}>
														<Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
														<Column dataField='forexCode' elementAttr={{ dataField: "CustomerForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("CustList");
														removeParamValue("FromCust");
														removeParamValue("ToCust");
														setSelectedCust(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedCust === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Customer").length !== 0 ? `${returnSelectedKeys("Customer").length} Selected  (${returnSelectedKeys("Customer")})` : `${returnSelectedKeys("Customer").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Customer")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Customer")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Salesman </div>
						{selectedSales === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSales}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Salesman");
								}}
							/>
						)}
						{selectedSales === 1 && (
							<div onClick={() => setOpenSalesRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSales") || returnSelectedValue("ToSales") ? `${returnSelectedValue("FromSales")} to ${returnSelectedValue("ToSales")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Salesman'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Salesman'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSalesRange}
										onHiding={(e) => popFunction(e, "Salesman")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSalesmanLookupRef}
														className={"listing-datagrid"}
														dataSource={fromSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSales")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("FromSales")}
														onDataSourceChanged={setFromSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSalesmanLookupRef}
														className={"listing-datagrid"}
														dataSource={toSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSales")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("ToSales")}
														onDataSourceChanged={setToSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("SalesList");
														removeParamValue("FromSales");
														removeParamValue("ToSales");
														setSelectedSales(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSales === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Salesman").length !== 0 ? `${returnSelectedKeys("Salesman").length} Selected  (${returnSelectedKeys("Salesman")})` : `${returnSelectedKeys("Salesman").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openSalesSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Salesman")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Salesman")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>
			)}

			{!isAR && (
				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Supplier </div>
						{selectedSupp === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSupp}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Supplier");
								}}
							/>
						)}
						{selectedSupp === 1 && (
							<div onClick={() => setOpenSuppRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSupp") || returnSelectedValue("ToSupp") ? `${returnSelectedValue("FromSupp")} to ${returnSelectedValue("ToSupp")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Supplier'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Supplier'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSuppRange}
										onHiding={(e) => popFunction(e, "Supplier")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSupplierLookupRef}
														className={"listing-datagrid"}
														dataSource={fromSupplierList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSupp")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSupp", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSuppliers"}
														displayText={returnSelectedValue("FromSupp")}
														onDataSourceChanged={setFromSupplierList}>
														<Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
														<Column dataField='forexCode' elementAttr={{ dataField: "SupplierForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSupplierLookupRef}
														className={"listing-datagrid"}
														dataSource={toSupplierList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSupp")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSupp", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSuppliers"}
														displayText={returnSelectedValue("ToSupp")}
														onDataSourceChanged={setToSupplierList}>
														<Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
														<Column dataField='forexCode' elementAttr={{ dataField: "SupplierForexCode" }} visible={true} caption='Currency' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														setSelectedSupp(0);
														removeParamValue("SuppList");
														removeParamValue("FromSupp");
														removeParamValue("ToSupp");
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSupp === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Supplier").length !== 0 ? `${returnSelectedKeys("Supplier").length} Selected  (${returnSelectedKeys("Supplier")})` : `${returnSelectedKeys("Supplier").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Supplier")}
										ref={dropdownRef}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Supplier")}
										defaultOpened={true}
										style={{ marginTop: "-2.5px" }}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Salesman </div>
						{selectedSales === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSales}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Salesman");
								}}
							/>
						)}
						{selectedSales === 1 && (
							<div onClick={() => setOpenSalesRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSales") || returnSelectedValue("ToSales") ? `${returnSelectedValue("FromSales")} to ${returnSelectedValue("ToSales")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Salesman'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Salesman'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSalesRange}
										onHiding={(e) => popFunction(e, "Salesman")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSalesmanLookupRef}
														className={"listing-datagrid"}
														dataSource={fromSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSales")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("FromSales")}
														onDataSourceChanged={setFromSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSalesmanLookupRef}
														className={"listing-datagrid"}
														dataSource={toSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSales")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("ToSales")}
														onDataSourceChanged={setToSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("SalesList");
														removeParamValue("FromSales");
														removeParamValue("ToSales");
														setSelectedSales(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSales === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Salesman").length !== 0 ? `${returnSelectedKeys("Salesman").length} Selected  (${returnSelectedKeys("Salesman")})` : `${returnSelectedKeys("Salesman").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openSalesSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Salesman")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Salesman")}
										defaultOpened={true}
										style={{ marginTop: "-2.5px" }}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>
			)}

			<div className='popup-form-item-container3'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Control A/C </div>
					{selectedChart === 0 && (
						<SelectBox
							width={"250px"}
							dataSource={filterTypes}
							value={selectedChart}
							displayExpr={"Type"}
							valueExpr={"ID"}
							elementAttr={{ dataField: "FilterNumber" }}
							onValueChanged={(e) => {
								valueOnChange(e, "Chart");
							}}
						/>
					)}
					{selectedChart === 1 && (
						<div onClick={() => setOpenChartRange(true)}>
							<TextBox
								value={returnSelectedValue("FromChart") || returnSelectedValue("ToChart") ? `${returnSelectedValue("FromChart")} to ${returnSelectedValue("ToChart")}` : "No Range Selected Yet"}
								// useMaskBehavior={true} // Enable mask behavior
								// mask="cccccc" // Define the input mask
								id='Chart'
								style={{ marginRight: "10px" }}
								width={"250px"}>
								<Popover
									target='#Chart'
									showEvent={"click"}
									// hideEvent="mouseleave"
									position='bottom'
									visible={openChartRange}
									onHiding={(e) => popFunction(e, "Chart")}
									dragEnabled={false}
									hideOnOutsideClick={true}
									width={500}
									height={125}>
									<div>
										<div className='popup-form-item-container2'>
											<div>
												<label>From</label>
												<CustomizedLookup
													ref={fromChartLookupRef}
													className={"listing-datagrid"}
													dataSource={fromChartList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("FromChart")}
													onSelectionChanged={(e) => {
														updateParamValue("FromChart", e.value);
													}}
													dataSourceURL={`api/Utilities/${isAR ? "GetGLDebtorAccount" : "GetGLCreditorAccounts"}`}
													displayText={returnSelectedValue("FromChart")}
													onDataSourceChanged={setFromChartList}>
													<Column dataField='code' elementAttr={{ dataField: "ChartCode" }} visible={true} caption='Chart Code' alignment='left' allowGrouping={false} />
													<Column dataField='name' elementAttr={{ dataField: "ChartName" }} visible={true} caption='Chart Name' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
											<div>
												<label>To</label>

												<CustomizedLookup
													ref={toChartLookupRef}
													className={"listing-datagrid"}
													dataSource={toChartList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("ToChart")}
													onSelectionChanged={(e) => {
														updateParamValue("ToChart", e.value);
													}}
													dataSourceURL={`api/Utilities/${isAR ? "GetGLDebtorAccount" : "GetGLCreditorAccounts"}`}
													displayText={returnSelectedValue("ToChart")}
													onDataSourceChanged={setToChartList}>
													<Column dataField='code' elementAttr={{ dataField: "ChartCode" }} visible={true} caption='Chart Code' alignment='left' allowGrouping={false} />
													<Column dataField='name' elementAttr={{ dataField: "ChartName" }} visible={true} caption='Chart Name' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
										</div>
										<div>
											<Button
												style={{ position: "absolute", top: "65%", left: "75%" }}
												text='Clear Filter'
												stylingMode='text'
												type='default'
												onClick={() => {
													removeParamValue("ChartList");
													removeParamValue("FromChart");
													removeParamValue("ToChart");
													setSelectedChart(0);
												}}
												elementAttr={{ dataField: "FilterNumber" }}
											/>
										</div>
									</div>
								</Popover>
							</TextBox>
						</div>
					)}
					{selectedChart === 2 && (
						<div>
							<TextBox
								value={returnSelectedKeys("Chart").length !== 0 ? `${returnSelectedKeys("Chart").length} Selected  (${returnSelectedKeys("Chart")})` : `${returnSelectedKeys("Chart").length} Selected`}
								style={{ marginTop: "-35px" }}
								height={0}
								showClearButton={false}
								width={"250px"}>
								<DropDownBox
									// onClosed={(e) => closeDropDownBox(e, "Chart")}
									ref={dropdownRef}
									// opened={openCustSpecific}
									valueExpr='id'
									deferRendering={false}
									displayExpr='code'
									showClearButton={false}
									contentRender={(e) => DataGridMultiRender("Chart")}
									defaultOpened={true}
									style={{ marginTop: "-2.5px" }}
									width={"250px"}
									dropDownOptions={{ width: "auto" }}
								/>
							</TextBox>
						</div>
					)}
				</div>

				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'	style={{ marginTop: "7px" }}> Area </div>
					{selectedArea === 0 && (
						<SelectBox
							width={"250px"}
							dataSource={filterTypes}
							value={selectedArea}
							displayExpr={"Type"}
							valueExpr={"ID"}
							elementAttr={{ dataField: "FilterNumber" }}
							onValueChanged={(e) => {
								valueOnChange(e, "Area");
							}}
						/>
					)}
					{selectedArea === 1 && (
						<div onClick={() => setOpenAreaRange(true)}>
							<TextBox
								value={returnSelectedValue("FromArea") || returnSelectedValue("ToArea") ? `${returnSelectedValue("FromArea")} to ${returnSelectedValue("ToArea")}` : "No Range Selected Yet"}
								// useMaskBehavior={true} // Enable mask behavior
								// mask="cccccc" // Define the input mask
								id='Area2'
								style={{ marginRight: "10px" }}
								width={"250px"}>
								<Popover
									target='#Area2'
									showEvent={"click"}
									// hideEvent="mouseleave"
									position='bottom'
									visible={openAreaRange}
									onHiding={(e) => popFunction(e, "Area")}
									dragEnabled={false}
									hideOnOutsideClick={true}
									width={500}
									height={125}>
									<div>
										<div className='popup-form-item-container2'>
											<div>
												<label>From</label>
												<CustomizedLookup
													ref={fromAreaLookupRef}
													className={"listing-datagrid"}
													dataSource={fromAreaList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("FromArea")}
													onSelectionChanged={(e) => {
														updateParamValue("FromArea", e.value);
													}}
													dataSourceURL={"api/Utilities/GetAreas"}
													displayText={returnSelectedValue("FromArea")}
													onDataSourceChanged={setFromAreaList}>
													<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
													<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
											<div>
												<label>To</label>

												<CustomizedLookup
													ref={toAreaLookupRef}
													className={"listing-datagrid"}
													dataSource={toAreaList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("ToArea")}
													onSelectionChanged={(e) => {
														updateParamValue("ToArea", e.value);
													}}
													dataSourceURL={"api/Utilities/GetAreas"}
													displayText={returnSelectedValue("ToArea")}
													onDataSourceChanged={setToAreaList}>
													<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
													<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
										</div>
										<div>
											<Button
												style={{ position: "absolute", top: "65%", left: "75%" }}
												text='Clear Filter'
												stylingMode='text'
												type='default'
												onClick={() => {
													removeParamValue("AreaList");
													removeParamValue("FromArea");
													removeParamValue("ToArea");
													setSelectedArea(0);
												}}
												elementAttr={{ dataField: "FilterNumber" }}
											/>
										</div>
									</div>
								</Popover>
							</TextBox>
						</div>
					)}
					{selectedArea === 2 && (
						<div>
							<TextBox
								value={returnSelectedKeys("Area").length !== 0 ? `${returnSelectedKeys("Area").length} Selected  (${returnSelectedKeys("Area")})` : `${returnSelectedKeys("Area").length} Selected`}
								style={{ marginTop: "-35px" }}
								height={0}
								showClearButton={false}
								width={"250px"}>
								<DropDownBox
									ref={dropdownRef}
									// opened={openAreaSpecific}
									// onClosed={(e) => closeDropDownBox(e, "Area")}
									valueExpr='id'
									deferRendering={false}
									displayExpr='code'
									showClearButton={false}
									contentRender={() => DataGridMultiRender("Area")}
									defaultOpened={true}
									style={{ marginTop: "-2.5px" }}
									width={"250px"}
									dropDownOptions={{ width: "auto" }}
								/>
							</TextBox>
						</div>
					)}
				</div>
			</div>

			<div className='popup-form-item-container4'>
				<div className='popup-group-form-item'>
					<CheckBox
						value={returnSelectedValue("IsZero")}
						defaultValue={false}
						text='Show Zero Balance'
						onValueChanged={(e) => {
							updateParamValue("IsZero", e.value);
						}}
					/>
				</div>
			</div>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
});
