import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class Application extends React.Component {
    // render() {
    //     return <CommonTable title="Application" url="/api/Application" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
        <SettingListing 
            listingTitle="Application" 
            apiURL="/api/Application" 
            listingURL="/api/Application/list"
            sortColumn={"Code"} 
            defaultSettingTemplate={true} 
            dateFilter={false}
            storageName={"SettingApplicationListing"}
            className={"listing-datagrid"}
        />
        </div>
        </Container>
    }
}

export default Application;