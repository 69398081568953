import { useRef, useState, useEffect } from "react";
import Container from "../../components/container";
import { trialBalanceListing, projectOptions, salesmanOptions, areaOptions } from "../../utils/lookupstore";
import { Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import baseapi from "../../api/baseapi";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import { DataGrid, Button as Pager, Toolbar, Column, Selection, TotalItem, Summary } from "devextreme-react/data-grid";
import utils, { loading, closeLoading } from "../../utils/common";
import PathIndicator from "../../components/path-indicator/PathIndicator";
import CustomizedLookup from "../../components/lookup/Lookup";
import { TextBox, NumberBox, SelectBox, DropDownBox, Popover, CheckBox } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import { Popup } from "devextreme-react/popup";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";

export default function BalanceSheet() {
	const fromSalesmanLookupRef = useRef(null);
	const toSalesmanLookupRef = useRef(null);
	const [fromSalesmanList, setFromSalesmanList] = useState([]);
	const [toSalesmanList, setToSalesmanList] = useState([]);
	const fromAreaLookupRef = useRef(null);
	const toAreaLookupRef = useRef(null);
	const [fromAreaList, setFromAreaList] = useState([]);
	const [toAreaList, setToAreaList] = useState([]);
	const fromProjectLookupRef = useRef(null);
	const toProjectLookupRef = useRef(null);
	const [fromProjectList, setFromProjectList] = useState([]);
	const [toProjectList, setToProjectList] = useState([]);

	const tempForm = useRef(null);
	const storageName = "TrailBalanceReportListingColumnWidth";
	const [listingDataSource, setListingDataSource] = useState(null);
	const [dropDownList, setDropDownList] = useState({
		reportList: []
	});
	const [originalDropDownList, setOriginalDropDownList] = useState({});
	const userID = JSON.parse(localStorage.getItem("data"));
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [date, setDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [paramValue, setParamValue] = useState([
		{
			ParameterName: "ToDate",
			ParameterValue: utils.dateboxFormat(date)
		},
		{
			ParameterName: "IsZero",
			ParameterValue: false
		},
		{
			ParameterName: "ReportName",
			ParameterValue: "This Year"
		},
		{
			ParameterName: "ReportID",
			ParameterValue: "-vbaTe_4HoY%3d"
		}
	]);
	const [openReportPop, setOpenReportPop] = useState(false);
	const [selectedSales, setSelectedSales] = useState(0);
	const [selectedArea, setSelectedArea] = useState(0);
	const [openSalesRange, setOpenSalesRange] = useState();
	const [openAreaRange, setOpenAreaRange] = useState();
	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const [openProjectRange, setOpenProjectRange] = useState();
	const [selectedProject, setSelectedProject] = useState(0);
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const popupMessageRef = useRef(null);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(dropDownList)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		// if (Array.isArray(listingDataSource)) {
		// 	previousDataSource.current = [...listingDataSource];
		// }
	}, [dropDownList]);

	const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];

	useEffect(() => {
		Promise.allSettled([trialBalanceListing.store.load(), projectOptions.store.load(), salesmanOptions.store.load(), areaOptions.store.load()]).then((lists) => {
			setDropDownList({
				reportList: lists[0].value.data,
				projectList: lists[1].value.data,
				salesmanList: lists[2].value.data,
				areaList: lists[3].value.data
			});
			setOriginalDropDownList({
				reportList: lists[0].value.data,
				projectList: lists[1].value.data,
				salesmanList: lists[2].value.data,
				areaList: lists[3].value.data
			});

			// console.log(lists[0].value.data)
		});
	}, []);

	const handlePrintBtn = (e) => {
		if (returnSelectedValue("ReportID") !== null) {
			baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportName?reportID=${returnSelectedValue("ReportID")}&accessToken=${currentToken}`).then((response) => {
				const { data } = response;
				data.map((items) => {
					confirmReportParameters(items.ID, items.ViewerAccessURL);
				});
			});
		}
	};

	const confirmReportParameters = async (reportID, ViewerAccessURL) => {
		var documentViewerLink = ViewerAccessURL;

		try {
			// const existingParamArr = [];
			for (const param of paramValue) {
				if (param.ParameterName.includes("List")) {
					documentViewerLink += `&${param.ParameterName}=${listingJoin(param.ParameterValue)}`;
				} else {
					documentViewerLink += `&${param.ParameterName}=${param.ParameterValue}`;
				}
			}
			// console.log(existingParamArr);

			window.open(documentViewerLink, "_blank");
		} catch (error) {
			console.error("Error fetching report parameters:", error);
		}
	};

	function listingJoin(listValue) {
		return listValue.replace("$%", ",");
	}

	const onInitialized = () => {
		var parameters = `?userID=${userID?.userID}&reportListingType=isTrial&reportName=${returnSelectedValue("ReportName")}`;
		loading();

		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}

			if (typeof param.ParameterValue !== "string") {
				param.ParameterValue = String(param.ParameterValue);
			}
		});

		var copied = tempForm.current;
		// console.log(copied);

		baseapi
			.httppost(`${utils.ReportURL}/api/Report/TrialBalanceLedger${parameters}`, copied)
			.then((response) => {
				const { data } = response;
				// console.log(data);
				if (data !== null && data !== undefined) {
					setListingDataSource(data);

					if (data.length > 0) {
						setPrintRepDisabled(false);
					}

					closeLoading();
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}
				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});

		tempForm.current.forEach((param) => {
			if (param.ParameterName === "IsZero") {
				param.ParameterValue = param.ParameterValue === "true";
			}
		});
	};

	function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "ToDate") {
					setDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								setOpenProjectRange(true);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								setOpenSalesRange(true);
								break;
							case "Area":
								setSelectedArea(e.value);
								setOpenAreaRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "Project":
								setSelectedProject(e.value);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								break;
							case "Area":
								setSelectedArea(e.value);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const updateParamValue = (key, value) => {
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (index !== -1) {
				// Update the existing item if it exists
				updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
			} else {
				// Add a new item if the key doesn't exist
				updatedParamValue.push({ ParameterName: key, ParameterValue: value });
			}

			return updatedParamValue;
		});
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			var updatedParamValue = null;
			updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "Project" && paramObject["ProjectList"]) {
			selectedKeys = ensureArray(paramObject["ProjectList"]);
		} else if (type === "Salesman" && paramObject["SalesList"]) {
			selectedKeys = ensureArray(paramObject["SalesList"]);
		} else if (type === "Area" && paramObject["AreaList"]) {
			selectedKeys = ensureArray(paramObject["AreaList"]);
		}

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	const returnDropDownList = (type) => {
		if (type === "Project") {
			return dropDownList.projectList;
		} else if (type === "Salesman") {
			return dropDownList.salesmanList;
		} else if (type === "Area") {
			return dropDownList.areaList;
		}
	};

	const returnDropDownListURL = (type) => {
		if (type === "Salesman") {
			return "api/Utilities/GetSalesmans";
		} else if (type === "Area") {
			return "api/Utilities/GetAreas";
		} else if (type === "Project") {
			return "api/Utilities/GetProjects";
		}
	};

	function refreshLookupData(type) {
		if (type === "Salesman") {
			setDropDownList((prevState) => ({
				...prevState,
				salesmanList: [...originalDropDownList.salesmanList]
			}));
		} else if (type === "Area") {
			setDropDownList((prevState) => ({
				...prevState,
				areaList: [...originalDropDownList.areaList]
			}));
		} else if (type === "Project") {
			setDropDownList((prevState) => ({
				...prevState,
				projectList: [...originalDropDownList.projectList]
			}));
		}
	}

	const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Salesman: "salesmanList",
			Area: "areaList",
			Project: "projectList"
		};
		// console.log(paramValue);
		var value = "";

		if (!paging) {
			page = 0;
		}

		if (paramValue !== undefined) {
			value = paramValue;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

		baseapi
			.httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
			.then((response) => {
				const data = response.data;
				setDropDownList((prevState) => {
					const existingArray = prevState[arrayName] || [];
					const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
					return {
						...prevState,
						[arrayName]: [...existingArray, ...newData]
					};
				});
			})
			.catch(() => {
				throw "Network error";
			});
	};

	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Project") {
								// 	removeParamValue("ProjectList");
								// } else if (type === "Salesman") {
								// 	removeParamValue("SalesList");
								// } else if (type === "Area") {
								// 	removeParamValue("AreaList");
								// }
								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "Project") {
									removeParamValue("ProjectList");
									removeParamValue("FromProject");
									removeParamValue("ToProject");
									setSelectedProject(0);
								} else if (type === "Salesman") {
									setSelectedSales(0);
									removeParamValue("SalesList");
									removeParamValue("FromSales");
									removeParamValue("ToSales");
								} else if (type === "Area") {
									setSelectedArea(0);
									removeParamValue("AreaList");
									removeParamValue("FromArea");
									removeParamValue("ToArea");
								}
								refreshLookupData(type);
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid listing-datagrid"}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr='code'
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "Project") {
									removeParamValue("ProjectList");
								} else if (type === "Salesman") {
									removeParamValue("SalesList");
								} else if (type === "Area") {
									removeParamValue("AreaList");
								}
								refreshLookupData(type);
							}
						}
					}}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
										// console.log(e);
									}
								}
							});
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
				// onFocusedRowChanged={(e) => {
				//   const data = e.row.data;
				//   currentFocusIndex.current = e.rowIndex;
				//   if(e.event.key === "Enter") {
				//     setSelectedCustKey(data.id);
				//   }
				// }}
				>
					<Column
						dataField='code'
						elementAttr={{
							dataField: type === "Salesman" ? "SalesmanCode" : type === "Area" ? "AreaCode" : "ProjectCode"
						}}
						caption={type === "Salesman" ? "Salesman Code" : type === "Area" ? "Area Code" : "Project Code"}
						visible={true}
						defaultSortOrder={"asc"}
						alignment='left'
						allowGrouping={false}
					/>

					<Column
						dataField='name'
						elementAttr={{ dataField: type === "Salesman" ? "SalesmanName" : type === "Project" ? "ProjectName" : "AreaName" }}
						caption={type === "Salesman" ? "Salesman Name" : type === "Project" ? "Project Name" : "Area Name"}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "Project") {
			updateParamValue("ProjectList", e.selectedRowKeys);
		} else if (type === "Salesman") {
			updateParamValue("SalesList", e.selectedRowKeys);
		} else if (type === "Area") {
			updateParamValue("AreaList", e.selectedRowKeys);
		}
	};

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "Project") {
				setOpenProjectRange(false);
			} else if (type === "Salesman") {
				setOpenSalesRange(false);
			} else if (type === "Area") {
				setOpenAreaRange(false);
			}
		} else {
			if (type === "Project") {
				setOpenProjectRange(true);
			} else if (type === "Salesman") {
				setOpenSalesRange(true);
			} else if (type === "Area") {
				setOpenAreaRange(true);
			}
		}
	};

	const onRowPrepared = (e) => {
		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find((c) => c.command === "transparent");
			//Ignore the fixed columns
			if (utils.isNullOrEmpty(find)) {
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}

				saveToLS(storageName, columns);
			}
		}
	};

	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);

			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

	const columns = [
		{ dataField: "ChartAccountCode", caption: "Code" },
		{ dataField: "Description", caption: "GL Description" },
		{
			dataField: "Debit",
			alignment: "center",
			caption: "Debit",
			dataType: "number",
			customizeText: (e) => {
				return e.value !== 0
					? utils.numberToCurrency(e.value.toString())
					: returnSelectedValue("IsZero")
						? utils.numberToCurrency(e.value.toString())
						: "-";
			},
		},
		{
			dataField: "Credit",
			alignment: "center",
			caption: "Credit",
			dataType: "number",
			customizeText: (e) => {
				return e.value !== 0
					? utils.numberToCurrency(e.value.toString())
					: returnSelectedValue("IsZero")
						? utils.numberToCurrency(e.value.toString())
						: "-";
			},
		},
	];

	return (
		<Container>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>
					<PathIndicator />
				</span>
			</div>

			<Popup visible={openReportPop} width={"auto"} height={"auto"} dragEnabled={false} title='Print Report' showCloseButton={false}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Report</div>
					<SelectBox
						value={returnSelectedValue("ReportID")}
						elementAttr={{ dataField: "ReportID" }}
						onValueChanged={(e) => {
							// console.log(e.value);
							updateParamValue("ReportID", e.value);
						}}
						alignment='left'
						width={"450px"}
						dataSource={dropDownList.reportList}
						displayExpr={"code"}
						valueExpr={"id"}
						searchEnabled={true}
						showClearButton={true}
					/>
				</div>
				<div className='popup-group-form-item' style={{ marginTop: "15px", justifyContent: "flex-end" }}>
					<Button text='Cancel' type='normal' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(false)} />
					<Button text='Print Report' type='success' width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => handlePrintBtn(e)} />
				</div>
			</Popup>

			<div style={{ backgroundColor: "#EFEFEF", borderRadius: "15px", width: "80%", padding: "0.7%" }}>
				<div className='popup-form-item-container'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date</div>
						<DateBox
							type='date'
							value={date}
							elementAttr={{ dataField: "ToDate" }}
							useMaskBehavior={true}
							label='To'
							labelMode='floating'
							displayFormat='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							onValueChanged={(e) => {
								valueOnChange(e, "ToDate");
							}}
						/>
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label' style={{ marginTop: "7px" }}>Project</div>
						{selectedProject === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedProject}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Project");
								}}
							/>
						)}
						{selectedProject === 1 && (
							<div onClick={() => setOpenProjectRange(true)}>
								<TextBox
									value={returnSelectedValue("FromProject") || returnSelectedValue("ToProject") ? `${returnSelectedValue("FromProject")} to ${returnSelectedValue("ToProject")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Project'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Project'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openProjectRange}
										onHiding={(e) => popFunction(e, "Project")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromProjectLookupRef}
														className={"listing-datagrid"}
														dataSource={fromProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromProject")}
														onSelectionChanged={(e) => {
															updateParamValue("FromProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("FromProject")}
														onDataSourceChanged={setFromProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toProjectLookupRef}
														className={"listing-datagrid"}
														dataSource={toProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToProject")}
														onSelectionChanged={(e) => {
															updateParamValue("ToProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("ToProject")}
														onDataSourceChanged={setToProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "ProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "ProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("ProjectList");
														removeParamValue("FromProject");
														removeParamValue("ToProject");
														setSelectedProject(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedProject === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Project").length !== 0 ? `${returnSelectedKeys("Project").length} Selected  (${returnSelectedKeys("Project")})` : `${returnSelectedKeys("Project").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Project")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Project")}
										defaultOpened={true}
										style={{ marginTop: "-2.5px" }}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label' style={{ marginTop: "7px" }}>Salesman</div>
						{selectedSales === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSales}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Salesman");
								}}
							/>
						)}
						{selectedSales === 1 && (
							<div onClick={() => setOpenSalesRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSales") || returnSelectedValue("ToSales") ? `${returnSelectedValue("FromSales")} to ${returnSelectedValue("ToSales")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Salesman'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Salesman'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSalesRange}
										onHiding={(e) => popFunction(e, "Salesman")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSalesmanLookupRef}
														className={"listing-datagrid"}
														dataSource={fromSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSales")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("FromSales")}
														onDataSourceChanged={setFromSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSalesmanLookupRef}
														className={"listing-datagrid"}
														dataSource={toSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSales")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("ToSales")}
														onDataSourceChanged={setToSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("SalesList");
														removeParamValue("FromSales");
														removeParamValue("ToSales");
														setSelectedSales(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSales === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Salesman").length !== 0 ? `${returnSelectedKeys("Salesman").length} Selected  (${returnSelectedKeys("Salesman")})` : `${returnSelectedKeys("Salesman").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openSalesSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Salesman")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Salesman")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>

				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label' style={{ marginTop: "7px" }}>Area</div>
						{selectedArea === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedArea}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Area");
								}}
							/>
						)}
						{selectedArea === 1 && (
							<div onClick={() => setOpenAreaRange(true)}>
								<TextBox
									value={returnSelectedValue("FromArea") || returnSelectedValue("ToArea") ? `${returnSelectedValue("FromArea")} to ${returnSelectedValue("ToArea")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Area'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Area'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openAreaRange}
										onHiding={(e) => popFunction(e, "Area")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromAreaLookupRef}
														className={"listing-datagrid"}
														dataSource={fromAreaList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromArea")}
														onSelectionChanged={(e) => {
															updateParamValue("FromArea", e.value);
														}}
														dataSourceURL={"api/Utilities/GetAreas"}
														displayText={returnSelectedValue("FromArea")}
														onDataSourceChanged={setFromAreaList}>
														<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toAreaLookupRef}
														className={"listing-datagrid"}
														dataSource={toAreaList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToArea")}
														onSelectionChanged={(e) => {
															updateParamValue("ToArea", e.value);
														}}
														dataSourceURL={"api/Utilities/GetAreas"}
														displayText={returnSelectedValue("ToArea")}
														onDataSourceChanged={setToAreaList}>
														<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("AreaList");
														removeParamValue("FromArea");
														removeParamValue("ToArea");
														setSelectedArea(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedArea === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Area").length !== 0 ? `${returnSelectedKeys("Area").length} Selected  (${returnSelectedKeys("Area")})` : `${returnSelectedKeys("Area").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openAreaSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Area")}
										valueExpr='id'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Area")}
										defaultOpened={true}
										width={"250px"}
										style={{ marginTop: "-2.5px" }}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>

					<div className='popup-group-form-item' style={{ alignItems: "center", display: "flex" }}>
						<CheckBox
							value={returnSelectedValue("IsZero")}
							defaultValue={false}
							text='Show Zero Balance'
							z							// style={{ marginLeft: "-25%", marginRight: "60px" }}
							onValueChanged={(e) => {
								updateParamValue("IsZero", e.value);
							}}
						/>
					</div>
				</div>

				<div className='popup-group-form-item' id='report-listing-btn-group'>
					<Button text='Inquiry' type='success' width={"auto"} onClick={(e) => onInitialized(e)} />
					<Button text='Print Report' disabled={printRepDisabled} type='success' stylingMode={"outlined"} width={"auto"} style={{ marginLeft: "20px" }} onClick={(e) => setOpenReportPop(true)} />
				</div>
			</div>

			<DataGrid dataSource={listingDataSource} allowColumnReordering={true} rowAlternationEnabled={true} showBorders={true} paging={false} width='100%' allowColumnResizing={true}
				onRowPrepared={(e) => onRowPrepared(e)}
				onOptionChanged={onOptionChanged}
			>
				{Array.isArray(columns) && columns.map((column) => {
					if (column && column.dataField) {
						const columnWidth = !utils.isNullOrEmpty(column.width) ? column.width : utils.getColumnWidth(column.dataField, storageName) ?? undefined;
						const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) ?? 0;
						const columnEditorOptions = column.editorOptions || {};

						const gridProps = {
							...column,
							width: columnWidth,
							visibleIndex: columnIndex,
							caption: column.caption || utils.childGridDefaultSetting(column.dataField, "Caption"),
							editorOptions: columnEditorOptions,
							format: column.format || utils.childGridDefaultSetting(column.dataField, "Format"),
							customizeText: column.customizeText || utils.childGridDefaultSetting(column.dataField, "Customize Text"),
						};

						return <Column key={column.dataField} {...gridProps} />;
					}

					return null;
				})
				}
				<Summary>
					<TotalItem
						column='Description'
						customizeText={(e) => {
							return "Total";
						}}
					/>
					<TotalItem
						column='Debit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
					/>
					<TotalItem
						column='Credit'
						summaryType='sum'
						customizeText={(e) => {
							return utils.numberToCurrency(e.value);
						}}
					/>
				</Summary>
			</DataGrid>

			<ErrorPopUpForm ref={popupMessageRef} />
		</Container>
	);
}
